import React, { useState } from 'react'
import { Modal,Button } from 'react-bootstrap'

export default function  CustomModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => {setShow(false);
    
      if(props.close)
      props.close();
    };

    const handleShow = () => {
        setShow(true);
        if(props.load)
         props.load();
    };

    const process = ()=>{
      if(props.proccess)
      {
        props.proccess();
      }
     
      if(props.closeAfterProccess)
        setShow(false);
    }

    return (
        <>
        <Button title={props.title} style={{margin:2}} size="sm" variant={props.variant || "dark"}  onClick={handleShow}>
       {props.icon}
      </Button>

      <Modal size={props.size || "lg"}  centered={props.centered} show={show} onHide={handleClose}>
        <Modal.Header  closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
          <Button variant="dark" disabled={!props.canProccess}  onClick={process}>
           {props.proccessTitle}  
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}

export  function  CustomModalV2(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {setShow(false);
  
    if(props.close)
    props.close();
  };

  const handleShow = () => {
      setShow(true);
      if(props.load)
       props.load();
  };

  const process = ()=>{
    if(props.proccess)
    {
      props.proccess();
    }
   
    if(props.closeAfterProccess)
      setShow(false);
  }

  return (
      <>
      <div onClick={handleShow}>
        {props.mainComponents}
      </div>
     

    <Modal size={props.size || "lg"}  centered={props.centered} show={show || props.forceShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {props.proccessTitle && 
        <Button variant="dark" disabled={!props.canProccess}  onClick={process}>
         {props.proccessTitle}  
        </Button>}  
        
      </Modal.Footer>
    </Modal>
      </>
  )
}


export function CustomModalV3 ({buttonTitle,buttonVariant, load, modalTitle, modalWith,children})

{
  const [showModal, setshowModal] = useState(false)

  return (<>
  <Button variant={buttonVariant || "dark"} onClick={e=>{
         setshowModal(false);
         if(load)
         load();

       }}>{buttonTitle}</Button>
    <Modal
     
     forceRender={true}
     okType="success"
     width={modalWith || 800}
     title={modalTitle}
     visible={showModal}
     onOk={() => {
       
     }}
     onCancel={() => {
        setshowModal(false);
     }}
   >
       {children}
     
          
       </Modal>
  </>)
}


