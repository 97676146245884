import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Form, Image, Spinner } from "react-bootstrap";
import { Eraser, InfoCircle, Person, PersonBadge, Plus, PlusCircleDotted } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import CustomModal, { CustomModalV2 } from "../Components/CustomModal";
import {
  GenericFormDaterange,
  GenericFormElement,
  GenericSelectForm,
  SearchSujestion,
} from "../Components/GenericFormController";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import moment from "moment";

import { closeMQTTConnection, connectToMQTTBroker, connectWS, disconnectWS, errorMessage, successMessage } from "../services/utilities";
import { CreateNewPerson, UpdatePerson } from "./People";

import SignatureComponents from "../Components/SignatureComponents";
import CameraCaptureModal from "../Components/CameraCaptureModal";
import { GridLoader } from "react-spinners";
import {  DatePicker, Select, version } from "antd";
import { Calendar,Badge } from 'antd';

import "antd/dist/antd.css";






//e=>{ return (<><ul><Badge status={"error"} text={"Otis Malema"} /><Badge status={"warning"} text={"Jade Motema"} /></ul></>)}
const MyCalandar= (props) =>{

  var currentDateSelcted=moment();

  function onPanelChange(value, mode) {
    console.log(value.format('YYYY-MM-DD'), mode);
  
    currentDateSelcted= value.format('YYYY-MM-DD');
    loadBookings(value);
   
  }

  function findTime(date)
  {
    return bookingsAvailbale.filter(a=> a.date ===date);
  }
  
  function datacellRender (date)
  {
  var getElements = findTime(date.format("YYYY-MM-DD"));
  

  return (<><ul> {getElements.map(r=>(<>  <VisitorUpdateBookingV2 _id={r._id} mainComponents={<Badge status={r.active} text={`${r.details}.`} /> } />  </>))} </ul></>)
  }

  function returnColorOnStatus(status)
  {
    if(status==="active") return "success"
    else if(status==="expired") return "error"
    else if(status==="booked") return "warning"
     else if(status==="confirmed") return "warning"
    else return "error"
  }

  const loadBookings = (value)=>{
    httpService.get(`/v1/bookings/bydate/${value.format('YYYY-MM-DD')}`).then(res=>{
      const{data} = res; 
      var cleaningData = data.map(a=> {

        return {_id:a._id,active:returnColorOnStatus(a.status),date:moment(a.from).format("YYYY-MM-DD"),status:a.status , details : `${a.person.name} ${a.person.surname}`}
      })
      setbookingsAvailbale([...cleaningData]);
    }).catch(er=>{

    })
  }

  useEffect(() => {
    
    loadBookings(moment());
  
  }, [])
  

  const [bookingsAvailbale, setbookingsAvailbale] = useState([]);

  
  useEffect(() => {

    loadBookings(currentDateSelcted);
    // var reconnectAfter =true; 


    // connectToMQTTBroker('/v2/udpateTelemetries/dashboard/dev',(msg)=>{ 
    //   loadBookings(currentDateSelcted);
    
    // },"jademotema","");

   
    // return () => {
     
    //   closeMQTTConnection();
    // } 
  
  }, [])


  return <><Calendar mode="" dateCellRender={  (e)=>datacellRender(e)}  onPanelChange={onPanelChange} /></>
};

// Update Booking

const VisitorUpdateBooking = (props) => {

  const colInfo = [
    { name: "name", title: "Name" },
    { name: "surname", title: "Surname" },
    { name: "companyName", title: "Company Name" },
    { name: "reason", title: "Reason of the Visit", as: "textarea" },
    { name: "visiting", title: "Visiting Person" },
  ];

  const [bookingDetails, setbookingDetails] = useState({
    from: "",
    to: "",
    type: "",
    person: "",
    vistingPerson: "",
  })

  const [visitorBookinf, setvisitorBookinf] = useState(null)

  const clearvisitorBookinf = () => {
    setvisitorBookinf({
      name: "",
      surname: "",
      companyName: "",
      _id: ""
    });
    setbookingDetails({ name: "", person: "", reason: "" });

  }
  const [canBook, setcanBook] = useState(true)

  const updateValue = (e) => {
    setbookingDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));

    setvisitorBookinf((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));


  };


  const loadValues = () => {
    //loadVisitors();
    //loadTenants();
    //setdateRange([new Date(), new Date()])
    loadBookings();
  }

  const clearValues = () => {
    setbookingDetails({
      from: "",
      to: "",
      type: "",
      person: "",
      vistingPerson: "",
    });
    clearvisitorBookinf();
    setcanBook(true)
    setsignatureImage(false);
  }

  const [dateRange, setdateRange] = useState([new Date(), moment().add(1, "hour").milliseconds()])


  const makeBooking = () => {

    var questionAswers = null;

    covidQuestions.forEach(quest => {
      if (quest.answer === "")
        questionAswers = quest;
    });

    var myanswers = null;

    if (visitorBookinf?.questions?.length === 0 && questionAswers) {
      errorMessage(`Please answer all covid questions (${questionAswers.title})`);
      return;
    }

    var payload = {};

    if (visitorBookinf?.questions?.length === 0) {

      myanswers = covidQuestions.map(a => ({ question: a.title, answer: a.answer }));
      payload["questions"] = myanswers;
    }



    payload = {
      ...payload,
      "from": dateRange[0],
      "to": dateRange[1],
      "signature": visitorBookinf?.signature,
      "visitorPicture": visitorBookinf?.visitorPicture,
      "id": visitorBookinf?.id
    };

    if (visitorBookinf?._id) {
      payload.person["id"] = visitorBookinf?._id;
    }

    httpService.post('/v1/bookings/confirm', payload).then(a => {


      successMessage(`Booking Made Successfully`);
      setcanBook(false);
      if (props.reload) {
        props.reload();
        setcanBook(false)
      }
    }).catch(err => {
      if (err.response)
        errorMessage(err.response.data);
    })
  }

  const [covidQuestions, setcovidQuestions] = useState([
    { title: "Have you travelled internationally in the last 14 days?", answer: "", isCheck: true },
    { title: "Have you been in contact in the last 14 days with someone who is  confirmed to have COVID-19  ", answer: "", isCheck: true },
    { title: "Are you currently suffering from any of the following symptoms?", answer: "", isCheck: true },
    { title: "Fever", answer: "", isCheck: true },
    { title: "Cough", answer: "", isCheck: true },
    { title: "Sore throat", answer: "", isCheck: true },
    { title: "Body pains / headache", answer: "", isCheck: true },
    { title: "Shortness of breath ", answer: "", isCheck: true },
    { title: "Temperature", answer: "" }

  ])

  const loadBookings = () => {
    httpService.get(`/v1/bookings/simple/${props._id}`).then(resp => {
      const { data } = resp;

      // var bookingInfoModified = resp.map(b=>( {
      //   ...b,
      //   companyName:b.company.name
      // }))


      var visitingPerson = (person) => {
        if (!person) return "My Cabinets";
        else {
          return `${person.name} ${person.surname}`
        }
      }

      var binfo = {
        name: data?.person?.name,
        surname: data?.person?.surname,
        visiting: visitingPerson(data.vistingPerson),
        reason: data?.reason,
        companyName: data?.person?.company?.name,
        questions: data?.questions,
        status: data.status,
        signature: data?.signature,
        id: data?._id,
        visitorPicture: data?.visitorPicture
      }
      setvisitorBookinf({ ...binfo });
      setdateRange([data.from, data.to])

    }).catch(er => {

      console.log('Error ', er)
    })
  }




  const [signatureImage, setsignatureImage] = useState(null);

  const [visitorpicture, setvisitorpicture] = useState(null)

  return (
    <>
      <CustomModal
        proccess={() => {
          makeBooking();
        }}
        size="xl"
        title="Visitor Booking"
        proccessTitle={"Update Booking"}
        icon={<InfoCircle />}
        load={loadValues}
        close={clearValues}
        canProccess={canBook}
      >

        {!visitorBookinf && <div style={{ textAlign: "center" }}><Spinner size="lg" animation="grow" /></div>}

        {visitorBookinf && <>
          <Row>
            <Col lg="6">
              <div style={{ padding: 5 }}>
                <h4>Booking Info ({visitorBookinf.status})</h4>


                <Button
                  title="Create New Visitor"
                  onClick={(e) => clearvisitorBookinf()}
                  style={{ margin: 5 }}
                  size="sm"
                  variant="dark"
                >
                  <PersonBadge />{" "}
                </Button>



                {colInfo.map((a) => (
                  <GenericFormElement
                    {...a}
                    disabled={true}
                    value={visitorBookinf[a.name]}
                    handleChange={updateValue}
                  />
                ))}


                <h4>Visit Time</h4>

                <GenericFormDaterange
                  title="Period"
                  value={dateRange}
                  setValue={setdateRange}
                />


                
              </div>

              {/* <GenericSelectForm title="Companies" handleChange={(e)=> updateValueSelct(e,"company")}  options={companies}/>
                <GenericSelectForm title="Person Type" handleChange={(e)=> updateValueSelct(e,"type")}  options={typeOfPerson}/> */}
            </Col>

            <Col lg="6">
              <div style={{ padding: 5 }}>
                <h4>Covid' Questions</h4>

                {visitorBookinf?.questions?.length > 0 && visitorBookinf.questions.map(a => (
                  <>
                    <Row>
                      <Col lg={10}>
                        {a.question}
                      </Col>
                      <Col>
                        {a.answer}
                      </Col>
                    </Row>

                  </>
                ))}

                {!(visitorBookinf?.questions?.length > 0) && covidQuestions.map((a) => (
                  <CovidQuestions
                    update={(e) => {


                      const updatedData = covidQuestions.map((x) =>
                        x.title === e.title ? { ...x, answer: e.answer } : x
                      );

                      setcovidQuestions([...updatedData]);
                    }}
                    {...a}
                  />
                ))}
              </div>
              <div >
                <Row>
                  <Col>
                    <h4>Signature

                      {!(visitorBookinf?.signature) && <SignatureComponents getSignature={(e) => {
                        //console.log('Signature data',e);
                        //setsignatureImage(e);
                        setvisitorBookinf({ ...visitorBookinf, signature: e })
                      }} />}
                    </h4>

                    <div style={{ border: "0px solid black", textAlign: "center", height: 200 }}>
                      {visitorBookinf?.signature && <Image height={180} width={200} src={visitorBookinf?.signature} />}

                    </div>
                  </Col>
                  <Col>
                    <h4>Visitor Picture</h4>
                    {!(visitorBookinf?.visitorPicture) && <CameraCaptureModal setPicture={(e => {
                      setvisitorBookinf(old => ({ ...old, visitorPicture: e }))
                    })} />}
                    <div style={{ border: "0px solid black", textAlign: "center", height: 200, width: 200 }}>
                      {(visitorBookinf?.visitorPicture) && <> <Image height={180} alt="Picture" src={visitorBookinf?.visitorPicture} /> </>}

                    </div>

                  </Col>
                </Row>

                <Col>
                </Col>





              </div>
            </Col>

          </Row>

          <Row></Row>
        </>}




      </CustomModal>
    </>
  );
};

const VisitorUpdateBookingV2 = (props) => {

  const colInfo = [
    { name: "name", title: "Name" },
    { name: "surname", title: "Surname" },
    { name: "companyName", title: "Company Name" },
    { name: "reason", title: "Reason of the Visit", as: "textarea" },
    { name: "visiting", title: "Visiting Person" },
  ];

  const [bookingDetails, setbookingDetails] = useState({
    from: "",
    to: "",
    type: "",
    person: "",
    vistingPerson: "",
  })

  const [visitorBookinf, setvisitorBookinf] = useState(null)

  const clearvisitorBookinf = () => {
    setvisitorBookinf({
      name: "",
      surname: "",
      companyName: "",
      _id: ""
    });
    setbookingDetails({ name: "", person: "", reason: "" });

  }
  const [canBook, setcanBook] = useState(true)

  const updateValue = (e) => {
    setbookingDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));

    setvisitorBookinf((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));


  };


  const loadValues = () => {
    //loadVisitors();
    //loadTenants();
    //setdateRange([new Date(), new Date()])
    loadBookings();
  }

  const clearValues = () => {
    setbookingDetails({
      from: "",
      to: "",
      type: "",
      person: "",
      vistingPerson: "",
    });
    clearvisitorBookinf();
    setcanBook(true)
    setsignatureImage(false);
  }

  const [dateRange, setdateRange] = useState([new Date(), moment().add(1, "hour").milliseconds()])


  const makeBooking = () => {

    var questionAswers = null;

    covidQuestions.forEach(quest => {
      if (quest.answer === "")
        questionAswers = quest;
    });

    var myanswers = null;

    if (visitorBookinf?.questions?.length === 0 && questionAswers) {
      errorMessage(`Please answer all covid questions (${questionAswers.title})`);
      return;
    }

    var payload = {};

    if (visitorBookinf?.questions?.length === 0) {

      myanswers = covidQuestions.map(a => ({ question: a.title, answer: a.answer }));
      payload["questions"] = myanswers;
    }



    payload = {
      ...payload,
      "from": dateRange[0],
      "to": dateRange[1],
      "signature": visitorBookinf?.signature,
      "visitorPicture": visitorBookinf?.visitorPicture,
      "id": visitorBookinf?.id
    };

    if (visitorBookinf?._id) {
      payload.person["id"] = visitorBookinf?._id;
    }

    httpService.post('/v1/bookings/confirm', payload).then(a => {


      successMessage(`Booking Made Successfully`);
      setcanBook(false);
      if (props.reload) {
        props.reload();
        setcanBook(false)
      }
    }).catch(err => {
      if (err.response)
        errorMessage(err.response.data);
    })
  }

  const [covidQuestions, setcovidQuestions] = useState([
    { title: "Have you travelled internationally in the last 14 days?", answer: "", isCheck: true },
    { title: "Have you been in contact in the last 14 days with someone who is  confirmed to have COVID-19  ", answer: "", isCheck: true },
    { title: "Are you currently suffering from any of the following symptoms?", answer: "", isCheck: true },
    { title: "Fever", answer: "", isCheck: true },
    { title: "Cough", answer: "", isCheck: true },
    { title: "Sore throat", answer: "", isCheck: true },
    { title: "Body pains / headache", answer: "", isCheck: true },
    { title: "Shortness of breath ", answer: "", isCheck: true },
    { title: "Temperature", answer: "" }

  ])

  const loadBookings = () => {
    httpService.get(`/v1/bookings/simple/${props._id}`).then(resp => {
      const { data } = resp;

      // var bookingInfoModified = resp.map(b=>( {
      //   ...b,
      //   companyName:b.company.name
      // }))


      var visitingPerson = (person) => {
        if (!person) return "My Cabinets";
        else {
          return `${person.name} ${person.surname}`
        }
      }

      var binfo = {
        name: data?.person?.name,
        surname: data?.person?.surname,
        visiting: visitingPerson(data.vistingPerson),
        reason: data?.reason,
        companyName: data?.person?.company?.name,
        questions: data?.questions,
        status: data.status,
        signature: data?.signature,
        id: data?._id,
        visitorPicture: data?.visitorPicture
      }
      setvisitorBookinf({ ...binfo });
      setdateRange([data.from, data.to])

    }).catch(er => {

      console.log('Error ', er)
    })
  }




  const [signatureImage, setsignatureImage] = useState(null);

  const [visitorpicture, setvisitorpicture] = useState(null)

  return (
    <>
      <CustomModalV2
        proccess={() => {
          makeBooking();
        }}
        size="xl"
        title="Visitor Booking"
        proccessTitle={"Update Booking"}
        icon={<InfoCircle />}
        load={loadValues}
        close={clearValues}
        canProccess={canBook}
        mainComponents={props.mainComponents}
      >

        {!visitorBookinf && <div style={{ textAlign: "center" }}><Spinner size="lg" animation="grow" /></div>}

        {visitorBookinf && <>
          <Row>
            <Col lg="6">
              <div style={{ padding: 5 }}>
                <h4>Booking Info ({visitorBookinf.status})</h4>


                <Button
                  title="Create New Visitor"
                  onClick={(e) => clearvisitorBookinf()}
                  style={{ margin: 5 }}
                  size="sm"
                  variant="dark"
                >
                  <PersonBadge />{" "}
                </Button>



                {colInfo.map((a) => (
                  <GenericFormElement
                    {...a}
                    disabled={true}
                    value={visitorBookinf[a.name]}
                    handleChange={updateValue}
                  />
                ))}


                <h4>Visit Time</h4>

                <GenericFormDaterange
                  title="Period"
                  value={dateRange}
                  setValue={setdateRange}
                />


                
              </div>

              {/* <GenericSelectForm title="Companies" handleChange={(e)=> updateValueSelct(e,"company")}  options={companies}/>
                <GenericSelectForm title="Person Type" handleChange={(e)=> updateValueSelct(e,"type")}  options={typeOfPerson}/> */}
            </Col>

            <Col lg="6">
              <div style={{ padding: 5 }}>
                <h4>Covid' Questions</h4>

                {visitorBookinf?.questions?.length > 0 && visitorBookinf.questions.map(a => (
                  <>
                    <Row>
                      <Col lg={10}>
                        {a.question}
                      </Col>
                      <Col>
                        {a.answer}
                      </Col>
                    </Row>

                  </>
                ))}

                {!(visitorBookinf?.questions?.length > 0) && covidQuestions.map((a) => (
                  <CovidQuestions
                    update={(e) => {


                      const updatedData = covidQuestions.map((x) =>
                        x.title === e.title ? { ...x, answer: e.answer } : x
                      );

                      setcovidQuestions([...updatedData]);
                    }}
                    {...a}
                  />
                ))}
              </div>
              <div >
                <Row>
                  <Col>
                    <h4>Signature

                      {!(visitorBookinf?.signature) && <SignatureComponents getSignature={(e) => {
                        //console.log('Signature data',e);
                        //setsignatureImage(e);
                        setvisitorBookinf({ ...visitorBookinf, signature: e })
                      }} />}
                    </h4>

                    <div style={{ border: "0px solid black", textAlign: "center", height: 200 }}>
                      {visitorBookinf?.signature && <Image height={180} width={200} src={visitorBookinf?.signature} />}

                    </div>
                  </Col>
                  <Col>
                    <h4>Visitor Picture</h4>
                    {!(visitorBookinf?.visitorPicture) && <CameraCaptureModal setPicture={(e => {
                      setvisitorBookinf(old => ({ ...old, visitorPicture: e }))
                    })} />}
                    <div style={{ border: "0px solid black", textAlign: "center", height: 200, width: 200 }}>
                      {(visitorBookinf?.visitorPicture) && <> <Image height={180} alt="Picture" src={visitorBookinf?.visitorPicture} /> </>}

                    </div>

                  </Col>
                </Row>

                <Col>
                </Col>





              </div>
            </Col>

          </Row>

          <Row></Row>
        </>}




      </CustomModalV2>
    </>
  );
};

// Make Bookings


const CovidQuestions = (props) => {
  const [ischeck, setischeck] = useState(false);

  const UpdateCovidQeustions = (status) => {
    if (props.update)
      props.update({ title: props.title, answer: status ? "Yes" : "No", isCheck: props.isCheck })
  }

  const UpdateCovidQeustionsValue = (status) => {
    if (props.update)
      props.update({ title: props.title, answer: status, isCheck: props.isCheck })
  }
  return (<>
    <Row>

      <Col lg={8}> {props.title} </Col>
      <Col>
        {props.isCheck &&
          <>
            <Form.Check

              name={`${props.title}-er`}
              inline
              type={"radio"}
              label={"Yes"}
              onChange={e => { UpdateCovidQeustions(true) }}
            />
            <Form.Check
              name={`${props.title}-er`}
              inline
              type={"radio"}
              label={"No"}

              onChange={e => { UpdateCovidQeustions(false) }}
            /></>
        }
        {!props.isCheck && <Form.Control

          type={"number"}
          step={0.1}
          max={45}
          min={30}
          style={{ width: 80 }}
          label=""
          onChange={e => { UpdateCovidQeustionsValue(e.currentTarget.value) }}
        />}
      </Col>
    </Row>
  </>)
}

const VisitorNewBooking = (props) => {
  const typeOfVisit = [
    { value: "staffVisit", label: "Staff Visit" },
    { value: "tenantVisit", label: "Tenant Visit" },
  ];

  const colInfo = [
    { name: "surname", title: "Surname" },
    { name: "username", title: "Email Address" },
    { name: "companyName", title: "Company Name" },
    { name: "reason", title: "Reason of the Visit", as: "textarea" },
  ];

  const [bookingDetails, setbookingDetails] = useState({
    from: "",
    to: "",
    type: "",
    person: "",
    vistingPerson: "",
  })

  const [visitorBookinf, setvisitorBookinf] = useState({
    name: "",
    surname: "",

  })

  const clearvisitorBookinf = () => {
    setvisitorBookinf({
      name: "",
      surname: "",
      companyName: "",
      _id: ""
    });
    setbookingDetails({ name: "", person: "", reason: "" });
    setpeopelSuggestion([]);
  }

  const [visitors, setvisitors] = useState([]);

  const [tenants, settenants] = useState([]);

  const [canBook, setcanBook] = useState(true)

  const updateValue = (e) => {
    setbookingDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));

    setvisitorBookinf((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));


  };
  const updateValueSelct = (e, name) => {
    setbookingDetails((oldValue) => ({ ...oldValue, [name]: e }));
  };

  const loadVisitors = () => {
    httpService.get("/v1/people?type=onlyvisitors").then(a => {
      const { data } = a;
      const list = data.map(a => {
        return {
          label: `${a.name} ${a.surname} - (${a?.company?.name || a?.companyName})`,
          value: a._id,
          data: a
        }
      })
      setvisitors([...list]);
    }).catch(er => {

    });


  }

  const loadTenants = () => {
    httpService.get("/v1/people?type=staff").then(a => {
      const { data } = a;
      const list = data.map(a => {
        return {
          label: `${a.name} ${a.surname}`,
          value: a._id,
          data: a
        }
      })
      settenants([...list]);
    }).catch(er => {

    });
  }

  const loadValues = () => {
    loadVisitors();
    loadTenants();
    setdateRange([new Date(), new Date()])
  }

  const clearValues = () => {
    setbookingDetails({
      from: "",
      to: "",
      type: "",
      person: "",
      vistingPerson: "",
    });
    setVisitorPicture(null)
    clearvisitorBookinf();
    setcanBook(true)
    setsignatureImage(false);
  }

  const [dateRange, setdateRange] = useState([new Date(), moment().add(1, "hour").milliseconds()])


  const makeBooking = () => {

    var questionAswers = null;
    covidQuestions.forEach(quest => {
      if (quest.answer === "")
        questionAswers = quest;
    });

    if (questionAswers) {
      errorMessage(`Please answer all covid questions (${questionAswers.title})`);
      return;
    }

    var myanswers = covidQuestions.map(a => ({ question: a.title, answer: a.answer }));

    var payload = {
      "from": dateRange[0],
      "to": dateRange[1],
      person: { email: visitorBookinf.username, name: visitorBookinf.name, surname: visitorBookinf.surname, companyName: visitorBookinf.companyName },
      "vistingPerson": bookingDetails?.vistingPerson?.value,
      "questions": myanswers,
      "reason": bookingDetails.reason,
      "signature": signatureImage,
      visitorPicture: visitorPicture

    };

    if (visitorBookinf?._id) {
      payload.person["id"] = visitorBookinf?._id;
    }

    httpService.post('/v1/bookings/visitor', payload).then(a => {

      const { data } = a;
      successMessage(`Booking Made Successfully`);
      setcanBook(false);
      if (props.reload) {
        props.reload();
        setcanBook(false)
      }
    }).catch(err => {
      if (err.response)
        errorMessage(err.response.data);
    })
  }

  const [covidQuestions, setcovidQuestions] = useState([
    { title: "Have you travelled internationally in the last 14 days?", answer: "", isCheck: true },
    { title: "Have you been in contact in the last 14 days with someone who is  confirmed to have COVID-19  ", answer: "", isCheck: true },
    { title: "Are you currently suffering from any of the following symptoms?", answer: "", isCheck: true },
    { title: "Fever", answer: "", isCheck: true },
    { title: "Cough", answer: "", isCheck: true },
    { title: "Sore throat", answer: "", isCheck: true },
    { title: "Body pains / headache", answer: "", isCheck: true },
    { title: "Shortness of breath ", answer: "", isCheck: true },
    { title: "Temperature", answer: "" }

  ])

  const [peopelSuggestion, setpeopelSuggestion] = useState([]);

  const loadSuggestion = (e) => {

    if (e.target.value.length === 0) {
      setpeopelSuggestion([]);
      return;
    }


    if (e.target.value.length < 3)
      return;

    httpService.get(`/v1/people/visitor?name=${e.target.value}`).then(res => {
      const { data } = res;
      var people = data.map(a => ({ ...a, title: `${a.name} ${a.surname} ( ${a?.companyName || a?.company?.name} )`, name: a.name, surname: a.surname, id: a._id, companyName: a.company.name }))
      setpeopelSuggestion([...people]);
    }).catch(er => {

    })
  }



  var sigCanvas = {};

  const [visitorPicture, setVisitorPicture] = useState(null)
  const [signatureImage, setsignatureImage] = useState(null);


  return (
    <>
      <CustomModal
        proccess={() => {
          makeBooking();
        }}
        size="xl"
        title="Visitor Booking"
        proccessTitle={"Make a Booking"}
        icon={<Person />}
        load={loadValues}
        close={clearValues}
        canProccess={canBook}
      >
        <Row>
          <Col lg="6">
            <div style={{ padding: 5 }}>
              <h4>Booking Info</h4>
              {/* <GenericSelectForm
                value={bookingDetails.person}
                title="Visitors"
                handleChange={(e) => {
                  updateValueSelct(e, "person");
                  setvisitorBookinf({ ...e.data });
                }}
                options={visitors}
              /> */}

              <Button
                title="Create New Visitor"
                onClick={(e) => clearvisitorBookinf()}
                style={{ margin: 5 }}
                size="sm"
                variant="dark"
              >
                <PersonBadge />{" "}
              </Button>

              <SearchSujestion sugestions={peopelSuggestion} handleAutoSuggestion={(e) => { setpeopelSuggestion([]); setvisitorBookinf({ ...e }) }} />

              <GenericFormElement
                sugestions={peopelSuggestion}
                name="name"
                title="Name"
                value={visitorBookinf["name"]}

                handleChange={e => {
                  updateValue(e);
                  loadSuggestion(e)
                }}
              />


              {colInfo.map((a) => (
                <GenericFormElement
                  {...a}
                  value={visitorBookinf[a.name]}
                  handleChange={updateValue}
                />
              ))}

              <GenericSelectForm
                title="Staffs"
                handleChange={(e) => updateValueSelct(e, "vistingPerson")}
                options={tenants}
              />

              <h4>Visit Time</h4>

              <GenericFormDaterange
                title="Period"
                value={dateRange}
                setValue={setdateRange}
              />
            </div>

            {/* <GenericSelectForm title="Companies" handleChange={(e)=> updateValueSelct(e,"company")}  options={companies}/>
                <GenericSelectForm title="Person Type" handleChange={(e)=> updateValueSelct(e,"type")}  options={typeOfPerson}/> */}
          </Col>

          <Col lg="6">
            <div style={{ padding: 5 }}>
              <h4>Covid' Questions</h4>

              {covidQuestions.map((a) => (
                <CovidQuestions
                  update={(e) => {
                    var covids = covidQuestions;

                    const updatedData = covidQuestions.map((x) =>
                      x.title === e.title ? { ...x, answer: e.answer } : x
                    );

                    setcovidQuestions([...updatedData]);
                  }}
                  {...a}
                />
              ))}
            </div>
            <div >
              <Row>
                <Col>
                  <h4>Signature

                    <SignatureComponents getSignature={(e) => {
                      console.log('Signature data', e);
                      setsignatureImage(e);
                    }} />

                  </h4>
                  <div style={{ border: "0px solid black", width: 200, height: 200 }}>
                    {signatureImage && <Image fluid={true} height={200} width={200} src={signatureImage} />}

                  </div>
                </Col>
                <Col>
                  <CameraCaptureModal setPicture={setVisitorPicture} />


                  <div style={{ border: "0px solid black", width: 200, height: 200 }}>
                    {visitorPicture && <Image fluid={true} height={200} width={200} src={visitorPicture} />}

                  </div>
                </Col>
              </Row>




            </div>
          </Col>

        </Row>

        <Row></Row>
      </CustomModal>
    </>
  );
};

const EasyNewBooking = (props) => {
  const typeOfVisit = [
    { value: "staffVisit", label: "Staff Visit" },
    { value: "tenantVisit", label: "Tenant Visit" },
  ];

  const colInfo = [
    { name: "surname", title: "Surname" },
  ];

  const [bookingDetails, setbookingDetails] = useState({
    from: "",
    to: "",
    type: "",
    person: "",
    vistingPerson: "",
  })

  const [visitorBookinf, setvisitorBookinf] = useState({
    name: "",
    surname: ""
  })

  const [visitors, setvisitors] = useState([]);

  const [tenants, settenants] = useState([])

  const updateValue = (e) => {
    setbookingDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
  };
  const updateValueSelct = (e, name) => {
    setbookingDetails((oldValue) => ({ ...oldValue, [name]: e }));
  };

  const loadVisitors = () => {
    httpService.get("/v1/people?type=visitors").then(a => {
      const { data } = a;
      const list = data.map(a => {
        return {
          label: `${a.name} ${a.surname} - (${a?.company?.name || a?.companyName})`,
          value: a._id,
          data: a
        }
      })
      setvisitors([...list]);
    }).catch(er => {

    });


  }

  const loadTenants = () => {
    httpService.get("/v1/people?type=staff").then(a => {
      const { data } = a;
      const list = data.map(a => {
        return {
          label: `${a.name} ${a.surname}`,
          value: a._id,
          data: a
        }
      })
      settenants([...list]);
    }).catch(er => {

    });
  }

  const loadValues = () => {
    loadVisitors();
    loadTenants();
    setdateRange([new Date(), new Date()])
  }

  const clearValues = () => {
    setbookingDetails({
      from: "",
      to: "",
      type: "",
      person: "",
      vistingPerson: "",
    })
  }

  const [dateRange, setdateRange] = useState([new Date(), moment().add(1, "hour").milliseconds()])


  const makeBooking = () => {

    var questionAswers = null;
    covidQuestions.forEach(quest => {
      if (quest.answer === "")
        questionAswers = quest;
    });

    if (questionAswers) {
      errorMessage(`Please answer all covid questions (${questionAswers.title})`);
      return;
    }

    var myanswers = covidQuestions.map(a => ({ question: a.title, answer: a.answer }))

    httpService.post('/v1/bookings',
      {
        "from": dateRange[0],
        "to": dateRange[1],
        "type": bookingDetails.type.value,
        "person": bookingDetails.person.value,
        "vistingPerson": bookingDetails.vistingPerson.value,
        "questions": myanswers
      }).then(a => {
        const { data } = a;
        successMessage(`Booking Made Successfully`);
        if (props.reload)
          props.reload();
      }).catch(err => {
        if (err.response)
          errorMessage(err.response.data);
      })
  }




  const [covidQuestions, setcovidQuestions] = useState([
    { title: "Have you travelled internationally in the last 14 days?", answer: "", isCheck: true },
    { title: "Have you been in contact in the last 14 days with someone who is  confirmed to have COVID-19  ", answer: "", isCheck: true },
    { title: "Are you currently suffering from any of the following symptoms?", answer: "", isCheck: true },
    { title: "Fever", answer: "", isCheck: true },
    { title: "Cough", answer: "", isCheck: true },
    { title: "Sore throat", answer: "", isCheck: true },
    { title: "Body pains / headache", answer: "", isCheck: true },
    { title: "Shortness of breath ", answer: "", isCheck: true },
    { title: "Temperature", answer: "" }

  ])

  return (
    <>
      <CustomModal
        proccess={() => { makeBooking() }}
        size="xl"
        title="Easy Visit"
        proccessTitle={"Make a Booking"}
        icon={<PlusCircleDotted />}
        load={loadValues}
        close={clearValues}
        canProccess={true}
      >


        <Row>


          <Col lg="6">
            <div style={{ padding: 5 }}>

              <h4>Booking Info</h4>




              <GenericSelectForm value={{ value: "staffVisit", label: "Staff Visit" }} title="Type of Visit" handleChange={(e) => { updateValueSelct(e, "type"); }} options={typeOfVisit} />

              <GenericSelectForm title="Visitors" handleChange={(e) => { updateValueSelct(e, "person"); setvisitorBookinf({ ...e.data }) }} options={visitors} />
              {<DisplayDetails reload={loadValues} {...bookingDetails.person.data} />}

              <GenericFormElement sugestions={[{ title: "Jade Motema" }]} name="name" title="Name" value={visitorBookinf["name"]} handleChange={updateValue} />

              {colInfo.map(a => (<GenericFormElement {...a} value={visitorBookinf[a.name]} handleChange={updateValue} />))}


              {bookingDetails?.type?.value === "staffVisit" && <GenericSelectForm title="Staffs" handleChange={(e) => updateValueSelct(e, "vistingPerson")} options={tenants} />}

              {bookingDetails?.type?.value === "staffVisit" && <DisplayDetails reload={loadValues} {...bookingDetails.vistingPerson.data} />}

              <GenericFormElement as="textarea" name="reason" type="string" title="Reason For the Visit" handleChange={(e) => { setbookingDetails({ ...bookingDetails, reason: e.target.value }) }} />

              <h4>Visit Time</h4>

              <GenericFormDaterange title="Period" value={dateRange} setValue={setdateRange} />

            </div>



            {/* <GenericSelectForm title="Companies" handleChange={(e)=> updateValueSelct(e,"company")}  options={companies}/>
                <GenericSelectForm title="Person Type" handleChange={(e)=> updateValueSelct(e,"type")}  options={typeOfPerson}/> */}
          </Col>

          <Col lg="6">
            <div style={{ padding: 5 }}>
              <h4>Covid' Questions</h4>

              {covidQuestions.map(a => (<CovidQuestions update={(e) => {

                var covids = covidQuestions;

                const updatedData = covidQuestions.map(x => (x.title === e.title ? { ...x, answer: e.answer } : x));

                setcovidQuestions([...updatedData]);

              }} {...a} />))}
            </div>

          </Col>
        </Row>

        <Row>

        </Row>
      </CustomModal>
    </>
  );
};

const NewBooking = (props) => {
  const typeOfVisit = [
    { value: "staffVisit", label: "Staff Visit" },
    { value: "tenantVisit", label: "Tenant Visit" },
  ];

  const colInfo = [
    { name: "from", title: "From Time" },
    { name: "to", title: "To Time" },
  ];

  const [bookingDetails, setbookingDetails] = useState({
    from: "",
    to: "",
    type: "",
    person: "",
    vistingPerson: "",
  })

  const [visitors, setvisitors] = useState([]);

  const [tenants, settenants] = useState([]);



  const updateValue = (e) => {
    setbookingDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
  };
  const updateValueSelct = (e, name) => {
    setbookingDetails((oldValue) => ({ ...oldValue, [name]: e }));
  };

  const loadVisitors = () => {
    httpService.get("/v1/people?type=visitors").then(a => {
      const { data } = a;
      const list = data.map(a => {
        return {
          label: `${a.name} ${a.surname} - (${a?.company?.name || a?.companyName})`,
          value: a._id,
          data: a
        }
      })
      setvisitors([...list]);
    }).catch(er => {

    });


  }

  const loadTenants = () => {
    httpService.get("/v1/people?type=staff").then(a => {
      const { data } = a;
      const list = data.map(a => {
        return {
          label: `${a.name} ${a.surname}`,
          value: a._id,
          data: a
        }
      })
      settenants([...list]);
    }).catch(er => {

    });
  }

  const loadValues = () => {
    loadVisitors();
    loadTenants();
    setdateRange([new Date(), new Date()])
  }

  const clearValues = () => {
    setbookingDetails({
      from: "",
      to: "",
      type: "",
      person: "",
      vistingPerson: "",
    })
  }

  const [dateRange, setdateRange] = useState([new Date(), moment().add(1, "hour").milliseconds()])


  const makeBooking = () => {

    var questionAswers = null;
    covidQuestions.forEach(quest => {
      if (quest.answer === "")
        questionAswers = quest;
    });

    if (questionAswers) {
      errorMessage(`Please answer all covid questions (${questionAswers.title})`);
      return;
    }

    var myanswers = covidQuestions.map(a => ({ question: a.title, answer: a.answer }))

    httpService.post('/v1/bookings',
      {
        "from": dateRange[0],
        "to": dateRange[1],
        "type": bookingDetails.type.value,
        "person": bookingDetails.person.value,
        "vistingPerson": bookingDetails.vistingPerson.value,
        "questions": myanswers
      }).then(a => {
        const { data } = a;
        successMessage(`Booking Made Successfully`);
        if (props.reload)
          props.reload();
      }).catch(err => {
        if (err.response)
          errorMessage(err.response.data);
      })
  }

  const [covidQuestions, setcovidQuestions] = useState([
    { title: "Have you travelled internationally in the last 14 days?", answer: "", isCheck: true },
    { title: "Have you been in contact in the last 14 days with someone who is  confirmed to have COVID-19  ", answer: "", isCheck: true },
    { title: "Are you currently suffering from any of the following symptoms?", answer: "", isCheck: true },
    { title: "Fever", answer: "", isCheck: true },
    { title: "Cough", answer: "", isCheck: true },
    { title: "Sore throat", answer: "", isCheck: true },
    { title: "Body pains / headache", answer: "", isCheck: true },
    { title: "Shortness of breath ", answer: "", isCheck: true },
    { title: "Temperature", answer: "" }

  ])

  return (
    <>
      <CustomModal
        proccess={() => { makeBooking() }}
        size="xl"
        title="New Visit"
        proccessTitle={"Make a Booking"}
        icon={<Plus />}
        load={loadValues}
        close={clearValues}
        canProccess={true}
      >


        <Row>


          <Col lg="6">
            <div style={{ padding: 5 }}>

              <h4>Booking Info</h4>




              <GenericSelectForm title="Type of Visit" handleChange={(e) => updateValueSelct(e, "type")} options={typeOfVisit} />

              <GenericSelectForm title="Visitors" handleChange={(e) => updateValueSelct(e, "person")} options={visitors} />
              {<DisplayDetails reload={loadValues} {...bookingDetails.person.data} />}


              {bookingDetails?.type?.value === "staffVisit" && <GenericSelectForm title="Staffs" handleChange={(e) => updateValueSelct(e, "vistingPerson")} options={tenants} />}

              {bookingDetails?.type?.value === "staffVisit" && <DisplayDetails reload={loadValues} {...bookingDetails.vistingPerson.data} />}

              <GenericFormElement as="textarea" name="reason" type="string" title="Reason For the Visit" handleChange={(e) => { setbookingDetails({ ...bookingDetails, reason: e.target.value }) }} />

              <h4>Visit Time</h4>

              <GenericFormDaterange title="Period" value={dateRange} setValue={setdateRange} />

            </div>



            {/* <GenericSelectForm title="Companies" handleChange={(e)=> updateValueSelct(e,"company")}  options={companies}/>
                <GenericSelectForm title="Person Type" handleChange={(e)=> updateValueSelct(e,"type")}  options={typeOfPerson}/> */}
          </Col>

          <Col lg="6">
            <div style={{ padding: 5 }}>
              <h4>Covid' Questions</h4>

              {covidQuestions.map(a => (<CovidQuestions update={(e) => {

                var covids = covidQuestions;

                const updatedData = covidQuestions.map(x => (x.title === e.title ? { ...x, answer: e.answer } : x));

                setcovidQuestions([...updatedData]);

              }} {...a} />))}
            </div>

          </Col>
        </Row>

        <Row>

        </Row>
      </CustomModal>
    </>
  );
};

const DisplayDetails = (props) => {
  return (
    <div style={{ fontStyle: "italic", fontWeight: "bold", margin: 10 }}>
      {<CreateNewPerson reload={props.reload} />}

      {props._id && <UpdatePerson reload={props.reload} id={props._id} />}     <br />
      {/* Name: {props.name}  <br/>
        Surname: {props.surname} <br/>
        Id Number: {props.idNumber} <br/> */}
    </div>
  )
}

export default function Bookings() {

  const typeOfVisit = [
    { value: "staffVisit", label: "Staff Visit" },
    { value: "tenantVisit", label: "Tenant Visit" },
  ];

  const columns = [
    {
      sortable: true,
      name: "Status",
      selector: (row) => row?.status,
    }
    ,
    {
      sortable: true,
      name: "Type",
      selector: (row) => row?.type,
      cell: (row) => (
        <>
          {typeOfVisit.find(a => a.value === row.type)?.label} <br />

        </>
      ),
    },
    {
      sortable: true,
      name: "Visitor",
      selector: (row) => row?.person?.name,
      cell: (row) => (
        <>
          {row?.person?.name} {row?.person?.surname} <br />
        </>
      ),
    },
    {
      sortable: true,
      name: "Company",
      selector: (row) => row?.person?.name,
      cell: (row) => (
        <>
          {row?.person?.company?.name} <br />
        </>
      ),
    },
    {
      sortable: true,
      name: "From",
      selector: (row) => row.from,
      cell: (row) => (
        <>
          {moment(row.from).format("MMMM Do YYYY, h:mm:ss a")} <br />
        </>)
    },
    {
      sortable: true,
      name: "To",
      selector: (row) => row.to,
      cell: (row) => (
        <>
          {moment(row.to).format("MMMM Do YYYY, h:mm:ss a")} <br />
        </>)
    },
    {
      sortable: true,
      name: "Visiting",
      selector: (row) => row?.vistingPerson?.name,
      cell: (row) => (
        <>
          {row?.vistingPerson?.name} {row?.vistingPerson?.surname} {!row?.vistingPerson?.name && "-"}
        </>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Button size="sm" variant="danger" onClick={() => deleteBooking(row._id, loadBooking)}><Eraser /></Button>
          <VisitorUpdateBooking _id={row._id} />
          {/* <UpdatePerson id={row._id} reload={loadPeople} /> <DeleteButton /> */}
        </>
      ),
    },
  ];
  const [bookings, setbookings] = useState([]);

  const deleteBooking = (id, reload) => {
    var confirm = window.confirm(`Are you sure you want to delele this booking?`);

    if (confirm)
      httpService.delete(`/v1/bookings/${id}`).then(a => {
        const { data } = a;
        successMessage(`Booking Successfully Deleted`);
        if (reload)
          reload();
      }).catch(r => {
        if (r.response) {
          errorMessage(r.response.data.error)
        }
      })
  }

  const loadBooking = () => {
    setisLoading(true);
    httpService
      .get("/v1/bookings")
      .then((a) => {
        const { data } = a;
        setbookings([...data]);
        setisLoading(false);
      })
      .catch((r) => {  setisLoading(false);});
  };

  useEffect(() => {
    loadBooking();
  }, []);


  useEffect(() => {

    var reconnect=true;
  
   
  }, [])

  const [isLoading, setisLoading] = useState(true)



  return (
    <>
      <Header />
      <HeaderTitle name="Bookings" />
      <Container>
        <></>
        <NewBooking reload={loadBooking} />
        {<EasyNewBooking />}
        <VisitorNewBooking reload={loadBooking} />
        <div style={{textAlign:"center"}}><GridLoader margin={10} loading={isLoading} size={30} color="black"
     /></div>
        <MyCalandar />
      
        {/* <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={columns}
          data={bookings}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
        /> */}
                
      </Container>
    </>
  );
}
