
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  
  Navigate,
  
  Route,
  Routes,
  useLocation,
  useNavigate
  
 
} from "react-router-dom";
import People from './Pages/People';
import Bookings from './Pages/Bookings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Activities from './Pages/Activities';
import Cabinets from './Pages/Cabinets';
import Companies from './Pages/Companies';
import LocationAccess from './Pages/LocationAccess';
import Login from './Pages/Login';
import AccessSchedule from './Pages/AccessSchedule';

import { css } from "@emotion/react";
import DashBoard from './Pages/DashBoard';
import IZones from './Pages/IZones';
import Floors from './Pages/Floors';
import BuildingsMaps from './Pages/BuildingsMaps';
import MainBuildingsInformation from './Pages/MainBuildingsInformation';
import Onboarding from './Pages/Onboarding';
import AccessStragetegies from './Pages/AccessStragetegies';
import Assets from './Pages/Assets';
import AccessGroup from './Pages/AccessGroup';
import Visits, { Visitors } from './Pages/Visitors_Tenants/Visits';
import Notifications from './Pages/Visitors_Tenants/Notifications';
import LocationDisplay from './Pages/Displays/LocationDisplay';
import PasswordReset from './Pages/PasswordReset';
import Scheduler from './Pages/Scheduler';
import { UserContext } from './context';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocalStorage } from './hooks';
import { SimpleRepport } from './Pages/repports';
import IzonesManager from './Pages/IzonesManager';

function RequireAuth({ children }) {  

  let jwt = localStorage.getItem("jwt_token");
  let profile = localStorage.getItem("profileInfo");

  try {
    profile = JSON.parse(profile);
  } catch (error) {
    profile=null;
  }
  
  let location = useLocation();
  if (!jwt || !profile) {   
    return <Navigate to="/app/login/ready" state={{ from: location }} replace />;
  }

  return children;
}

function App() {

  const {getItem}= useLocalStorage();

  const [userDetails, setuserDetails] = useState(getItem("moreProfile"));
  return (
    <>
      <Router>
        <>
        <UserContext.Provider value={{userDetails, setuserDetails}}>
          <Routes>

         

          <Route
              path="/app/report"
              caseSensitive={false}
              element={< SimpleRepport />}
            />
           
            <Route
              path="/app/login/:success"
              caseSensitive={false}
              element={<Login />}
            />
            <Route
              path="/app/resetpassword/:token"
              caseSensitive={false}
              element={<PasswordReset />}
            />

            <Route
              path="/app/display"
              caseSensitive={false}
              element={<LocationDisplay />}
            />



<Route
              path="/app/izonesmanager"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <IzonesManager />
                </RequireAuth>
              }
            />

            <Route
              path="/app/buildingsetups"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <MainBuildingsInformation />
                </RequireAuth>
              }
            />
            <Route
              path="/app/schedulers"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Scheduler />
                </RequireAuth>
              }
            />

            <Route
              path="/app/dashboard/:id"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <DashBoard />
                </RequireAuth>
              }
            />

            <Route
              path="/app/visits"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Visits />
                </RequireAuth>
              }
            />

            <Route
              path="/app/visitors"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Visitors />
                </RequireAuth>
              }
            />

            <Route
              path="/app/notifications"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Notifications />
                </RequireAuth>
              }
            />

            <Route
              path="/app/onboarding"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Onboarding />
                </RequireAuth>
              }
            />

            <Route
              path="/app/accessstrategies"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <AccessStragetegies />
                </RequireAuth>
              }
            />

            <Route
              path="/app/buildingsmaps"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <BuildingsMaps />
                </RequireAuth>
              }
            />

            <Route
              path="/app/accessschedule"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <AccessSchedule />
                </RequireAuth>
              }
            />

            <Route
              path="/app/companies"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Companies />
                </RequireAuth>
              }
            />

            <Route
              path="/app/izones"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <IZones />
                </RequireAuth>
              }
            />

            <Route
              path="/app/accessgroup"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <AccessGroup />
                </RequireAuth>
              }
            />

            <Route
              path="/app/floors"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Floors />
                </RequireAuth>
              }
            />

            <Route
              path="/app/assets"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Assets />
                </RequireAuth>
              }
            />

            <Route
              path="/app/people"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <People />
                </RequireAuth>
              }
            />

            <Route
              path="/app/activities"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Activities />
                </RequireAuth>
              }
            />

            <Route
              path="/app/bookings"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <Bookings />
                </RequireAuth>
              }
            />
            <Route
              caseSensitive={false}
              path="/app/locations"
              element={
                <RequireAuth>
                  <LocationAccess />
                </RequireAuth>
              }
            />

            <Route
              path="/"
              caseSensitive={false}
              element={
                <RequireAuth>
                  <BuildingsMaps />
                </RequireAuth>
              }
            />
          
          </Routes>
          </UserContext.Provider>
          <ToastContainer />
        </>
      </Router>
    </>
  );
}

export default App;
