import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import { successMessage } from "../services/utilities";

export default function MainBuildingsInformation() {
  const [showEditModal, setshowEditModal] = useState(true);
  const [buildingInformation, setbuildingInformation] = useState();

  const udpateIteminfov2 = (data, index, path, value, setdata) => {
    let temp = [...data];
    temp[index] = { ...temp[index], [path]: value };
    setdata(temp);
  };

  const locationUpdate = () => {
    httpService
      .get(`/v1/buildinglocation`)
      .then((response) => {
        const { data } = response;
        setbuildingInformation([...data]);
      })
      .catch((err) => {});
  };

  const deletleBuiding =(index,data,setdata)=>{
    let temp = [...data];
    temp.splice(index, 1);
    setdata(temp);
  }

  const updateInfomation =(index,data)=>{
    httpService.put(`/v1/buildinglocation/${index}`,data).then(res=>{
      const {data} = res;
      successMessage(`Building information updated successfully.`)
    }).catch(err=>{

    })
  }

  const createBuidling =(data)=>{
    httpService.post(`/v1/buildinglocation`,data).then(res=>{
      const {data} = res;
      successMessage(`Building ${data.name} created successfully.`);
      locationUpdate();
    }).catch(err=>{

    })
  }

  useEffect(() => {
    locationUpdate();
    return () => {
      
    }
  }, [])

  const addNewBuilding = ()=>{
    setbuildingInformation(old=>[...old,{name:"",description:"",location:{latitude:-25.5,longitude:28.0}}]);
  }
  
  return (
    <>
      <Header />
      <HeaderTitle name="Building Accesss Location" />
      <Modal
        forceRender={true}
        width={850}
        okType=""
        title={`Building Setups`}
        visible={showEditModal}
        onOk={() => {setshowEditModal(false)}}
        onCancel={() => setshowEditModal(false)}
      >
        <Button onClick={(e)=>addNewBuilding()} size="sm" variant="dark">+</Button>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Descrption</th>
              <th>Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {buildingInformation?.map((mapbuidlinginformation,index) => (
              <>
              
                <tr>
                  <td>
                  <Input defaultValue={mapbuidlinginformation.name} onChange={(e)=> udpateIteminfov2(buildingInformation,index,"name",e.currentTarget.value,setbuildingInformation)} placeholder="Name" />
                  </td>
                  <td>
                  <Input defaultValue={mapbuidlinginformation.description} onChange={(e)=> udpateIteminfov2(buildingInformation,index,"description",e.currentTarget.value,setbuildingInformation)} placeholder="Description" />
                  </td>
                  <td>
                    <td>
                      Latitude {" "}
                    <Input style={{marginTop:0, width:80}} defaultValue={mapbuidlinginformation?.location?.latitude} onChange={(e)=> udpateIteminfov2(buildingInformation,index,"location",{latitude:e.currentTarget.value,longitude:mapbuidlinginformation?.location?.longitude},setbuildingInformation)} placeholder="latitude" />
                    </td>
                    <td>
                      Longitude {" "}
                      <Input style={{marginTop:0,width:80}} defaultValue={mapbuidlinginformation?.location?.longitude} onChange={(e)=> udpateIteminfov2(buildingInformation,index,"location",{longitude:e.currentTarget.value,latitude:mapbuidlinginformation?.location?.latitude},setbuildingInformation)} placeholder="longitude" />
                    </td>
                  
                 
                  </td>
                  
                  <td>
                    {mapbuidlinginformation?._id && <Button style={{margin:5}} size="sm" variant="warning" onClick={(e)=>{

                    updateInfomation(mapbuidlinginformation?._id,{name:mapbuidlinginformation?.name,description:mapbuidlinginformation?.description, location:mapbuidlinginformation?.location})
                    }}>Update</Button>}
                    {!mapbuidlinginformation?._id && <Button style={{margin:5}} onClick={e=>{
                      createBuidling({name:mapbuidlinginformation.name, description:mapbuidlinginformation.description, location:mapbuidlinginformation.location});
                    }}  size="sm" variant="success">Create</Button>}      
                    {!mapbuidlinginformation?._id && <Button onClick={(e)=>deletleBuiding(index,buildingInformation,setbuildingInformation)} style={{margin:5}}  size="sm" variant="danger">Delete</Button>}                     
                 
                    
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Modal>
    </>
  );
}
