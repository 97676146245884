import { CopyFilled, UploadOutlined } from "@ant-design/icons";
import {
  Collapse,
  Input,
  Modal,
  Select,
  Upload,
  Button as ButtonAnt,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Container, Form } from "react-bootstrap";
import { CloudUpload, CloudUploadFill, Info } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { GridLoader } from "react-spinners";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import { errorMessage, successMessage } from "../services/utilities";

function AddAsset(props) {
  let fileReader;

  function handlloadEnd(e) {
    try {     
        var obej = JSON.parse(fileReader.result);
        obej["alarms"] = obej?.alarms?.map(alarm=>({...alarm,deviceId:assetInformation.controller}));
        obej["commands"] = obej?.commands?.map(command=>({...command,deviceId:assetInformation.controller}));
        
        setassetInformation(old=>({...old,...obej}))
    } catch (error) {
      setselectedFile(null);
    }
  }

  const changeHandler = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handlloadEnd;
    fileReader.readAsText(file);
  };
  const [selectedFile, setselectedFile] = useState(null);

  function createUpdate() {
    httpService.post(``);
  }

  const [assetInformation, setassetInformation] = useState({});

  const [showModal, setshowModal] = useState(false);

  function createUpdate() {
    if (props.type === "update") {
      httpService
        .put(`/v1/assets/${assetInformation._id}`, {
          name: assetInformation.name,
          description: assetInformation.description,
          controller: assetInformation.controller,
          alarms:assetInformation.alarms,
          commands:assetInformation.commands         
        })
        .then((responnse) => {
          setshowModal(false);
          successMessage(`${assetInformation.name} successfully updated !`);
          props.reload();
        })
        .catch((err) => {
          errorMessage(`Failed to update ${assetInformation.name}.(${err})`);
          props.reload();
        });
    } else
      httpService
        .post("/v1/assets", {
          name: assetInformation.name,
          description: assetInformation.description,
          controller: assetInformation.controller,
          alarms:assetInformation.alarms,
          commands:assetInformation.commands   
        })
        .then((responnse) => {
          successMessage(`Asset successfully created !`);
          props.reload();
        })
        .catch((err) => {
          errorMessage(`Failed to create asset (${err})`);
          props.reload();
        });
  }

  function loadDetails()
  {
      httpService.get(`/v1/assets/${props.selectedAsset._id}`).then(res=>{
          const {data}=res;
          setassetInformation({...data})
          setlaodingData(true);
      }).catch(err=> {setassetInformation({name:""})})
  }

  const [laodingData, setlaodingData] = useState(props.laoded);

  return (
    <>
      {props.type === "update" && (
        <Button
          style={{ margin: 5 }}
          onClick={(e) => {
            setshowModal(true)
            loadDetails();
          }}
          size="sm"
          variant="warning"
        >
          <Info />
        </Button>
      )}
      {props.type != "update" && (
        <Button
          style={{ margin: 5 }}
          onClick={(e) => {setshowModal(true);setlaodingData(true)}}
          size="sm"
          variant="dark"
        >
          +
        </Button>
      )}

      <Modal
     
        footer={[<Button size="sm" onClick={e=>createUpdate()} variant="success">Create</Button>]}
        forceRender={true}
        okType="success"
        width={500}
        title={props?.selectedAsset?.name || props.title}
        visible={showModal}
        
        
        onCancel={() => {
          setshowModal(false);
          setselectedFile(null);
          setassetInformation({name:""});
          setlaodingData(false);
        }}
      >
        {laodingData && <>
            <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="Asset Information" key="1">
            <div style={{ margin: 5 }}>
              Name :
              <Input
                value={assetInformation?.name}
                onChange={(e) =>
                  setassetInformation((old) => ({
                    ...old,
                    ["name"]: e.target.value,
                  }))
                }
                placeholder="Name"
              />
            </div>
            <div style={{ margin: 5 }}>
              Description :
              <Input
                value={assetInformation?.description}
                onChange={(e) =>
                  setassetInformation((old) => ({
                    ...old,
                    ["description"]: e.target.value,
                  }))
                }
                placeholder="Description"
              />
            </div>

            <div style={{ margin: 5 }}>
              Controller :
              <Select
                value={assetInformation?.controller}
                onChange={(e) =>
                  setassetInformation((old) => ({ ...old, ["controller"]: e }))
                }
                style={{ width: "100%" }}
              >
                {props?.controllers?.map((controller) => (
                  <Select.Option
                    key={controller.value}
                    value={controller.value}
                  >
                    {controller.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Collapse.Panel>
          <Collapse.Panel header="Defition Information" key="1">
            
            <div style={{ margin: 5 }}>
              <h6>Alarms</h6>
              {assetInformation?.alarms && (
                <>
                  {assetInformation?.alarms?.map((alarm) => (
                    <div>
                      {alarm.label} ({alarm.name})
                    </div>
                  ))}
                </>
              )}       
              
            </div>
            <div style={{ margin: 5 }}>
            <h6>Commands</h6>
              {assetInformation?.commands && (
                <>
                  {assetInformation?.commands?.map((alarm) => (
                    <div>
                      {alarm.name} : {alarm?.commands?.map(command=><Badge style={{marginLeft:2}} bg="warning">{command.label}</Badge>)}
                    </div>
                  ))}
                </>
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
        <div style={{ margin: 5 }}>
                <Form.Group controlId="formFileDisabled" className="mb-3">
                  <Form.Label>Upload Asset Definition</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => changeHandler(e.target.files[0])}
                  />
                </Form.Group>
              </div>
        
        </>}
      
      </Modal>
    </>
  );
}

export default function Assets() {
  const loadDevicelist = () => {
    httpService
      .get("/v1/building/availabledevices")
      .then((response) => {
        const { data } = response;
        setcontrollers([...data]);
      })
      .catch((error) => {
        setcontrollers([]);
      });
  };

  const loadAssetsList = () => {
    setisLoading(true)
    httpService
      .get("/v1/assets")
      .then((response) => {
        const { data } = response;
        setassetsAvailable([...data]);
        setisLoading(false)
      })
      .catch((err) => {
        setassetsAvailable([]);
        setisLoading(false)
      });
  };

  const [showAddingAsset, setshowAddingAsset] = useState(false);

  const [showEditAsset, setshowEditAsset] = useState(false);

  const [controllers, setcontrollers] = useState([]);

  const [assetsAvailable, setassetsAvailable] = useState([]);

  const [selectedAssets, setselectedAssets] = useState();

  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    loadDevicelist();
    loadAssetsList();
  }, []);

  return (
    <>
      {" "}
      <Header />
      <HeaderTitle name="Assets" />
      <Container>
        <AddAsset
          laoded={true}
          reload={loadAssetsList}
          selectedAsset={selectedAssets}
          controllers={controllers}
          title="New Asset"
          show={showAddingAsset}
          setShow={setshowAddingAsset}
        />
<div style={{textAlign:"center"}}>
     <GridLoader  margin={10} loading={isLoading} size={30} color="black" />
     </div>
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[
            {
              name: "Creation Date",
              selector: "createdDate",
              cell: (row) => (
                <>{moment(row.createdDate).format("YYYY-MM-Do HH:mm")}</>
              ),
            },
            { name: "Name", selector: "name" },
            { name: "Description", selector: "description" },
            {
              name: "",
              selector: "description",
              cell: (row) => (
                <>
                  <AddAsset
                    reload={loadAssetsList}
                    selectedAsset={row}
                    type="update"
                    controllers={controllers}
                    title={`Update ${row.name}`}
                    show={showEditAsset}
                    setShow={setshowEditAsset}
                  />
                </>
              ),
            },
          ]}
          data={assetsAvailable}
        />
      </Container>
    </>
  );
}
