import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Badge, Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Eraser, Info, InfoCircle, Plus } from 'react-bootstrap-icons'
import DataTable from 'react-data-table-component'
import Header from '../Components/Header'
import HeaderTitle from '../Components/HeaderTitle'
import httpService from '../services/httpService'
import { errorMessage, successMessage } from '../services/utilities'
import { MultiSelect } from "react-multi-select-component";
import CustomModal from '../Components/CustomModal'
import { GenericFormElement } from '../Components/GenericFormController'
import { GridLoader } from 'react-spinners'
import { Input } from 'antd'
import { UserContext } from '../context'
import { useLocalStorage } from '../hooks'

const DeleteButton = (props)=>{

    return (<>
    <Button variant='danger' size="sm" onClick={(e)=> props.handler(props._id)}><Eraser/></Button>
    </>)
}

const UpdateCompanie = (props)=>{

    const handleUpdateCompanyCreaion =()=>{

        var cabinets = selectedCabinets.map(a=> (a.value));

        httpService.put(`/v1/companies/${props._id}`,{
            name:companyDetails.name,
            cabinets:cabinets,
            assets:companyDetails.assets?.map(as=> as.value),
            notificants:contactList
        }).then(a=>{
            successMessage('Company Created Successfully');
            if(props.refresher)
            props.refresher();
        }).catch(a=>{
            const {response} = a;

            errorMessage(`Failed to create company (${response.data})`);
        })
    } 

    const colInfo = [
        {name:"name", title:"Name"},       
      
    ]
    const [companyDetails, setcompanyDetails] = useState({assets:[]});

    const [availableCabinets, setavailableCabinets] = useState([]);

    const [selectedCabinets, setselectedCabinets] = useState([]);

    const [assets, setassets] = useState([]);

    const [contactList, setcontactList] = useState([
      {},
      {},
      {}

    ]);

    const loadCabinets = () => {
        httpService
            .get("/v1/cabinets")
            .then((a) => {
                const { data } = a;

                var cabinetsOptions = data.map(a=> ({label:a.name, value:a._id}))

                setavailableCabinets([...cabinetsOptions]);
            })
            .catch((err) => { });
    };    


    const laodAssets = () => {
      httpService
          .get("/v1/assets/lite")
          .then((a) => {
              const { data } = a;

              var cabinetsOptions = data.map(a=> ({label:a.name, value:a._id}))

              setassets([...cabinetsOptions]);
          })
          .catch((err) => { });
  };  

    const loadCompanyInfo = (id) => {
        httpService
            .get(`/v1/companies/${id}`)
            .then((a) => {
                const { data } = a;
                var cabinetsOptions = data.cabinets.map(a=> ({label:a.name, value:a._id}));
                setcompanyDetails(old=>({...old,name:data.name, assets:data.assets}))
                setselectedCabinets([...cabinetsOptions]);

               
                if(data?.notificants?.length > 0)
                setcontactList([...data?.notificants?.map(notificant=>({name:notificant.name, email:notificant.email, phone:notificant.phone}))])
            })
            .catch((err) => { });
    };    
    

    const updateValue =(e)=>{    
        setcompanyDetails(oldValue=>({...oldValue,[e.target.name]:e.target.value}))
      }

    const updateValueNew = (e,index,data, setdata, name)=>{
      let current = [...contactList];
      current[index][name]=e.target.value;
      setdata([...current]);
    }

    return (
      <>
        <CustomModal
          load={() => {
            loadCompanyInfo(props._id);
            loadCabinets();
            laodAssets();
          }}
          canProccess={true}
          proccess={handleUpdateCompanyCreaion}
          size="lg"
          title="Update Company"
          proccessTitle={"Update"}
          icon={<InfoCircle />}
        >
          <Row>
            <Col>
              {colInfo.map((a) => (
                <GenericFormElement
                  {...a}
                  value={companyDetails[a.name]}
                  handleChange={updateValue}
                />
              ))}
            </Col>
          </Row>

         
          <Row>
          <Form.Label>Assets</Form.Label>
          
            <MultiSelect
              value={companyDetails?.assets}
              options={assets}
              
              onChange={e=> setcompanyDetails(old=>({...old,assets:e}))}
              labelledBy="Select"
            />
          </Row>
          <Row>
          <Form.Label>Contact Details</Form.Label>
          <Table hover size="sm" responsive={true}>
                <thead>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  
                  
                </thead>
                <tbody>
                 
                  {contactList?.map((contact,index)=><tr>
                    <td>
                    <td><Input value={contact?.name} onChange={e=> updateValueNew(e, index,contactList,setcontactList,"name")}/></td>
                    </td>
                    <td><Input value={contact?.email} onChange={e=> updateValueNew(e, index,contactList,setcontactList,"email")}/></td>
                    <td><Input value={contact?.phone} onChange={e=> updateValueNew(e, index,contactList,setcontactList,"phone")}/></td>
                  </tr>)}
                  </tbody>
                  </Table>
          </Row>
        </CustomModal>
      </>
    );
}



const CreateCompanie = (props)=>{

    const handleNewCompanyCreaion =()=>{

        var cabinets = selectedCabinets.map(a=> (a.value));

        httpService.post('/v1/companies',{
            name:companyDetails.name,
            cabinets:cabinets
        }).then(a=>{
            successMessage('Company Created Successfully');
            if(props.refresher)
            props.refresher();
        }).catch(a=>{
            const {response} = a;

            errorMessage(`Failed to create company (${response.data})`);
        })
    }

 

    const colInfo = [
        {name:"name", title:"Name"},       
      
    ]
    const [companyDetails, setcompanyDetails] = useState({});

    const [availableCabinets, setavailableCabinets] = useState([]);

    const [selectedCabinets, setselectedCabinets] = useState([]);
    

    const loadCabinets = () => {
        httpService
            .get("/v1/cabinets")
            .then((a) => {
                const { data } = a;

                var cabinetsOptions = data.map(a=> ({label:a.name, value:a._id}))

                setavailableCabinets([...cabinetsOptions]);
            })
            .catch((err) => { });
    };    

    const updateValue =(e)=>{    
        setcompanyDetails(oldValue=>({...oldValue,[e.target.name]:e.target.value}))
      }

    return (
      <>
        <CustomModal
          load={() => {
            loadCabinets();
          }}
          canProccess={true}
          proccess={handleNewCompanyCreaion}
          size="md"
          title="New Company"
          proccessTitle={"Create"}
          icon={<Plus />}
        >
          <Row>
            <Col>
              {colInfo.map((a) => (
                <GenericFormElement
                  {...a}
                  value={companyDetails[a.name]}
                  handleChange={updateValue}
                />
              ))}
            </Col>
          </Row>

         
        </CustomModal>
      </>
    );
}

export default function Companies() {

    const {userDetails} = useContext(UserContext);

    const [companies, setcompanies] = useState([]);

    const [isLoading, setisLoading] = useState(false)

    const colums =[
        {sortable:true,name:"Create Date", selector:(row)=>row?.creationDate, cell : (row) => <>{moment(row?.creationDate).format("MMMM Do YYYY, h:mm:ss a")}</>},
        {sortable:true,name:"name", selector:(row)=>row?.name},
        {sortable:true,name:"Assets", selector:(row)=>row?.assets, cell: (row)=> (<><Badge bg="success" >{row.assets.length}</Badge></>)},
        {name:"", cell: (row)=> <>
        <DeleteButton  handler={deleteCompanies} _id={row._id} /> <UpdateCompanie refresher={loadCompanies} _id={row._id}  />
        </>},
    ]

    const loadCompanies = ()=>{
      setisLoading(true)
        httpService.get('/v1/companies').then(a=>{
            const {data} = a;
            setcompanies([...data]);
            setisLoading(false)
        }).catch(err=>{
          setisLoading(false)
        })
    }

    const deleteCompanies = (id)=>{

        var confirm = window.confirm(`Are you sure you want to delele this company ?`);
    
    if(confirm)
        httpService.delete(`/v1/companies/${id}`).then(a=>{
            const {data} = a;
            successMessage(`Company successfully deleted`);

            loadCompanies();
        }).catch(er=>{
            errorMessage(`Failed to delete company`);
        })
    }
    useEffect(() => {
        loadCompanies();
    }, [])

    const  {counter,isOnline} = useLocalStorage();

    return (
        <>
                <Header />
    <HeaderTitle name="Companies" />
    <Container>
      <></>


     <CreateCompanie />
     <div style={{textAlign:"center"}}>
         <GridLoader  margin={10} loading={isLoading} size={30} color="black" />
     </div>
     {`is the internet there : ${JSON.stringify(isOnline)}`}
     {/* {JSON.stringify(counter)}
     {JSON.stringify(userDetails)} */}
     
      <DataTable
      pagination={true}
      paginationPerPage={10}
     
        columns={colums}
        data={companies}
      // expandableRows
      // expandableRowsComponent={ExpandedComponent}
      /> 
    </Container>
        </>
    )
}
