import { Button, Card, Input, message,Descriptions,Image } from "antd";
import { Container } from "react-bootstrap";

import { useEffect, useState } from "react";
import httpService from "../../services/httpService";
import { closeMQTTConnection, ConnectToMqtt, } from "../../services/utilities";
import moment from "moment";
import { useSocketIO } from "../../hooks";
var QRCode = require('qrcode.react');



function LocationActivation({ callback,credentials }) {
    const [busy, setbusy] = useState(false)

    function ActivateDiplay() {
        setbusy(true);
        httpService.post('/v1/auth/display', displayActivationInformation).then(response => {
            const { data } = response;
            message.success("The Display activation was Successfull !");
            callback(data);
            setbusy(false);
            credentials(displayActivationInformation);
        }).catch(err => {
            message.error(`Failed to activate the display (${err?.response?.data || err})`);
            setbusy(false);
        })
    }


    const [displayActivationInformation, setdisplayActivationInformation] = useState({
        key: "",
        code: ""
    })
    return (
        <div style={{ marginTop: 50, textAlign: "center" }}>
            <Card bordered={true} title="Display Setting.">

                <div >
                    
                    <Card.Meta title="Please Enter the Location Display Access Code" />
                    <Input type={"password"} value={displayActivationInformation.key} onChange={e => setdisplayActivationInformation(old => ({ ...old, "key": e.target.value }))} style={{ width: 400, margin: 10 }} />
                    <Card.Meta title="Please Enter the Location Display Activattion Code" />
                    <Input value={displayActivationInformation.code} onChange={e => setdisplayActivationInformation(old => ({ ...old, "code": e.target.value.toUpperCase() }))} maxLength={8} style={{ width: 400, margin: 10 }} />
                </div>
                <Button disabled={busy} onClick={e => ActivateDiplay()} style={{ background: "green" }}>Activate</Button>
            </Card>
        </div>
    );
}

function LocationDisplayInformation({ id, name, description,date,displayCode,connectionLost,connectionRetryCount }) {

    return (
        <div style={{ marginTop: 50, textAlign: "center" }}>
            {date && <h3 style={{ margin: 5  }} >{date.format("YYYY-MM-DD HH:mm:ss")}</h3>}
            <Card bordered={true} title={<h4>Welcome!</h4>}>
             
             {connectionLost && <>
             <h4 style={{color:"red"}}>The connection to the server has been lost, trying to reconnect! ({connectionRetryCount})</h4>
             </>}
             {!connectionLost && <>
                <h5>Please Scan the QRCode</h5>
                <QRCode style={{ margin: 5 }} size={150} value={`democlient0001,${displayCode}`} />
                <h3>{name}</h3>
                <h4 >{description}</h4>
             </>}
                

            </Card>
        </div>
    );
}

function AccessDisplayInformation({ person, location, message, canProccess }) {
    return (

        <div style={{ marginTop: 10, textAlign: "center" }}>
            <Card bordered={true} title={<h4 style={{ textAlign: "center" }}>Hello {person?.name} {person?.surname}!</h4>}>
                <Image height={200} width={200} style={{ borderRadius: 100 }} src={`/v1/people/${person.id}/picture`} />
                <Descriptions title="User Info" bordered>

                    <Descriptions.Item label="Name">{person?.name}</Descriptions.Item>
                    <Descriptions.Item label="Surname">{person?.name}</Descriptions.Item>
                    <Descriptions.Item label="Company">{person?.company}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="Message" bordered>

                </Descriptions>
                <p>
                <h1 style={{ color: canProccess ? "green" : "red" }}>
                    {message}
                </h1>
                </p>
               
            </Card>
        </div>


    );
}

function ActivateLocationWithCredentials(callback,displayActivationInformation) {
    
    httpService.post('/v1/auth/display', displayActivationInformation).then(response => {
        const { data } = response;
        message.success("The Display activation was Successfull !");
        callback(data);
       
    }).catch(err => {
        message.error(`Failed to activate the display (${err?.response?.data || err})`);
        
    })
}

export default function LocationDisplay() {
    var CountDisconnnect=0;

    const [lcoationInformation, setlcoationInformation] = useState();
    const [userAccessInformatino, setuserAccessInformatino] = useState();
    const [displayUser, setdisplayUser] = useState(false);
    const [currentDate, setcurrentDate] = useState();
    const [lostConenction, setlostConenction] = useState(false);
    const [currentDisplayCredentials, setcurrentDisplayCredentials] = useState();
    
    const [DisconnecCount, setDisconnecCount] = useState(0);

    const  {isConnected}= useSocketIO(null,(topic,payload)=>{})

    

    function AuthenticationDetailsReceived(data) {
        var topic = `/v2/${data.CID}/location/${data?.location._id}`;

        ConnectToMqtt(topic, ProccessNewPaylaod, data.username, data.token, data.token, data.ws, null,(lc)=> {setlostConenction(true);setDisconnecCount(old=> (old+1));CountDisconnnect++;},(bc)=> {
           
            setlostConenction(false);
            setDisconnecCount(0);
            CountDisconnnect=0;

            if(CountDisconnnect>0)
            {             
                //     ActivateLocationWithCredentials((done)=>{
                //      AuthenticationDetailsReceived(done); setlcoationInformation(done) ;
                //      setlostConenction(false);
                //      setDisconnecCount(0);
                //      CountDisconnnect=0;
                // },currentDisplayCredentials)
            }
           
        });
    }

    function ProccessNewPaylaod(topc, msg) {
        try {

            var payloadInformation = JSON.parse(msg);
            setuserAccessInformatino({ ...payloadInformation });
            setlcoationInformation(old=>({...old,location:{...old.location,displayCode:payloadInformation?.location?.displayCode}}))
            setdisplayUser(true);
            setTimeout(() => {
                setdisplayUser(false);
            }, 5000);
        } catch (error) {
        }
    }

    useEffect(() => {

        var timeInterval = setInterval(() => {
            setcurrentDate(moment());
        }, 1000); 

        return () => {
            clearTimeout(timeInterval);

            closeMQTTConnection(() => {
                setlcoationInformation(false);
                setuserAccessInformatino(false);
                setdisplayUser(false);
            });
        };

    }, [])
    return (<>
        <Container>            
            
            {!lcoationInformation && <LocationActivation credentials={e=> setcurrentDisplayCredentials({...e})} callback={e => { AuthenticationDetailsReceived(e); setlcoationInformation(e) }} />}
            {lcoationInformation &&
                <>
                    {!displayUser && <LocationDisplayInformation connectionRetryCount={DisconnecCount} connectionLost={lostConenction} {...lcoationInformation.location}  date={currentDate} />}
                    {displayUser && <AccessDisplayInformation {...userAccessInformatino}  />}
                </>}
        </Container>
    </>)
}