import React, { useRef, useState } from 'react'
import { Camera2 } from 'react-bootstrap-icons'
import { CustomModalV2 } from './CustomModal'
import { Camera } from 'react-camera-pro'
import { Button, Form, Image } from 'react-bootstrap';
import Webcam from 'react-webcam';


const DisplayCurrentPicture = (props)=>{
  return (<> <div 
  style={{ textAlign:"center" }}>
  {props.visitorPicture && <Image roundedCircle={true} fluid={true} height={200} width={200} src={props.visitorPicture} />}

</div></>)
}

export default function CameraCaptureModal(props) {
  const webcamRef = React.useRef(null);

 

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log(imageSrc);
    if(props.setPicture)
    props.setPicture(imageSrc);
  
  }, [webcamRef]); 

  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user"
  };

  const handleInputChange = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {      
     if(props.setPicture)
       props.setPicture(e.target.result);
    }
}

  return (
   <>
    <CustomModalV2 closeAfterProccess={true} centered={props.centered} size="lg" proccess={capture} canProccess={true} proccessTitle={props.type || "Capture"} title={props.title || `Capture User Picture`} mainComponents={ <><h4>Picture <Button variant={props.variant || 'dark'} size='sm' >  {props.icon || <Camera2 />} </Button> </h4>
    
    <DisplayCurrentPicture  visitorPicture={props.picture}/>
   
   
     </> }>
    
   
   
   <div style={{textAlign:"center"}}>
   <Webcam  ref={webcamRef}  screenshotFormat="image/jpeg"  screenshotQuality={0.2}  audio={false} videoConstraints={videoConstraints}  />
   {/* <div style={{textAlign:"center"}}>
   <Form.Group   controlId="formFile" className="mb-3">
    <Form.Label>Select Picture</Form.Label>
    <Form.Control onChange={handleInputChange} variant="dark" type="file" />
  </Form.Group>
   </div> */}
   </div>


 
    
   </CustomModalV2>
   </>
  )
}
