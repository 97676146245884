import { Cascader, Collapse, Input, Modal, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Button, Table } from "react-bootstrap";
import { Info } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { GridLoader } from "react-spinners";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import { errorMessage, successMessage } from "../services/utilities";

const { Option } = Select;

function AddUpdateStrategy(props) {
  const [strategyInformation, setstrategyInformation] = useState({...props?.data, locations:props?.data?.accessLocation?.map(acesslocamap=> acesslocamap._id)});
  const [stategiesCommands, setstategiesCommands] = useState([]);
  const [assets, setassets] = useState();

  const [payload, setpayload] = useState();

  const CommandsAvailabale =[
      "OPEN DOOR 1",
      "OPEN DOOR 2",
      "LOCK DOOR 1",
      "LOCK DOOR 2",
      "OPEN_FRONT_DOOR",
      "OPEN_BACK_DOOR"
  ]

  const checkAlarms =[
    "DOOR 1 OPENED",
    "DOOR 2 OPENED",
]

  function findAssets (id)
  {
     setstrategyInformation(old=>({...old,["company"]:id}));
      httpService.get(`/v1/companies/assets/${id}`).then(res=>{
          const {data} =res;
          setassets([...data.assets]);
      }).catch(err=>{
        setassets([])
      })
  }

  function buildComamndToExecute(commands)
  {
    var mycomamnds = commands?.map(mycommandsmap=>{
        var tempmap = {label:mycommandsmap.name,value:mycommandsmap.name, children:mycommandsmap?.commands?.map(commandsmap=>({label:commandsmap.label,value:`${commandsmap.label},${commandsmap.command}`}) || [])};
        return tempmap;
      })

      return mycomamnds;
  }

  function createStrategy()
  {
    var mypayload =  {
        name:strategyInformation.name,
        companies:strategyInformation.companies,
        accessLocation   :strategyInformation?.locations,
        onEntranceassetsComamnds:strategyInformation.onEntranceassetsComamnds,
        onExitceassetsComamnds:strategyInformation.onExitceassetsComamnds,
        onExitcheckThisAlams:strategyInformation.onExitcheckThisAlams

    }   

    if(props.type==="update")
    {
        httpService.put(`/v1/assetsstrategies/${strategyInformation._id}`,mypayload).then(resp=>{
            successMessage(`Strategy ${mypayload.name} successfully updated !`)
            if (props?.close) props?.close();
        }).catch(err=>{
            errorMessage(`Failed to upate strategy (${err})`)
        })
       
    }
    else
    {  
          httpService.post(`/v1/assetsstrategies`,mypayload).then(resp=>{
        successMessage(`Strategy ${mypayload.name} created successfully!`)
    }).catch(err=>{
        errorMessage(`Failed to create strategy (${err})`)
    })

    }


  }

  const udpateIteminfo = (index, path, value) => {
    let temp = [...assets];
    temp[index] = { ...temp[index], [path]: value };
    setassets(temp);
  };
  return (
    <>
       {props.type==="update"  && <Button onClick={(e) => props.setShow(true)} size="sm" variant="warning">
        <Info/>
      </Button>}
      {props.type!="update"  && <Button onClick={(e) => props.setShow(true)} size="sm" variant="dark">
        +
      </Button>}
      
      <Modal
        forceRender={true}
        footer={[<Button size="sm" onClick={e=>createStrategy()} variant="success">Submit</Button>]}
        okType="success"
        width={600}
        title={`Access Stragetegy`}
        visible={props?.show}
        onOk={() => {createStrategy()}}
        onCancel={() => {
          if (props?.close) props?.close();
        }}
      >
        <p>       
           
          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel header="Strategy" key="1">
            <div style={{ margin: 5 }}>
            Strategy Name :
            <Input defaultValue={strategyInformation?.name} onChange={e=>setstrategyInformation(old=>({...old,name:e.target.value || ""}))} placeholder="Strategy Name" />
          </div>
          <div style={{ margin: 5 }}>
            Companies :
            <Select value={strategyInformation?.companies}  id="23"  onChange={(e) => {
                setstrategyInformation((oldinfo) => ({
                  ...oldinfo,
                  companies: e,
                }));
              }} style={{ width: "100%" }}  mode="tags">
              {props?.companies?.map((mapemails) => (
                <Select.Option key={mapemails.value} value={mapemails.value}>
                  {mapemails.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ margin: 5 }}>
            Locations :
            <Select
              value={strategyInformation?.locations?.map(maplocations=> maplocations)}
              id="d"
              onChange={(e) => {
                setstrategyInformation((oldinfo) => ({
                  ...oldinfo,
                  locations: e,
                }));
              }}
              style={{ width: "100%" }}
              mode="tags"
            >
              {props?.locations?.map((mapemails) => (
                <Select.Option key={mapemails.value} value={mapemails.value}>
                  {mapemails.label}
                </Select.Option>
              ))}
            </Select>
            
            
          </div>
                </Collapse.Panel>
          <Collapse.Panel header="Actions" key="2">
          <div style={{ margin: 5 }}>
            On Entrance :
            <Select
              value={strategyInformation?.onEntranceassetsComamnds}
              id="d"
              onChange={(e) => {
                setstrategyInformation((oldinfo) => ({
                  ...oldinfo,
                  onEntranceassetsComamnds: e,
                }));
              }}
              style={{ width: "100%" }}
              mode="tags"
            >
              {CommandsAvailabale.map((mapemails) => (
                <Select.Option key={mapemails} value={mapemails}>
                  {mapemails}
                </Select.Option>
              ))}
            </Select>
            
            
          </div>      
          <div style={{ margin: 5 }}>
            On Exit :
            <Select
              value={strategyInformation?.onExitceassetsComamnds}
              id="dere4545"
              onChange={(e) => {
                setstrategyInformation((oldinfo) => ({
                  ...oldinfo,
                  onExitceassetsComamnds: e,
                }));
              }}
              style={{ width: "100%" }}
              mode="tags"
            >
              {CommandsAvailabale.map((mapemails) => (
                <Select.Option key={mapemails} value={mapemails}>
                  {mapemails}
                </Select.Option>
              ))}
            </Select>
            
            
          </div>   
          <div style={{ margin: 5 }}>
            On Exit Check :
            <Select
              value={strategyInformation?.onExitcheckThisAlams}
              id="dere4545"
              onChange={(e) => {
                setstrategyInformation((oldinfo) => ({
                  ...oldinfo,
                  onExitcheckThisAlams: e,
                }));
              }}
              style={{ width: "100%" }}
              mode="tags"
            >
              {checkAlarms.map((mapemails) => (
                <Select.Option key={mapemails} value={mapemails}>
                  {mapemails}
                </Select.Option>
              ))}
            </Select>
            
            
          </div>   
           
          {/* <Table responsive>
         
                <thead>
                    <th>Asset</th>
                    <th>On Entrance</th>
                    <th>On Exit</th>
                    <th>On Exit Check</th>
                </thead>
                <tbody>

                   
                 {assets?.map((mapstrat,index)=>(<>
                 <tr>
                     <td>
                    {mapstrat.name}
                     </td>
                     <td>
                     
                     <Cascader
                            
                            options={buildComamndToExecute(mapstrat?.commands)}
                            onChange={(e)=>{udpateIteminfo(index,"onEntranceassetsComamnds",e)}}
                            multiple
                            maxTagCount="responsive"
                            // defaultValue={mapalarmSettings?.commandToExecute}
                            // showCheckedStrategy={SHOW_CHILD}
                          />
                     </td>
                     <td>
                    
                     <Cascader
                            
                            options={buildComamndToExecute(mapstrat?.commands)}
                            onChange={(e)=>{udpateIteminfo(index,"onExitceassetsComamnds",e)}}
                            multiple
                            maxTagCount="responsive"
                            // defaultValue={mapalarmSettings?.commandToExecute}
                            // showCheckedStrategy={SHOW_CHILD}
                          />
                     </td>
                     <td>
                     <div >
           
            <Select
              id="deewe"
              
              style={{ width: "100%" }}
              mode="tags"
            >
              {mapstrat?.alarms?.map((alarmmap) => (
                <Select.Option key={alarmmap.name} value={alarmmap.name}>
                  {alarmmap.label}
                </Select.Option>
              ))}
            </Select>
            
            
          </div>
                     </td>
                 </tr>
                 </>))}
                </tbody>
            </Table> */}
          </Collapse.Panel>
          </Collapse>
         
        </p>
      </Modal>
    </>
  );
}

function AddGlobalStrategy(props) {
    const [strategyInformation, setstrategyInformation] = useState({...props?.data, locations:props?.data?.accessLocation?.map(acesslocamap=> acesslocamap._id)});
    const [stategiesCommands, setstategiesCommands] = useState([]);
    const [assets, setassets] = useState();
  
    const [payload, setpayload] = useState();
  
    function findAssets (id)
    {
       setstrategyInformation(old=>({...old,["company"]:id}));
        httpService.get(`/v1/companies/assets/${id}`).then(res=>{
            const {data} =res;
            setassets([...data.assets]);
        }).catch(err=>{
          setassets([])
        })
    }
  
    function buildComamndToExecute(commands)
    {
      var mycomamnds = commands?.map(mycommandsmap=>{
          var tempmap = {label:mycommandsmap.name,value:mycommandsmap.name, children:mycommandsmap?.commands?.map(commandsmap=>({label:commandsmap.label,value:`${commandsmap.label},${commandsmap.command}`}) || [])};
          return tempmap;
        })
  
        return mycomamnds;
    }
  
    function createStrategy()
    {
      var mypayload =  {
          name:strategyInformation.name,
          company:strategyInformation.company,
          accessLocation   :strategyInformation?.locations,
          onEntranceassetsComamnds:assets?.map(mapcmdsentrance=>({commands:mapcmdsentrance?.onEntranceassetsComamnds, _id:mapcmdsentrance?._id})),
          onExitceassetsComamnds:assets?.map(mapcmdsentrance=>({commands:mapcmdsentrance?.onExitceassetsComamnds, _id:mapcmdsentrance?._id}))
      }
  
      setpayload({...mypayload});
  
      httpService.post(`/v1/assetsstrategies`,payload).then(resp=>{
          successMessage(`Strategy ${payload.name} created successfully!`)
      }).catch(err=>{
          errorMessage(`Failed to create strategy (${err})`)
      })
    }
  
    const udpateIteminfo = (index, path, value) => {
      let temp = [...assets];
      temp[index] = { ...temp[index], [path]: value };
      setassets(temp);
    };
    return (
      <>
        <Button onClick={(e) => props.setShow(true)} size="sm" variant="dark">
          +
        </Button>
        <Modal
          forceRender={true}
          okType="success"
          width={900}
          title={`Access Stragetegy`}
          visible={props?.show}
          onOk={() => {createStrategy()}}
          onCancel={() => {
            if (props?.close) props?.close();
          }}
        >
          <p>
          
            <Collapse defaultActiveKey={["1"]}>
              <Collapse.Panel header="Strategy" key="1">
              <div style={{ margin: 5 }}>
              Strategy Name :
              <Input defaultValue={strategyInformation?.name} onChange={e=>setstrategyInformation(old=>({...old,name:e.target.value || ""}))} placeholder="Strategy Name" />
            </div>
            <div style={{ margin: 5 }}>
              Companies :
              <Select   id="223sdd3" onChange={(e) => {
                  setstrategyInformation((oldinfo) => ({
                    ...oldinfo,
                    companies: e,
                  }));
                }} style={{ width: "100%" }}  mode="tags">
                {props?.companies?.map((mapemails) => (
                  <Select.Option key={mapemails.value} value={mapemails.value}>
                    {mapemails.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ margin: 5 }}>
              Locations :
              <Select
                value={strategyInformation?.locations?.map(maplocations=> maplocations)}
                id="d"
                onChange={(e) => {
                  setstrategyInformation((oldinfo) => ({
                    ...oldinfo,
                    locations: e,
                  }));
                }}
                style={{ width: "100%" }}
                mode="tags"
              >
                {props?.locations?.map((mapemails) => (
                  <Select.Option key={mapemails.value} value={mapemails.value}>
                    {mapemails.label}
                  </Select.Option>
                ))}
              </Select>
              
              
            </div>
                  </Collapse.Panel>
            <Collapse.Panel header="Actions" key="2">
                           
            <Table responsive>
           
                  <thead>
                      <th>Asset</th>
                      <th>On Entrance</th>
                      <th>On Exit</th>
                      <th>On Exit Check</th>
                  </thead>
                  <tbody>
  
                     
                   {assets?.map((mapstrat,index)=>(<>
                   <tr>
                       <td>
                      {mapstrat.name}
                       </td>
                       <td>
                       
                       <Cascader
                              
                              options={buildComamndToExecute(mapstrat?.commands)}
                              onChange={(e)=>{udpateIteminfo(index,"onEntranceassetsComamnds",e)}}
                              multiple
                              maxTagCount="responsive"
                              // defaultValue={mapalarmSettings?.commandToExecute}
                              // showCheckedStrategy={SHOW_CHILD}
                            />
                       </td>
                       <td>
                      
                       <Cascader
                              
                              options={buildComamndToExecute(mapstrat?.commands)}
                              onChange={(e)=>{udpateIteminfo(index,"onExitceassetsComamnds",e)}}
                              multiple
                              maxTagCount="responsive"
                              // defaultValue={mapalarmSettings?.commandToExecute}
                              // showCheckedStrategy={SHOW_CHILD}
                            />
                       </td>
                       <td>
                       <div >
             
              <Select
                id="deewe"
                
                style={{ width: "100%" }}
                mode="tags"
              >
                {mapstrat?.alarms?.map((alarmmap) => (
                  <Select.Option key={alarmmap.name} value={alarmmap.name}>
                    {alarmmap.label}
                  </Select.Option>
                ))}
              </Select>
              
              
            </div>
                       </td>
                   </tr>
                   </>))}
                  </tbody>
              </Table>
            </Collapse.Panel>
            </Collapse>
           
          </p>
        </Modal>
      </>
    );
  }
  

export default function AccessStragetegies() {
  const [strategies, setstrategies] = useState([]);
  const [showAddUpdate, setshowAddUpdate] = useState(false);
  const [companies, setcompanies] = useState([]);
  const [locations, setlocations] = useState([]);

  const [isLoading, setisLoading] = useState(false);

  const [showUpdate, setshowUpdate] = useState(false)

  function loadcompanies() {
    httpService
      .get("/v1/companies/lite")
      .then((res) => {
        const { data } = res;
        var newData = data.map((mapdata) => ({
          value: mapdata._id,
          label: mapdata.name,
        }));
        setcompanies([...newData]);
      })
      .catch((err) => {});
  }

  function loadLocations() {
   
    httpService
      .get("/v1/LocationsAccess/lite")
      .then((res) => {
        const { data } = res;
        var newData = data.map((mapdata) => ({
          value: mapdata._id,
          label: mapdata.name,
        }));
        setlocations([...newData]);
       
      })
      .catch((err) => {});
  }

  function loadStrategies ()
  {
    setisLoading(true);
      httpService.get('/v1/assetsstrategies').then(resposne=>{
        const {data} = resposne;
        
        setstrategies([...data]);
        setisLoading(false);
      }).catch(err=>{
        setstrategies([])
        setisLoading(false);
      })
  }

  useEffect(() => {
    loadcompanies();
    loadLocations();
    loadStrategies();
  }, []);

  return (
    <>
      <Header />
      <HeaderTitle name="Access Stragetegies" />
      <Container>
        <AddUpdateStrategy
          locations={locations}
          companies={companies}
          setShow={setshowAddUpdate}
          show={showAddUpdate}
          close={() => setshowAddUpdate(false)}
        />
        <div style={{textAlign:"center"}}>
     <GridLoader  margin={10} loading={isLoading} size={30} color="black" />
     </div>
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[
              {name:"Creation Date", selector:"creationDate", cell:row=> moment(row.creationDate).format("YYYY-MM-DD HH:mm"),sortable:true},
              {name:"Name", selector:"name", sortable:true},
              {name:"Locations", selector:"accessLocation" , cell:row=> <><p>{row?.accessLocation?.map(accessmap=> <div style={{margin:2}}>{accessmap?.name}</div>)}</p> </>},
              {name:"Locations", selector:"_id" , cell:row=> <> 
              <AddUpdateStrategy
              type="update"
              data={row}
              locations={locations}
              companies={companies}
              setShow={setshowUpdate}
              show={showUpdate}
              close={() => setshowUpdate(false)}
            /></>}
            ]}
          data={strategies}
        />
      </Container>
    </>
  );
}
