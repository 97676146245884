import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import HeaderTitle from '../Components/HeaderTitle';
import DataTable from "react-data-table-component";
import moment from 'moment';
import { Badge, Card, Container,Image } from 'react-bootstrap';
import httpService from '../services/httpService';
import {closeMQTTConnection, connectToMQTTBroker, connectWS, disconnectWS, errorMessage, getProfile, successMessage} from '../services/utilities';
import { UpdatePersonV2, UpdatePersonV3 } from './People';
import { DotLoader, GridLoader } from 'react-spinners';
import { useSocketIO } from '../hooks';
import { LostConnectionMessage } from '../Components/LostConnectionMessage';

var profile =JSON.parse(localStorage.getItem("profileInfo")) ; 
    var topic =`/v2/${profile?.CID}/accessEvents`;   

export function SimpleActivities(props)
{

  const generateColor =(type)=>
  {
    if(type==="success") return "green"
    else if(type==="warning") return "orange"
    else  return "green"
  }

  return (<>
  
  {props.activities.map(ac=><>
  
  <Card style={{marginTop:5, color:"white", background:"black"}}>
 
  
  <Card.Body>

  {ac?.person?.picture && <div><UpdatePersonV3 id={ac?.person?._id} label={ <div><div title={moment(ac?.date).format("YYYY-MM-DD HH:mm:ss")}>{moment(ac?.date).fromNow(false)}</div><Image roundedCircle={true} fluid={true} style={{width:50,height:50, margin:2}} src={`${ac?.person?.picture}`} /> {`${ac?.person?.name} ${ac?.person?.surname}`}   </div>} /> </div>}
 <p style={{color:generateColor(ac?.type)}}>{ac?.message}</p>
  </Card.Body>
</Card>
  </>)}
  </>)
}

export function AlarmActivities(props)
{

  const generateColor =(type)=>
  {
    if(type==="success") return "green"
    else if(type==="warning") return "orange"
    else  return "green"
  }

  return (<>
  
  {<>
  
  <Card style={{marginTop:5, color:"white", background:props.type ?? "red", borderRadius:10}}>
  <Card.Body>
  <div title={moment(props?.date).format("YYYY-MM-DD HH:mm:ss")}>{moment(props?.date).fromNow(false)}</div>
  
  
 <p >
 <h4 style={{color:"white"}}>{props?.name}</h4>
   {props?.izone}
  </p>
  </Card.Body>
</Card>
  </>}
  </>)
}

function Activities(props) {

  var profile = getProfile();

  const setActivityColour = (activity) =>{

    if(activity==="Entrance")
    return <Badge bg={"danger"}>{activity}</Badge>
    else if (activity==="Exit")
    return <Badge bg={"success"}>{activity}</Badge>
    else
    return  <Badge bg={"warning"}>{activity}</Badge>

  }

  const [activitiesList, setactivitiesList] = useState([]);
  const activitiesColums =[
    
    {sortable:true,name:"Time",selector:"date",grow:2, cell:(row)=> moment(row.date).format('MMMM Do YYYY, h:mm:ss a')},
    {sortable:true,name:"Person",selector:(row)=> row?.person?._id,grow:3, cell:(row)=>(<>
      {<> {row?.person?.picture && <Image roundedCircle={true} fluid={true} style={{width:100,height:100, margin:2}} src={`/v1/people/${row?.person?._id}/picture`} />} {row?.person?.name } {row?.person?.surname}  </>}
        
      </>)}
  ,
    {sortable:true,name:"Type", selector:"type", cell :(row)=>(<>
    {row.type==="success" &&  <Badge bg={"success"}>{row.type}</Badge>}
    {row.type==="warning" &&  <Badge bg={"warning"}>{row.type}</Badge>}
    {row.type==="failure" &&  <Badge bg={"danger"}>{row.type}</Badge>}
    </>)},
    {name:"Message", selector:"message", grow:3,cell :(row)=>(<p>{row.message}</p>)},
    
    {sortable:true,name:"Activity",  selector:(row)=> row?.location?.activity, cell :(row)=>(<>
    {setActivityColour(row?.location?.activity)}
      </>)},
       {sortable:true,name:"Location",selector:(row)=> row?.location?.name, cell:(row)=> <>{row?.location?.name}</>},
  ]
  const [isLoading, setisLoading] = useState(true)

function loadStatus()
{
  setisLoading(true)
  if(profile.userType==="admin")
    {
      httpService.get('/v1/activity').then(re=>{
        const {data} =re;
        setactivitiesList([...data]);
        setisLoading(false);
      }).catch(er=>{
        setisLoading(false);
      })
    }
    else{
    
      httpService.get('/v1/activity/mine').then(re=>{
        const {data} =re;
        setactivitiesList([...data]);
        setisLoading(false);
      }).catch(er=>{
        setisLoading(false);
      })
    }
}

const  {isConnected}= useSocketIO([topic],(myTopic,payload)=> {
  
  if(topic===myTopic)
  {
    alert(`New data from ${myTopic}`);
    loadStatus();
  }
  
} )


  useEffect(() => {
    loadStatus();

   
    return () => {
     
    
    } 
  
  }, [])



  return (
    <>
    <Header />
    <HeaderTitle name="Activities" />

    <Container>
    
    <LostConnectionMessage isConnected={!isConnected}  />
    
      <DataTable
      pagination={true}
      paginationPerPage={10}
     
        columns={activitiesColums}
        data={activitiesList}
      // expandableRows
      // expandableRowsComponent={ExpandedComponent}
      /> 
       <div style={{textAlign:"center"}}><GridLoader margin={10} loading={isLoading} size={30} color="black"
     /></div>
    </Container>
  </>
  );
}

export default Activities;