import React from "react";
import { Modal, Result } from "antd";



export function LostConnectionMessage({isConnected}) {

  return (<Modal title="Connection Lost" footer={[]} visible={isConnected}>
    <Result
      status="info"
      title="Trying to connect to the server, Please wait ..." />
  </Modal>);
}
