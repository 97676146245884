
import React from 'react'

var userDetails;




export const UserContext = React.createContext(null);



