import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Floor from '../Components/Floor'
import Header from '../Components/Header'
import HeaderTitle from '../Components/HeaderTitle';
import { Card, Statistic } from 'antd';
import { ArrowUpOutlined, UserOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';
import httpService from '../services/httpService';
import { closeMQTTConnection, connectToMQTTBroker, connectWS, disconnectWS, successMessage } from '../services/utilities';
import { useParams } from 'react-router-dom';

const DemoPie = (props) => {

  const config = {
    appendPadding: 10,
    
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} data={props.data} />;
};

export default function DashBoard() {

  const params = useParams();

  const [stats, setstats] = useState({"totalUsers":0,"userOnsites":0,"userOffsites":0});

  const [buildingId, setbuildingId] = useState(params.id)

  const load = ()=>{
    httpService.get('/v1/people/stats').then(res=>{
      const {data} = res
      setstats({...data})
    }).catch(err=>{

    })
  }

  const laodiZonesStats = ()=>{
   
    httpService.get(`/v1/izone/stats`).then(a=>{
      const {data} = a;   

      const stats = data.map(e=> ({type:e.name ,value:e.people}));
    
      console.log(`izones stats`,stats)
     
      setizonesStats([...stats]);
    }).catch(r=>{

    })
  }


  var interval=0;

  useEffect(() => {    
   
  return () => {     
    }
  }, []);
  
  const [izonesStats, setizonesStats] = useState([])

  return (
    <>
     <Header />
    <HeaderTitle name="Building" />
    <>
   
    </>

    <Container fluid={true}>

    <Floor buildingId={buildingId} stats={stats} izonesStats={izonesStats}  currentStatus={izonesStats} />
    </Container>
    </>
  )
}
