import { Result } from 'antd'
import React, { useEffect,useState } from 'react'
import { Button } from 'react-bootstrap'
import { useCookies } from 'react-cookie';
import httpService from '../services/httpService';

export default function Onboarding() {
    const [userProfile, setuserProfile,removeCookie] = useCookies();
    async function loadUnitInfo() {
      try {
        let profile = localStorage.getItem("profileInfo");
        if(profile)
        {
          //alert(profile);
          profile = JSON.parse(profile);
          setprofileInfo({...profile});
        }
        const { data } = await httpService.get("/app/logo");
        setunitInformation({ ...data });
      } catch (error) {}
    }
    const [unitInformation, setunitInformation] = useState();
    const [profileInfo, setprofileInfo] = useState({});
    useEffect(() => {      
        loadUnitInfo();
      return () => {        
      }
    }, [])
    
    const logout =()=>{  
        setuserProfile("profile","",{path:'/'});
        setuserProfile("token","",{path:'/'});      
        localStorage.removeItem("profileInfo");
        localStorage.removeItem("token");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("jwt_refreshtoken");
        window.location=`${window.API_URL}/auth/logout`;        
      }
  return (
    <>
    <Result
    status="success"
    title={`Welcome ${profileInfo.name} ${profileInfo.surname} to VSIT ${unitInformation?.unitName}`}
    subTitle={`Your email :${profileInfo.username}, Please contact your administrator to activate your account.`}
    extra={[
      <Button onClick={e=> logout()} variant="dark">
        Sign-Out
      </Button>     
    ]}
  />
    </>
  )
}
