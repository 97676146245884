import React, { useMemo, useState } from "react";
import HeaderTitle from "../Components/HeaderTitle";
import Header from "../Components/Header";
import { useSocketIO, useUser } from "../hooks";
import * as _ from "lodash";
import { Badge, Collapse, Container, Popover } from "react-bootstrap";
import { Col, Row, Alert, Drawer, Descriptions, Spin, Input } from "antd";
import moment from "moment";
import {
  IZoneAdvanceSettingsV2,
  IZoneTresholdsAdvance,
  SensorInformationDisplay,
} from "./IZones";
import { Activity, Bell, Gear } from "react-bootstrap-icons";
import {
  generateSensorLabel,
  sendToCommand,
  sendToCommandLegacy,
} from "../Components/Floor";
import GridLayout from "react-grid-layout";
import httpService from "../services/httpService";
import { useEffect } from "react";
import { useStateManager } from "react-select";

const { Search } = Input;

export default function IzonesManager() {
  const { getUserProfile } = useUser();

  const [iZones, setiZones] = useState([]);

  const handleUpdateFromSocketIOt = (topic, payload) => {
    console.log(`Payload received ${topic}`, payload);

    var profile = JSON.parse(localStorage.getItem("profileInfo"));
    var Thetopic = `/v2/${profile?.CID}/buildings`;

    if (Thetopic === topic) {
      try {
        var payloadInformation = payload;

        // setbuldingsInfo([...payloadInformation.buildings]);

        let iZonesList = [];

        payloadInformation.buildings?.map((building) =>
          building.floors.map((floor) =>
            floor.izones?.map((izone) =>
              iZonesList.push({
                ...izone,
                buildingName: building?.name,
                floorName: floor?.name,
                activeAlarm: izone?.alarms?.filter(
                  (alarm) => alarm?.status === "alarm"
                ).length,
                alarms: izone?.alarms
                  ?.filter((alarm) => alarm?.status === "alarm")
                  .slice(0, 2),
              })
            )
          )
        );
        // setpeople([...payloadInformation.people]);
        iZonesList = _.orderBy(iZonesList, ["activeAlarm"], ["desc"]);
        setiZones([...iZonesList]);
        var alarmsList = [];

        payloadInformation.buildings?.map((mapBuilding) =>
          mapBuilding?.floors?.map((mapfloor) =>
            mapfloor?.izones?.map((mapizone) =>
              mapizone?.alarms?.map((allalarms) => {
                if (allalarms?.status === "alarm")
                  alarmsList.push({
                    ...allalarms,
                    building: mapBuilding,
                    floor: mapfloor,
                    izone: {
                      name: mapizone.name,
                      description: mapizone.description,
                    },
                  });
              })
            )
          )
        );

        alarmsList = _.orderBy(alarmsList, "date", "desc");

        setalarmsDisplayes([...alarmsList]);
      } catch (error) {}
    }
  };

  const { isConnected } = useSocketIO(
    [`/v2/${getUserProfile()?.CID}/buildings`],
    handleUpdateFromSocketIOt
  );

  const [alarmsDisplayes, setalarmsDisplayes] = useState([]);

  const [selectedIzone, setselectedIzone] = useState(null);

  const [showSiderDetails, setshowSiderDetails] = useState(null);

  const [selectedSensor, setselectedSensor] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const [seachIzone, setseachIzone] = useState("");

  const loadBuildings = () => {
    setisLoading(true);
    httpService.get("/v1/buildinglocation/floors").then((res) => {
      const { data } = res;

      try {
        var payloadInformation = data;

        // setbuldingsInfo([...payloadInformation.buildings]);

        let iZonesList = [];

        payloadInformation.buildings?.map((building) =>
          building.floors.map((floor) =>
            floor.izones?.map((izone) =>
              iZonesList.push({
                ...izone,
                buildingName: building?.name,
                floorName: floor?.name,
                activeAlarm: izone?.alarms?.filter(
                  (alarm) => alarm?.status === "alarm"
                ).length,
                isOffline: izone?.alarms?.find(alarm=> alarm?.name==="TIMEOUT") ? true:false,
                alarms: izone?.alarms
                  ?.filter((alarm) => alarm?.status === "alarm")
                  .slice(0, 2),
              })
            )
          )
        );
        // setpeople([...payloadInformation.people]);
        iZonesList = _.orderBy(iZonesList, ["activeAlarm"], ["desc"]);
        setiZones([...iZonesList]);
        var alarmsList = [];

        payloadInformation.buildings?.map((mapBuilding) =>
          mapBuilding?.floors?.map((mapfloor) =>
            mapfloor?.izones?.map((mapizone) =>
              mapizone?.alarms?.map((allalarms) => {
                if (allalarms?.status === "alarm")
                  alarmsList.push({
                    ...allalarms,
                    building: mapBuilding,
                    floor: mapfloor,
                    izone: {
                      name: mapizone.name,
                      description: mapizone.description,
                    },
                  });
              })
            )
          )
        );

        alarmsList = _.orderBy(alarmsList, "date", "desc");

        setalarmsDisplayes([...alarmsList]);

        setisLoading(false);
      } catch (error) {
        setisLoading(false);
      }
    });
  };


  const isOffline = (izone)=> {
    return izone?.alarms?.find(alarm=> alarm.name==="TIMEOUT")
  }


  const findMatchingIzoneLive = (iZones,showSiderDetails)=>{

    return iZones
      ?.find(
        (izoneGeneral) =>
          izoneGeneral._id === showSiderDetails?._id
      );
  }

  useEffect(() => {
    loadBuildings();
    return () => {};
  }, []);

  const memoizedIZoneRow = useMemo(
    () => (
      <IZoneRow
        iZones={iZones}
        seachIzone={seachIzone}
        setshowSiderDetails={setshowSiderDetails}
      />
    ),
    [iZones, seachIzone]
  );

  return (
    <>
      <Header />
      <HeaderTitle name="Izones Manager" />
      <div style={{ margin: "50px" }}></div>
      <Spin size="large" tip="Loading ..." spinning={isLoading || !isConnected}>
        <Container fluid>
          <Search
            onKeyPress={(e) => {
              if (e.key === "Enter") setseachIzone(e.currentTarget.value.toLocaleLowerCase());
            }}
            placeholder="Search"
            allowClear
          />

          <div>
            <Drawer
              title={showSiderDetails?.name}
              headerStyle={{backgroundColor: isOffline(findMatchingIzoneLive(iZones,showSiderDetails) ) ?  "orange":"white"}}
              placement="right"
              onClose={(e) => setshowSiderDetails(false)}
              visible={showSiderDetails}
              width={750}
             
            >
              <>
                <p>
                  <IZoneAdvanceSettingsV2
                    reload={loadBuildings}
                    id={showSiderDetails?._id}
                  />
                  <IZoneTresholdsAdvance sensors={showSiderDetails?.sensors} />
                 
                </p>

                <p  >
                  <>
                    <h6 style={{ fontWeight: "bold" }}>
                    <Bell /> Active Alarms
                    </h6>

                    {iZones
                      ?.find(
                        (izoneGeneral) =>
                          izoneGeneral._id === showSiderDetails?._id
                      )
                      ?.alarms?.map((alarm, index) => (
                        <>
                          <Alert
                            key={alarm?.id}
                            style={{ margin: "5px" }}
                            message={
                              <>
                                {alarm?.label}{" "}
                                {moment(alarm?.date).format("YYYY-MM-DD HH:mm")}
                              </>
                            }
                            type="error"
                            showIcon
                          />
                        </>
                      ))}
                  </>

                  {showSiderDetails?.sensors && (
                    <>
                      <h6 style={{ fontWeight: "bold" }}>
                        <Activity /> Sensors
                      </h6>

                      <GridLayout
                        className="layout"
                        cols={12}
                        rowHeight={20}
                        width={1200}
                        onDragStop={(e) => {
                          console.log("Draggin:", e);
                        }}
                        isResizable={false}
                        isDraggable={false}
                      >
                        { findMatchingIzoneLive(iZones,showSiderDetails) 
                          ?.sensors?.map((mapsensors, index) => (
                            <div
                              key={`${index}`}
                              data-grid={mapsensors?.data}
                              style={{ width: "100%", height: "100%" }}
                            >
                             
                              <SensorInformationDisplay isOffline={isOffline(findMatchingIzoneLive(iZones,showSiderDetails) )} {...mapsensors}>
                                {generateSensorLabel(mapsensors)}
                              </SensorInformationDisplay>
                            </div>
                          ))}
                      </GridLayout>
                    </>
                  )}
                </p>
                <p>
                  {showSiderDetails?.commands && (
                    <>
                      <h6 style={{ fontWeight: "bold" }}>
                        <Gear /> Commands
                      </h6>
                      <div style={{ margin: 0 }}>
                        <Descriptions layout="horizontal" bordered>
                          {showSiderDetails?.commands?.map((cm) => (
                            <div style={{ textAlign: "center" }}>
                              <div>{cm.name}</div>

                              <>
                                {cm?.commands?.map((cmd) => (
                                  <Badge
                                  disabled={isOffline(findMatchingIzoneLive(iZones,showSiderDetails) )}
                                    as={"button"}
                                    onClick={(e) => {
                                      if (cm?.type != "Legacy")
                                        sendToCommand(
                                          cm?.deviceId,
                                          cmd?.command,
                                          null,
                                          cmd?.value,
                                          {
                                            name: showSiderDetails.name,
                                            commmand: `${cmd?.command}/${cmd?.label}`,
                                            more: cmd,
                                          }
                                        );
                                      else {
                                        sendToCommandLegacy(
                                          cm?.deviceId,
                                          cmd?.command,
                                          null,
                                          cmd?.value,
                                          {
                                            name: showSiderDetails.name,
                                            commmand: `${cmd?.command}/${cmd?.label}`,
                                            more: cmd,
                                          }
                                        );
                                      }
                                    }}
                                    style={{ marginLeft: 5 }}
                                    size="sm"
                                    bg="warning"
                                  >
                                    {cmd.label}
                                  </Badge>
                                ))}
                              </>
                            </div>
                          ))}
                        </Descriptions>
                      </div>
                    </>
                  )}
                </p>
              </>
            </Drawer>
          </div>

          {memoizedIZoneRow}

          {/* {iZones?.map(izone=><div>
<p>

<h4>{izone?.name}</h4>
</p>
{izone}
</div>)} */}
        </Container>
      </Spin>
    </>
  );
}

const IZoneRow = ({ iZones, seachIzone, setshowSiderDetails }) => {




  return (
    <Row>
      {iZones
        ?.filter((izoneFilter) => izoneFilter.name.toLocaleLowerCase().includes(seachIzone))
        .map((izone) => (
          <Col className="gutter-row" key={izone?._id}>
            <div
              onClick={() => setshowSiderDetails(izone)}
              style={{
                background: izone?.isOffline ? "orange"  : (izone?.activeAlarm > 0 ? "red" : "green"),
                width: "300px",
                height: "150px",
                margin: "20px",
              }}
            >
              <p style={{ margin: "10px", textAlign: "center" }}>
                <div style={{ fontWeight: "bold" }}>
                  {izone?.name}{" "}
                  <IZoneAdvanceSettingsV2
                    dontShowTitle={true}
                    id={izone?._id}
                  />
                </div>
              </p>
              <p>
                {izone?.alarms?.map((alarm) => (
                  <Alert
                    key={alarm?.id}
                    style={{ margin: "0px" }}
                    message={
                      <>
                        {alarm?.label}{" "}
                        {moment(alarm?.date).format("YYYY-MM-DD HH:mm")}
                      </>
                    }
                    type="error"
                    showIcon
                  />
                ))}
              </p>
            </div>
          </Col>
        ))}
    </Row>
  );
};
