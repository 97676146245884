import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row,Button } from "react-bootstrap";
import { Clipboard, CollectionPlay, Info, InfoCircle, InfoCircleFill, Journals, Key } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import CustomModal, { CustomModalV2 } from "../Components/CustomModal";
import { GenericFormElement, GenericSelectForm } from "../Components/GenericFormController";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import { DeleteButton, errorMessage, NewItem, successMessage } from "../services/utilities";
import { DotLoader, GridLoader } from 'react-spinners';
import { Modal, Steps, Form as FormAnt,Input, Select, Switch } from "antd";
import { CopyOutlined, FormatPainterOutlined, ForwardOutlined, StepBackwardFilled } from "@ant-design/icons";

var QRCode = require('qrcode.react');


const mqttConnection = 'wss://wss.dev.app.vsit.nsiotcloud.com:443';
     
const availabelComamns = [
   
    {value:"RELAY_01",label:"RELAY_01"},
    {value:"RELAY_02",label:"RELAY_02"},
    {value:"RELAY_03",label:"RELAY_03"},
    {value:"RELAY_04",label:"RELAY_04"},
    {value:"RELAY_05",label:"RELAY_05"}
]

const inputDetails =[
    {name:"name", title:"Name"},
    {name:"description", title:"Description"},
    {name:"ip", title:"Ip Address"},
    {name:"controllerId", title:"Controller ID"},
    {name:"code", title:"Code"}
];

const directionType = [
    {label:"Entrance", value:"Entrance"},   
    {label:"Exit", value:"Exit"},   
]

const LocationCreator = ({controllers,locationDetails, color, icon,accessSchedules,reload,title,newLocation})=>{

 
 

    const [showModal, setshowModal] = useState(false);

    const [locationInformation, setlocationInformation] = useState({...locationDetails})

    const updateCreate = () => {
        if (locationInformation?._id)
            httpService
                .put(`/v1/LocationsAccess/${locationDetails._id}`, {
                    name: locationInformation?.name,
                    description: locationInformation?.description,
                    ip: "",
                    controllerId: locationInformation?.controllerId,
                    code: locationInformation?.code,
                    direction: locationInformation?.direction,
                    isExistDoor: locationInformation?.isExistDoor,
                    statusSensorName: "",
                    controllerCommands: locationInformation?.controllerCommands,
                    accessSchedules: locationInformation?.accessSchedules,                    
                    locationKey:locationInformation?.locationKey
                })
                .then((response) => {
                    setshowModal(false);
                    successMessage(`Location updated successfully!`);
                    if (reload) reload();
                })
                .catch((error) => {
                    errorMessage(
                        `Failed to update Location information (${error?.response?.data || error
                        })`
                    );
                });

        if (!locationInformation?._id)
            httpService
                .post(`/v1/LocationsAccess`, {
                    name: locationInformation?.name,
                    description: locationInformation?.description,
                    ip: "",
                    controllerId: locationInformation?.controllerId,
                    code: locationInformation?.code,
                    direction: locationInformation?.direction,
                    isExistDoor: locationInformation?.isExistDoor,
                    statusSensorName: "",
                    controllerCommands: locationInformation?.controllerCommands,
                    accessSchedules: locationInformation?.accessSchedules,
                    locationKey:locationInformation?.locationKey
                })
                .then((response) => {
                    setshowModal(false);
                    successMessage(`Location created successfully!`);
                    if (reload) reload();
                })
                .catch((error) => {
                    errorMessage(
                        `Failed to update Location information (${error?.response?.data || error
                        })`
                    );
                });
    };

    const updateInput = (e,name)=> setlocationInformation(old=>({...old,[name]:e}));

    const loadInformation = ()=>
    {
        setisloading(true);

        if(!locationDetails?._id)
        {
            setisloading(false);
            return;
        }      

        httpService.get(`/v1/LocationsAccess/${locationDetails._id}`).then(response=>{
            const {data} =response;
            setlocationInformation({...data})
            setisloading(false);
        }).catch(err=>{

        })
    }

    const [isloading, setisloading] = useState(false);
    
    return (
      <>
        <Button onClick={(e) => {setshowModal(true);loadInformation()}} style={{margin:2}} variant={color || "dark"} size="sm">
            {icon ||  <ForwardOutlined />}
         
        </Button>
        <Modal
          forceRender={true}
          centered={false}
          okType="success"
          okText="OK"
          width={500}
          title={title || "Create Location Access"}
          visible={showModal}
          onOk={() => {
              updateCreate();             
            }}
          onCancel={() => {
            setshowModal(false);
            if(newLocation)
            setlocationInformation({})
          }}
        >
         <div style={{textAlign:"center"}}><GridLoader margin={10} loading={isloading} size={30} color="black"
     /></div>
     {!isloading && 
      <div style={{margin:10}}>
              
      <FormAnt
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        layout="horizontal"
        
      >
        <FormAnt.Item label="Name">
          <Input value={locationInformation?.name} onChange={e=> updateInput(e.target.value,"name")} />
        </FormAnt.Item>
        <FormAnt.Item label="Description">
          <Input value={locationInformation?.description} onChange={e=> updateInput(e.target.value,"description")} />
        </FormAnt.Item>
        <FormAnt.Item label="iPoP Controller">
              <Select
              value={locationInformation?.controllerId} onChange={e=> updateInput(e,"controllerId")}
              >
                  {controllers?.map(controller=>(<><Select.Option value={controller?.value}>{controller?.label}</Select.Option></>))}                    
               
              </Select>
            </FormAnt.Item>

            <FormAnt.Item label="iPoP Commands">
              <Select
               value={locationInformation?.controllerCommands} onChange={e=> updateInput(e,"controllerCommands")}
              mode="tags"
              >
                  {[1,2,3,4,5,6,7,8,9,10]?.map(number=>(<><Select.Option value={`RELAY_${number}`}>RELAY {number}</Select.Option></>))}                    
               
              </Select>
            </FormAnt.Item>    
            <FormAnt.Item label="Direction">
              <Select  
              value={locationInformation?.direction} onChange={e=> updateInput(e,"direction")}                
              >
                 <Select.Option value={`Entrance`}>Entrance</Select.Option>                  
                 <Select.Option value={`Exit`}>Exit</Select.Option>
              </Select>
            </FormAnt.Item> 
            <FormAnt.Item label="Code">
                 <Input value={locationInformation?.code} onChange={e=> updateInput(e.target.value,"code")} />
            </FormAnt.Item>
            <FormAnt.Item label="Location Key">
                 <Input value={locationInformation?.locationKey} onChange={e=> updateInput(e.target.value,"locationKey")} />
            </FormAnt.Item>
            <FormAnt.Item label="Door Exit ?">
            <Switch
                checked={locationInformation?.isExistDoor}
                onChange={e=> updateInput(e,"isExistDoor")} 
                checkedChildren="Building Exit Door"
                unCheckedChildren="Normal Door"
               
                />
                </FormAnt.Item>

                <FormAnt.Item label="Access Schedules">
              <Select
               value={locationInformation?.accessSchedules} onChange={e=> updateInput(e,"accessSchedules")}
              mode="tags"
              >
                  {accessSchedules?.map(controller=>(<><Select.Option value={controller?.value}>{controller?.label}</Select.Option></>))}                    
               
              </Select>
            </FormAnt.Item> 
       
                {(locationInformation?._id && locationInformation?.locationKey) && 
                <FormAnt.Item label="QRCODE">
                < QRCode value={`ns010313js74g,${locationInformation?.locationKey}`}/>
                </FormAnt.Item> }
                
               
      </FormAnt>
      </div>
  
     }
         
        </Modal>
      </>
    );
}

const createNewLocationv1 = (data)=>{   

    var locationdata = data;
    
    httpService.post('/v1/LocationsAccess',locationdata).then(a=>{
        successMessage(`Location Created Successfully !`);
       
    }).catch(err=>{
        const {response} = err;
        if(response?.data)
        errorMessage(`Failed to create location (${response?.data})`);
        else
        errorMessage(`Failed to create location (please concat your admin`);
    })
}
 const UpdateData = (props)=>{

    const loadUrl = `/v1/LocationsAccess/${props._id}`;
    const updateUrl = `/v1/LocationsAccess/${props._id}`;
    

    const [inputDetails, setinputDetails] = useState([
        {name:"name", title:"Name"},
        {name:"description", title:"Description"},
        {name:"ip", title:"Ip Address"},
        {name:"code", title:"Code"},
        {name:"controllerId", title:"Controller ID"},
        {name:"statusSensorName", title:"Sensor"},
    ])
    const [dataDetails, setdataDetails] = useState({
        _id:"",
        name:"",
        description:"",
        ip:"",
        controllerId:"",
        code:"",
        direction:""
    })
    const loadLocation = ()=>{
        httpService.get(loadUrl).then(a=>{
            const {data} = a;
            var activeCommands = data.controllerCommands.map(a=>{
                return {
                    value:a,
                    label:a
                }
            })

            var infoData = {...data}
            if(props.load)
            {
                infoData={...infoData,name:"",description:""}
            }

            var selectedS = data.accessSchedules.map(a=>({label:a.name,value:a._id}));

            setdataDetails({...infoData});
            setCommands([...activeCommands]);
            setselectedSchedules([...selectedS]);
        }).catch(err=>{
            errorMessage(errorMessage);
        })       
    }

    const loadAccessGroup = ()=>{
        httpService.get('/v1/accessgroup/lite').then(a=>{
            const {data} = a;
            var accessgroup = data.map(a=>{
                return {
                    value:a._id,
                    label:a.name
                }
            })

            setaccessGroup([...accessgroup]);

        }).catch(err=>{
            errorMessage(errorMessage);
        })       
    }

    const upateValue = ()=>{

        if(props.load)
        {

            createNewLocationv1({
                name:dataDetails.name,
                description:dataDetails.description,
                ip:dataDetails.ip,
                controllerId:dataDetails.controllerId,
                code:dataDetails.code,
                direction:dataDetails.direction,
                statusSensorName:dataDetails.statusSensorName,
                controllerCommands:commands.map(e=>{
                    return e.value
                })
               
            })
            return;
        }
        httpService.put(updateUrl,{
            name:dataDetails.name,
            description:dataDetails.description,
            ip:dataDetails.ip,
            controllerId:dataDetails.controllerId,
            code:dataDetails.code,
            direction:dataDetails.direction,
            isExistDoor:dataDetails.isExistDoor,
            statusSensorName:dataDetails.statusSensorName,
            controllerCommands:commands.map(e=>{
                return e.value
            }
            
            ),            
                accessSchedules:selectedSchedules.map(e=>{
                    return e.value
                })
        }).then(a=>{
            successMessage('Details Updated Successfully')
        }).catch(err=>{
            const {response} = err;
            //
            if(response?.data)
            errorMessage(`Failed to update location (${response?.data})`);
            else
            errorMessage(`Failed to update location !`);
        })  
    }

    const updateValue =(e)=>{    
        setdataDetails(oldValue=>({...oldValue,[e.target.name]:e.target.value}))
      }

    const loadSchedules = async () =>{

        const {data} = await httpService.get('/v1/accessschedule');
        return data;
    }
 
      const [commands, setCommands] = useState([]); 

      const [selectedSchedules, setselectedSchedules] = useState([]);

      const [schedules, setschedules] = useState([])  ;

      const [accessGroup, setaccessGroup] = useState([]);
      
      useEffect( () => {

        async function loadSchedulesAll ()
        {
            var schedules = await loadSchedules();

            setschedules([...schedules?.map(a=>({value:a._id,label:a.name}))]);
        }
        loadSchedulesAll();
       
      }, [])
      

    return(
    <>
        <CustomModal variant={props.variant} load={()=> {loadAccessGroup();loadLocation()}} canProccess={true} proccess={upateValue} size={"lg"} title={props.title || "Update Info"} proccessTitle={"Update"} icon={props.icon} >
     
            <Row>
                <Col lg={6}>
               
               
                    {inputDetails.map(a => (<GenericFormElement {...a} value={dataDetails[a.name]} handleChange={updateValue} />))}
                 <GenericSelectForm value={{value:dataDetails?.direction,label:dataDetails?.direction}} title="Direction" handleChange={(e)=> setdataDetails(oldValue=>({...oldValue,["direction"]:e.value}))}  options={directionType}/>
             
                 <Form.Check name="isExistDoor" onChange={e=>   setdataDetails(oldValue=>({...oldValue,[e.target.name]:e.target.checked}))} variant="dark" checked={dataDetails["isExistDoor"]}
                    type={"checkbox"}
                    id={`default-${"checkbox"}`}
                    label={`Exit Door`}
                />

                </Col>
                <Col lg={6}>
                <Form.Label>Command to execute</Form.Label>
                    <MultiSelect
                    options={availabelComamns}
                    value={commands}
                    onChange={setCommands}
                    labelledBy="Select"
                    />               

                   <Form.Label>Schedules</Form.Label>
                    <MultiSelect
                    options={schedules}
                    value={selectedSchedules}
                    onChange={setselectedSchedules}
                    labelledBy="Select"
                    />  

                    {/* <Form.Label>Schedules</Form.Label>
                    <MultiSelect
                    options={accessGroup}
                    value={selectedSchedules}
                    onChange={setselectedSchedules}
                    labelledBy="Select"
                    />      
             */}
            

                <div style={{textAlign:"center", margin:10}}>         
                       <h4>SITE CODE </h4>         
                       <QRCode size={150} value={`democlient0001,${dataDetails?._id}`} />
                </div> 

               
                </Col>
            </Row>
        </CustomModal>
    </>
    )

}
export const UpdateLocationDetails = (props)=>{

    const loadUrl = `/v1/LocationsAccess/${props._id}`;
    const updateUrl = `/v1/LocationsAccess/${props._id}`;
    

    const [inputDetails, setinputDetails] = useState([
        {name:"name", title:"Name"},
        {name:"description", title:"Description"},
        {name:"ip", title:"Ip Address"},
        {name:"code", title:"Code"},
        {name:"controllerId", title:"Controller ID"},
        {name:"statusSensorName", title:"Sensor"},
    ])
    const [dataDetails, setdataDetails] = useState({
        _id:"",
        name:"",
        description:"",
        ip:"",
        controllerId:"",
        code:"",
        direction:""
    })
    const loadLocation = ()=>{
        httpService.get(loadUrl).then(a=>{
            const {data} = a;
            var activeCommands = data.controllerCommands.map(a=>{
                return {
                    value:a,
                    label:a
                }
            })

            var infoData = {...data}
            if(props.load)
            {
                infoData={...infoData,name:"",description:""}
            }

            var selectedS = data.accessSchedules.map(a=>({label:a.name,value:a._id}));

            setdataDetails({...infoData});
            setCommands([...activeCommands]);
            setselectedSchedules([...selectedS]);
        }).catch(err=>{
            errorMessage(errorMessage);
        })       
    }

    const upateValue = ()=>{

        if(props.load)
        {

            createNewLocationv1({
                name:dataDetails.name,
                description:dataDetails.description,
                ip:dataDetails.ip,
                controllerId:dataDetails.controllerId,
                code:dataDetails.code,
                direction:dataDetails.direction,
                statusSensorName:dataDetails.statusSensorName,
                controllerCommands:commands.map(e=>{
                    return e.value
                })
               
            })
            return;
        }
        httpService.put(updateUrl,{
            name:dataDetails.name,
            description:dataDetails.description,
            ip:dataDetails.ip,
            controllerId:dataDetails.controllerId,
            code:dataDetails.code,
            direction:dataDetails.direction,
            isExistDoor:dataDetails.isExistDoor,
            statusSensorName:dataDetails.statusSensorName,
            controllerCommands:commands.map(e=>{
                return e.value
            }
            
            ),            
                accessSchedules:selectedSchedules.map(e=>{
                    return e.value
                })
        }).then(a=>{
            successMessage('Details Updated Successfully')
        }).catch(err=>{
            const {response} = err;
            //
            if(response?.data)
            errorMessage(`Failed to update location (${response?.data})`);
            else
            errorMessage(`Failed to update location !`);
        })  
    }

    const updateValue =(e)=>{    
        setdataDetails(oldValue=>({...oldValue,[e.target.name]:e.target.value}))
      }

    const loadSchedules = async () =>{

        const {data} = await httpService.get('/v1/accessschedule');
        return data;
    }
 
      const [commands, setCommands] = useState([]); 

      const [selectedSchedules, setselectedSchedules] = useState([]);

      const [schedules, setschedules] = useState([])  ;
      
      useEffect( () => {

        async function loadSchedulesAll ()
        {
            var schedules = await loadSchedules();

            setschedules([...schedules?.map(a=>({value:a._id,label:a.name}))]);
        }
        loadSchedulesAll();
       
      }, [])
      

    return(
    <>
        <CustomModalV2 mainComponents={props.mainComponents} variant={props.variant} load={loadLocation} canProccess={true} proccess={upateValue} size={"lg"} title={props.title || "Update Info"} proccessTitle={"Update"} icon={props.icon} >
     
            <Row>
                <Col lg={6}>
               
               
                    {inputDetails.map(a => (<GenericFormElement {...a} value={dataDetails[a.name]} handleChange={updateValue} />))}
                 <GenericSelectForm value={{value:dataDetails?.direction,label:dataDetails?.direction}} title="Direction" handleChange={(e)=> setdataDetails(oldValue=>({...oldValue,["direction"]:e.value}))}  options={directionType}/>
             
                 <Form.Check name="isExistDoor" onChange={e=>   setdataDetails(oldValue=>({...oldValue,[e.target.name]:e.target.checked}))} variant="dark" checked={dataDetails["isExistDoor"]}
                    type={"checkbox"}
                    id={`default-${"checkbox"}`}
                    label={`Exit Door`}
                />

                </Col>
                <Col lg={6}>
                <Form.Label>Command to execute</Form.Label>
                    <MultiSelect
                    options={availabelComamns}
                    value={commands}
                    onChange={setCommands}
                    labelledBy="Select"
                    />               

                   <Form.Label>Schedules</Form.Label>
                    <MultiSelect
                    options={schedules}
                    value={selectedSchedules}
                    onChange={setselectedSchedules}
                    labelledBy="Select"
                    />  
            

                <div style={{textAlign:"center", margin:10}}>         
                       <h4>SITE CODE </h4>         
                       <QRCode size={150} value={`democlient0001,${dataDetails?._id}`} />
                </div> 

               
                </Col>
            </Row>
        </CustomModalV2>
    </>
    )

}
export default function LocationAccess() {  
 
    const colums = [

        {
            sortable: true,
            name: "QRCODE",
            selector: (row) => row?._id,
            cell: (row) =>  <QRCode style={{ margin: 5 }} size={50} value={`democlient0001,${row._id}`} />,
        },
        {
            sortable: true,
            name: "Creation Date",
            selector: (row) => row?.creationDate,
            cell: (row) => moment(row?.creationDate).format("MMMM Do YYYY, h:mm:ss a"),
        },
        { sortable: true, name: "Name", selector: (row) => row.name , cell :(row)=><>{row?.name}</> },
        { sortable: true, name: "Description", selector: (row) => row.description, cell :(row)=><>{row?.description}</> },
       
        {
            name: "", cell: (row) => <>
            
            {/* <UpdateData variant="warning" icon={<Info />} {...row} />
            <UpdateData load={true} title={`Duplicate : ${row?.name}`}  variant="warning" icon={<Clipboard />} {...row} /> */}
            <DeleteButton  {...row} handler={deleteLocation} />
            <LocationCreator reload={loadAll} title={`Update ${row.name}`} controllers={availableiPoPs} locationDetails={{...row}} accessSchedules={accessSchedules} icon={<InfoCircle/>} color="warning" />
            <LocationCreator reload={loadAll} title={`Duplicate ${row.name}`} controllers={availableiPoPs} locationDetails={{...row,name:"",description:"",_id:""}} accessSchedules={accessSchedules} icon={<CopyOutlined/>} color="warning" />
            </>
        },
    ];

    const loadAll = ()=>{
        setisLoading(true);
        httpService.get("/v1/LocationsAccess").then(a=>{
            const {data} = a;
            setlocations([...data]);
            setisLoading(false);
        }).catch(err=>{
            setisLoading(false);
        })

        httpService.get('/v1/building/availabledevices').then(response=>{
                const {data} = response;
                setavailableiPoPs([...data]);
        }).catch(error=>{

        })

        httpService.get('/v1/accessschedule').then(response=>{
            const {data} = response;
            setaccessSchedules([...data?.map(dmap=>({value:dmap._id,label:dmap.name}))]);
    }).catch(error=>{

    })


       
    }
    const deleteLocation = (id)=>{
        httpService.delete(`/v1/LocationsAccess/${id}`).then(a=>{            
            successMessage(`Location Successfully Deleted`);
            loadAll();
        }).catch(err=>{
            errorMessage('Failed to Delete Location');
            loadAll();
        })
    }
    const createNewLocation = ()=>{   

        var locationdata = locationInfo;
        locationdata = {...locationdata,controllerCommands:    commands.map(e=>{
            return e.value
        })}
        httpService.post('/v1/LocationsAccess',locationdata).then(a=>{
            successMessage(`Location Created Successfully !`);
            loadAll();
        }).catch(err=>{
            const {response} = err;
            if(response?.data)
            errorMessage(`Failed to create location (${response?.data})`);
            else
            errorMessage(`Failed to create location (please concat your admin`);
        })
    }
    const updateValue =(e)=>{    
        setlocationInfo(oldValue=>({...oldValue,[e.target.name]:e.target.value}))
      }
    const [locations, setlocations] = useState([]);

    const [locationInfo, setlocationInfo] = useState({
        name:"",
        description:"",
        ip:"",
        direction:""
    })
  const [locationFields, setlocationFields] = useState([ 
    {name:"name", title:"Name"},
    {name:"description", title:"Description"},
    {name:"ip", title:"Ip Address"},,
    {name:"controllerId", title:"Controller ID"},
    {name:"code", title:"Code"},
    {name:"statusSensorName", title:"Sensor"},
        ])

        const [accessSchedules, setaccessSchedules] = useState([])

    const [availableiPoPs, setavailableiPoPs] = useState([]);

 const [commands, setcommands] = useState([]);
    useEffect(() => {
       
        loadAll();
    }, [])


  const [isLoading, setisLoading] = useState(true)

  return (
    <>
      <Header />
      <HeaderTitle name="Access Locations" />
      <Container>
        <></>
        <LocationCreator newLocation={true} reload={loadAll} controllers={availableiPoPs} accessSchedules={accessSchedules} />
 
        <div style={{textAlign:"center"}}><GridLoader margin={10} loading={isLoading} size={30} color="black"
     /></div>
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={colums}
          data={locations}
        />
        
      </Container>
    </>
  );
}
