import React, { useState } from 'react'
import { Signal } from 'react-bootstrap-icons'
import ReactSignatureCanvas from 'react-signature-canvas'
import CustomModal from './CustomModal'

export default function SignatureComponents(props) {

   
    var refSign={}
    return (
        <>
            <CustomModal
            closeAfterProccess={true}
            centered={true}
        proccess={() => {
            var sign = refSign.getTrimmedCanvas();      
            var image="";      
            image =  sign.toDataURL();           

            if(props.getSignature)
            props.getSignature(image);
        }}
        size="md"
        title="Signature"
        proccessTitle={"Save"}
        icon={<Signal />}
        load={()=>{}}
        close={()=>{}}
        canProccess={()=>{}}
      >
          <div style={{border:"2px solid black",width: '100%', height: 200}}>
          <ReactSignatureCanvas penColor='grey'
          ref={(re) => { refSign = re }} 
           canvasProps={{width: 400, height: 200}} />
         
          </div>

      </CustomModal>
        </>
    )
}
