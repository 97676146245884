import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import HeaderTitle from '../Components/HeaderTitle';
import DataTable from "react-data-table-component";
import moment from 'moment';
import { Badge, Button, Container,Form,Image } from 'react-bootstrap';
import httpService from '../services/httpService';
import {closeMQTTConnection, connectToMQTTBroker, connectWS, disconnectWS, errorMessage, getProfile, successMessage} from '../services/utilities';
import { UpdatePersonV2, UpdatePersonV3 } from './People';
import { DotLoader, GridLoader } from 'react-spinners';
import { IZoneCU } from './IZones';
import { File, Pencil, Plus } from 'react-bootstrap-icons';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { GenericSelectForm } from '../Components/GenericFormController';
import FloorForm from '../Components/Floors/FloorForm';



function Floors(props) {

  const floorsColums =[
    {sortable:true,name:"Created",selector:"creationDate",grow:1, cell:(row)=> moment(row.creationDate).format('MMMM Do YYYY, h:mm:ss a')},
    {sortable:true,name:"Name",selector:"name"},
    {sortable:true,name:"Description",selector:"description",cell:row=><p>{row.description} 
    </p>},
    {sortable:true,name:"Building",selector:row=> row?.buildingLocation?.name, cell: row=><p>{row?.buildingLocation?.name}</p>},
    {sortable:false,name:"Image",cell:(row)=><><Image id={row._id}  src={`${window.API_URL}/v1/building/${row._id}/floor`} fluid={true}/></>},
    {sortable:false,name:"Image",cell:(row)=><><Button style={{maring:5}} size='sm' variant="warning" onClick={()=> {setshowEditAdd(true); setselectedFloor(row)}}> <Pencil /></Button><UploadFile _id={row._id} /></>}
   
  ]

  const [isLoading, setisLoading] = useState(false);

  const [floors, setfloors] = useState();

  const loadFloor = ()=>{
    setisLoading(true);
    httpService.get(`/v1/building`).then(res=>{
      const {data} = res;
      const floorInhanced = data.map(r=> ({...r,readOnly:true}))
      setfloors([...floorInhanced]);
      setisLoading(false);
    }).catch(err=>{ setisLoading(false);})
  }


  useEffect(() => {
    loadFloor(); 
    return () => {

  
    }
  }, [])
  
  const NewFloor = (props)=>{

    const fields = [
      { name: "name", title: "Name" },
      { name: "description", title: "Description" },
  ]

  const createInformation = ()=>{
   httpService.post(`/v1/building`,{
    ...floorInfo,   
    ["buildingLocation"]:floorInfo?.buildingLocation?.value
   }).then(res=>{
    successMessage(`New Floor created !`);
    loadFloor();
   }).catch(err=>{
    errorMessage(`Failed to create new floor (${err})`)
   })
  }

  const loadBuildings = ()=>{
    httpService.get(`/v1/buildinglocation/lite`).then(response=>{
      const {data} = response;
      var formatBuildingLocations = data?.map(mapBuildings=>({value:mapBuildings._id,label:mapBuildings.name}));
     
     
      setbuildinglocations([...formatBuildingLocations])
    }).catch(err=>{})
  }

  const [floorInfo, setfloorInfo] = useState({...props.data});

  const [buildinglocations, setbuildinglocations] = useState([])

  const updateInfo =(name,value) => {
    setfloorInfo(old => ({ ...old, [name]: value}))
  }

    return <><IZoneCU 
    load={()=>{loadBuildings()}}
    moreComponents={<>
   
     <GenericSelectForm
                value={floorInfo?.buildingLocation}
                title="Select Building"
                handleChange={(e) => {setfloorInfo(old => ({ ...old, ["buildingLocation"]: {value:e.value,label:e.label}}))}}
                options={buildinglocations}
              />
    </>}
    proccess={()=> createInformation()} fields={fields} data={floorInfo} handleChange={updateInfo} type="Create" titleComponent={<Button style={{maring:5}} size='sm' variant="dark"> <Plus /></Button>} title="Add Floor" /></>
  }



  const EditFloor = (props)=>{

    const fields = [
      { name: "name", title: "Name" },
      { name: "description", title: "Description" },
  ]

  const updateInformation = ()=>{
   httpService.put(`/v1/building/${props._id}`,{
    ...floorInfo,   
    ["buildingLocation"]:floorInfo?.buildingLocation?.value
   }).then(res=>{
    successMessage(`Floor details successfully udpated.`);
    loadFloor();
   }).catch(err=>{
    errorMessage(`Failed to udpated Floor details.`)
   })
  }

  const loadBuildings = ()=>{
    httpService.get(`/v1/buildinglocation/lite`).then(response=>{
      const {data} = response;
      var formatBuildingLocations = data?.map(mapBuildings=>({value:mapBuildings._id,label:mapBuildings.name}));
     
     
      setbuildinglocations([...formatBuildingLocations])
    }).catch(err=>{})
  }

  const [floorInfo, setfloorInfo] = useState({...props.data});

  const [buildinglocations, setbuildinglocations] = useState([])

  const updateInfo =(name,value) => {
    setfloorInfo(old => ({ ...old, [name]: value}))
  }

    return <><IZoneCU 
    load={()=>{loadBuildings()}}
    moreComponents={<>
    
     <GenericSelectForm
                value={floorInfo?.buildingLocation}
                title="Select Building"
                handleChange={(e) => {setfloorInfo(old => ({ ...old, ["buildingLocation"]: {value:e.value,label:e.label}}))}}
                options={buildinglocations}
              />
    </>}
    proccess={()=> updateInformation()} fields={fields} data={floorInfo} handleChange={updateInfo} type="Update" titleComponent={<Button style={{maring:5}} size='sm' variant="warning"> <Pencil /></Button>} title="Edit Floor Information" /></>
  }

  const UploadFile =(props)=>{   

    const [selectedFile, setselectedFile] = useState();

    const changeHandler = (event) => {

      console.log(event.target.files[0]);
      setselectedFile(event.target.files[0]); 
    
  
    };

    const uploadFloortoServer = ()=>{

      const formData = new FormData();
      formData.append("floor", selectedFile);     

      httpService.put(`/v1/building/${props._id}/floor`,formData).then(response=>{
        successMessage(`Floor details successfully udpated.`);
        loadFloor();
      }).catch(res=>{

      })
    }

    return <>  <><IZoneCU close={()=> setselectedFile(null)} moreComponents={<>
  <div style={{textAlign:"center"}}>
      
  <Form.Group controlId="formFileDisabled" className="mb-3">
    <Form.Label>Select File</Form.Label>
    <Form.Control type="file"  onChange={changeHandler} />
  </Form.Group>
  {selectedFile && <Image fluid={true} src={URL.createObjectURL(selectedFile)} />}
  
  </div>
    </>} proccess={()=> {uploadFloortoServer()}} fields={null}type="Update" titleComponent={<Button style={{margin:5}} size='sm' variant="warning"> <File /></Button>} title="Upload Floor Plan" /></></>
  }

  const [showEditAdd, setshowEditAdd] = useState(false);

const [selectedFloor, setselectedFloor] = useState();

  const handleCreation = (data)=>{
    httpService.post(`/v1/building`,data)
      .then(response=> successMessage(`New Floor created !`))
      .catch(error=>  errorMessage(`Failed to create new floor (${error})`))
  }

  const handleSelectedEdit = (formInformation)=>{

  }
  


  return (
    <>
    <Header />
    <HeaderTitle name="Floors" />

    <Container>
    
    <Button onClick={()=>{setshowEditAdd(true);setselectedFloor(null)}} size='sm' variant='dark'>+</Button>
    <FloorForm onCreate={handleCreation} initialValues={selectedFloor}   visible={showEditAdd}  onCancel={()=> setshowEditAdd(false)} />
      <NewFloor />
      <div style={{textAlign:"center"}}>
     <GridLoader  margin={10} loading={isLoading} size={30} color="black" />
     </div>
      <DataTable
      pagination={true}
      paginationPerPage={10}
     
        columns={floorsColums}
        data={floors}
    
      /> 

    </Container>
  </>
  );
}

export default Floors;

