import axios from 'axios';

var retry = 0;

const envSettings = window ;

axios.defaults.baseURL= envSettings.API_URL;

const axiosApiInstance = axios.create({baseURL:envSettings.API_URL});

export const axiosinstance2 = axios.create();

//axios.defaults.baseURL= "http://192.168.1.186:40009";
export default ({
    get: axiosApiInstance.get,
    post: axiosApiInstance.post,
    put: axiosApiInstance.put,
    delete: axiosApiInstance.delete
});


axiosApiInstance.interceptors.response.use((response)=>
    {retry=0; return response}, async (error)=>{

    const originalRequest = error.config;

    console.log("Login tries .... ", retry);


     if(error.request && error.response.status === 401)
     {
       // console.log(error.request);
        window.location = '/app/login/ready';
     }


     if(error.request && error.response.status === 403 && retry >= 5)
     {
       // console.log(error.request);
        window.location = '/app/login/ready';
     }

     if(error.request && error.response.status === 403 && retry < 5)
     {
        retry++;

        var newTokens = await getnewToken();

        if(newTokens)
        {
            localStorage.setItem("jwt_token",newTokens.jwt_token);
            localStorage.setItem("jwt_refreshtoken",newTokens.jwt_refreshtoken);
            axios.defaults.headers.common['x-auth'] = newTokens.jwt_token;    
        }
       
        return axiosApiInstance(originalRequest);      
     }

    //  if(error.request && error.response.status === 403 && originalRequest._retry)
    //  {    
    //     window.location = '/app/login';
    //  }


     return Promise.reject(error);
    }
)

axiosApiInstance.interceptors.request.use((config)=>{
    

    var jwt_token = localStorage.getItem("jwt_token");
    if(jwt_token)
    config.headers["x-auth"] =jwt_token ;

    return config;
})

export async function getnewToken()
{  

    try {
        var rToken = localStorage.getItem("jwt_refreshtoken")
        var result = await axios.post("/v1/auth/token",{
            refreshToken:rToken
        });
    
        return result?.data
    } catch (error) {
        return ;
    }
}