import { Result } from 'antd';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import { useCookies } from 'react-cookie';
import { useParams,useNavigate } from 'react-router-dom';
import { GridLoader } from 'react-spinners';
import { GenericFormElement } from '../Components/GenericFormController';
import httpService from '../services/httpService';
import { errorMessage, successMessage } from '../services/utilities';

const colInfo = [

    { name: "password", title: "Password", type: "password" },
    { name: "passwordConfirmation", title: "Confirm Passowrd", type: "password" },
]

export default function PasswordReset() {

    const navigate = useNavigate();

    const [setuntinformation, setsetuntinformation] = useState({});

    const [loginDetails, setloginDetails] = useState();

    const [passwordResetInformation, setpasswordResetInformation] = useState(true);

    const [isLoading, setisLoading] = useState(true);

    const params = useParams();

    const [userProfile, setuserProfile, removeCookie] = useCookies();

    useEffect(() => {

        setuserProfile("profile", "", { path: '/' });
        setuserProfile("token", "", { path: '/' });
    
    
        localStorage.removeItem("profileInfo");
        localStorage.removeItem("moreProfile");
        localStorage.removeItem("tokencs");
        localStorage.removeItem("token");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("jwt_refreshtoken");
       
        async function loadUnitInfo() {

            try {
                const { data } = await httpService.get('/app/logo');
                setsetuntinformation({ ...data });

            } catch (error) {
                errorMessage(`Failed to load unit settings`);
            }
        }

        httpService.get(`/v1/auth/${params?.token}/resetpassword`).then(response => {
            const { data } = response;
            setpasswordResetInformation({ ...data });
            setisLoading(false);
        }).catch(error => {
            setpasswordResetInformation(false);
            setisLoading(false);
        })

        loadUnitInfo();

        return () => {

        }
    }, [])

    const updateValue = (e) => {
        setloginDetails((oldValue) => ({
            ...oldValue,
            [e.target.name]: e.target.value,
        }));
    };

    const changePassword = ()=>{
        httpService.post(`/v1/auth/${params?.token}/resetpassword`,loginDetails).then(response=>{
            const {data} = response;
            successMessage(`Password changed successfully!`);
            setInterval(() => {
                
                window.location = `/app/login/ready`;
            }, 2000);
        }).catch(error=>{
            errorMessage(`${error?.response?.data || error}`)
        })
    }

    return (
        <>
            <div>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4}>
                        {" "}
                        <div
                            style={{
                                textAlign: "center",
                                marginTop: 100,
                                margin: 50,
                                alignItems: "center",
                            }}
                        >
                            <Card style={{ borderWidth: 2, borderColor: "black" }}>
                                <Card.Body>
                                    <Card.Title>

                                        <h3>Password Reset</h3>{" "}
                                    </Card.Title>
                                    <div style={{ textAlign: "center" }}>
                                        {setuntinformation && (
                                            <div>
                                                <Image
                                                    height={100}
                                                    width={100}
                                                    src={setuntinformation?.iconUrl}
                                                    style={{ margin: 5 }}
                                                />
                                                <h5>{setuntinformation.unitName}</h5>
                                            </div>
                                        )}
                                    </div>

                                    <div style={{ textAlign: "center" }}>
                                        <GridLoader margin={10} loading={isLoading} size={30} color="black" />
                                    </div>

                                    {!isLoading && <>

                                        {!passwordResetInformation &&
                                            <Result
                                                status="warning"
                                                title="The password reset link is not available."
                                                extra={
                                                    <>

                                                        <Button onClick={e=>  navigate("/app/login/ready")} variant='dark' key="console">
                                                            Go Login
                                                        </Button>
                                                    </>
                                                }
                                            />
                                        }

                                        {passwordResetInformation && <div style={{ textAlign: "center", margin: 20 }}>

                                            Hi {passwordResetInformation?.user?.name} {passwordResetInformation?.user?.surname} , please enter the new password for your account {passwordResetInformation?.user?.username} .

                                            <Row>
                                                {colInfo.map((a) => (
                                                    <GenericFormElement {...a} handleChange={updateValue} />
                                                ))}
                                            </Row>

                                            <div style={{ margin: 10, textAlign: "center" }}>
                                                <Button onClick={e=> changePassword()} variant='dark' key="console">
                                                    Change Password
                                                </Button>
                                            </div>
                                        </div>}

                                    </>}






                                    <div style={{ textAlign: "center" }}>
                                        <Image
                                            fluid={true}
                                            height={100}
                                            width={100}
                                            src="/logo2.png"
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </div>
        </>
    );
}
