import React, { useEffect, useRef, useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Polygon,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
  Rectangle,
  Circle,
  AttributionControl,
  Contr,
  FeatureGroup,
  Polyline,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import flo from "../image/icon/camera.png";
import doorOpened from "../image/icon/doorOpened.png";
import doorClosed from "../image/icon/doorClosed.png";
import f1 from "../image/floormap/1f.png";
import f2 from "../image/floormap/2f.jpg";
import b2 from "../image/floormap/b1.png";
import f4 from "../image/floormap/f4.jpg";
import s_green from "../image/icon/sensor_green.png";
import s_red from "../image/icon/sensor_red.png";
import L from "leaflet";
import {
  Col,
  Row,
  Button,
  Badge,
  Card as Cardold,
  Accordion,
  Table,
} from "react-bootstrap";
import { EditControl } from "react-leaflet-draw";
import httpService from "../services/httpService";
import {
  IZoneAdvanceSettings,
  IZoneTresholds,
  IZoneUpdator,
  ListOfPeopleV2,
  UpdaAccessLocations,
  UpdaZoneLocations,
} from "../Pages/IZones";
import { UpdateData, UpdateLocationDetails } from "../Pages/LocationAccess";
import {
  Activity,
  Gear,
  GearFill,
  InfoCircle,
  PlusCircle,
  Signpost,
  Signpost2,
} from "react-bootstrap-icons";
import { InfoCircleFilled, LockFilled, MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Pie } from "@ant-design/plots";
import { Card, Switch, Slider, Drawer } from "antd";
import {
  closeMQTTConnection,
  connectToMQTTBroker,
  errorMessage,
  successMessage,
} from "../services/utilities";
import { AlarmActivities, SimpleActivities } from "../Pages/Activities";
import ReactPlayer from "react-player";
// import { Replay } from 'vimond-replay';
// import 'vimond-replay/index.css';

import { Collapse } from "antd";
import moment from "moment";

const { Panel } = Collapse;

const DemoPie = (props) => {
  const config = {
    appendPadding: 20,

    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return <Pie {...config} data={props.data} />;
};

function ComponentEditorNew(props) {
  const onCreated = (e) => {
    let type = e.layerType;
    let layer = e.layer;

    console.log(`NEW CREATION ${type}`, JSON.stringify(layer?._latlngs));

    if (type === "marker") {
      const { _leaflet_id, _latlng } = layer;
      if (props.addMarker)
        props.addMarker({ id: _leaflet_id, location: _latlng });
    } else {
      //console.log('_onCreated: something else created:', type, e);
    }

    if (type === "polygon") {
      console.log("_onCreated: something else created:", layer._latlngs);

      var pos = layer._latlngs[0].map((a) => {
        return [a.lat, a.lng];
      });
      const { _leaflet_id } = layer;
      if (props.add)
        props.add({
          id: _leaflet_id,
          latlngs: layer.getLatLngs()[0].map((a) => [a.lat, a.lng]),
        });
      console.log("Current polygon", pos);
    }
    // Do whatever else you need to. (save to db; etc)
  };
  return (
    <>
      {" "}
      <FeatureGroup>
        <EditControl
          position="topright"
          onCreated={onCreated}
          draw={{
            circlemarker: false,
            circle: false,
            rectangle: false,
          }}
        />
        <Circle center={[51.51, -0.06]} radius={200} />
      </FeatureGroup>
    </>
  );
}

function MyComponent(props) {
  const map = useMap();

  map.on("zoomend", (e) => {});

  const initounds = new L.LatLngBounds(null, null);

  return (
    <>
      <ImageOverlay
        url={props.floor}
        bounds={[
          [51.49, -0.08],
          [51.5, -0.06],
        ]}
      ></ImageOverlay>
    </>
  );
}

var selectedFloor;

export const sendToCommand = (
  deviceid,
  command,
  type = null,
  value = null,
  metta = "",
  feedback=true
) => {
  httpService
    .post(`/v1/izone/devicecontroll`, {
      deviceId: deviceid,
      command: command,
      type: type,
      value: value,
      mettadata: metta,
    })
    .then((response) => {
      if(feedback)
      successMessage("Command being proccessed ...");
    })
    .catch((error) => {
      const { data } = error?.response;
      errorMessage(`Failed to send command (${data})`);
    });
};

export const sendToCommandLegacy = (
  deviceid,
  command,
  type = null,
  value = null,
  metta = ""
) => {
  httpService
    .post(`/v1/izone/devicecontroll/legacy`, {
      deviceId: deviceid,
      command: command,
      type: type,
      value: value,
      mettadata: metta,
    })
    .then((response) => {
      successMessage("Command being proccessed ...");
    })
    .catch((error) => {
      const { data } = error?.response;
      errorMessage(`Failed to send command (${data})`);
    });
};

export const generateSensorLabel = (sensor) => {

  var buildColor;  

  var label = sensor?.valueLables?.find((a) => a.value === sensor.value);
  
  if(label?.color==="success") buildColor="green";
  if(label?.color==="danger") buildColor="red";
  if(label?.color==="warning") buildColor="orange";
  
  if (sensor?.updateCommand)
    return (
      <Col>
        {" "}
        
          <h6 style={{fontWeight:"bold"}}><PlusCircleFilled style={{margin:5, color:"red"}} onClick={() =>
            sendToCommand(
              sensor.deviceId,
              sensor.updateCommand,
              2,
              (Number(sensor.value) + 1).toString(),
              "",
              false
            )
          }/>{sensor.value} {sensor.symbole}
           <MinusCircleFilled style={{margin:5, color:"blue"}}  onClick={() =>
            sendToCommand(
              sensor.deviceId,
              sensor.updateCommand,
              2,
              (Number(sensor.value) - 1).toString(),
              "",
              false
            )
          }/>
          </h6>
          
          
         
       
       
      </Col>
    );

  if (label)
    return (
      <Col>
        {" "}
        
        <h6 style={{color:buildColor, fontWeight:"bold"}}> {label.label} </h6>
      </Col>
    );
  else
    return (
      <Col>
        {" "}
        {/* <Badge bg={"dark"}>
          {" "}
         
        </Badge>{" "} */}
        <h6 style={{ fontWeight:"bold"}}> {sensor.value} {sensor.symbole}</h6>
      </Col>
    );
};

export const sendupdateTresholdCommand = (deviceid, sensor, values) => {
  var sensorValues = {
    [`${sensor}_HIGH`]: values[1],
    [`${sensor}_LOW`]: values[0],
  };

  httpService
    .post(`/v1/izone/deviceattributes`, {
      deviceId: deviceid,
      payload: sensorValues,
    })
    .catch((response) => {
      successMessage("command send success fully");
    })
    .catch((error) => {
      errorMessage("failed to send command");
    });
};

export const sendupdateTresholdCommandGeneric = (deviceid, sensor, values) => {
  var sensorValue = {
    [`${sensor}_CT`]: values,
  };

  httpService
    .post(`/v1/izone/deviceattributes`, {
      deviceId: deviceid,
      payload: sensorValue,
    })
    .catch((response) => {
      successMessage("command send success fully");
    })
    .catch((error) => {
      errorMessage("failed to send command");
    });
};

export default function Floor(props) {
  const iniBounds = L.latLngBounds(null, null);
  let camera = L.icon({
    iconUrl: flo,
    iconRetinaUrl: flo,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [50, 50],
  });

  let doorOpenedIcon = L.icon({
    iconUrl: doorOpened,
    iconRetinaUrl: doorOpened,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [50, 50],
  });

  let doorClosedIcon = L.icon({
    iconUrl: doorClosed,
    iconRetinaUrl: doorClosed,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [50, 50],
  });

  let sensor_green = L.icon({
    iconUrl: s_green,
    iconRetinaUrl: s_green,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [20, 20],
  });

  let sensor_red = L.icon({
    iconUrl: s_red,
    iconRetinaUrl: s_red,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [30, 30],
  });

  const [polygones, setpolygones] = useState([]);

  const [newPolygones, setnewPolygones] = useState([]);

  const [fromAddingNewPolygone, setfromAddingNewPolygone] = useState(false);

  const [currentFloorId, setcurrentFloorId] = useState();



  const [currentBuildingSelected, setcurrentBuildingSelected] = useState({});

  const [addNewAccessLocation, setaddNewAccessLocation] = useState(false);

  const [accessLocationDevices, setaccessLocationDevices] = useState();

  const laodAllAvailableFloors = () => {
    httpService
      .get("/v1/building")
      .then((fl) => {
        const { data } = fl;
        var floorsMod = data.map((df) => {
          return {
            _id: df._id,
            name: df.name,
            description: df.description,
            image: `${window.API_URL}/v1/building/${df._id}/floor`,
          };
        });
        setfloors([...floorsMod]);

        if ([...floorsMod].length > 0) {
          setcurrentBuildingSelected({ ...floorsMod[0] });
          selectedFloor = floorsMod[0];
        }

        //loadFloorDetails(floorsMod[0].name);
      })
      .catch((error) => {
        setfloors([]);
      });
  };

  const loadLocationAccess = (floor) => {
    httpService
      .get(`/v1/LocationsAccess/floor/${selectedFloor?._id}`)
      .then((a) => {
        const { data } = a;
        setaccessLocationDevices([...data]);
      })
      .catch((r) => {});
  };

  const laodActivities = () => {
    httpService
      .get("/v1/activity")
      .then((re) => {
        const { data } = re;
        setrecentActivities([...data]);
      })
      .catch((er) => {});
  };

  useEffect(() => {
    var reconnectAfter = true;

    loadBuildingInformationV2((callback) => {
      // /setbuildingSelectedFloor({ ...callback?.floors[0] });
    });

    connectToMQTTBroker(
      "/v2/udpateTelemetries/dashboard/dev",
      (msg) => {
        loadBuildingInformationV2(null);
      },
      "",
      ""
    );
    return () => {
      closeMQTTConnection();
    };
  }, []);

  const izoneColorGenerator = (data) => {
    if (data?.alarms?.find((al) => al.status === "alarm")) return "red";
    else if (data?.status === "Locked") return "red";
    else if (data?.peopleCount === 0) return "green";
    else if (data?.peopleCount > 0) return "yellow";
    else return "green";
  };

  const sendCommand = (deviceid, command, type = null, value = null) => {
    httpService
      .post(`/v1/izone/devicecontroll`, {
        deviceId: deviceid,
        command: command,
        type: type,
        value: value,
      })
      .catch((response) => {
        successMessage("command send success fully");
      })
      .catch((error) => {
        errorMessage("failed to send command");
      });
  };

  const getDeviceAttributes = (devid, sensors) => {
    httpService
      .post(`/v1/izone/devices/attributes`, {
        deviceId: devid,
        keys: sensors,
      })
      .then((response) => {
        const { data } = response;
        // alert(`tresholds`)
        setizoneTresholdsSettings([...data]);
      })
      .catch((error) => {
        errorMessage("failed to send command");
      });
  };

  const generateLabel = (sensor) => {
    var label = sensor?.valueLables?.find((a) => a.value === sensor.value);
    if (sensor?.updateCommand)
      return (
        <>
          {" "}
          <Badge
            onClick={() =>
              sendCommand(
                sensor.deviceId,
                sensor.updateCommand,
                2,
                (Number(sensor.value) + 1).toString()
              )
            }
            as="button"
            bg="warning"
          >
            +
          </Badge>{" "}
          <Badge bg={"dark"}>
            {" "}
            {sensor.value} {sensor.symbole}
          </Badge>{" "}
          <Badge
            onClick={() =>
              sendCommand(
                "2ad1c2f0-5a7b-11ec-b737-c34620894778",
                "SET TEMPERATURE",
                2,
                (Number(sensor.value) - 1).toString()
              )
            }
            as="button"
            bg="warning"
          >
            -
          </Badge>{" "}
        </>
      );

    if (label) return <Badge bg={label.color}>{label.label}</Badge>;
    else
      return (
        <>
          {" "}
          <Badge bg={"dark"}>
            {" "}
            {sensor.value} {sensor.symbole}
          </Badge>{" "}
        </>
      );
  };

  // Update

  function loadBuildingInformationV2(callback) {
    httpService
      .get(`/v1/buildinglocation/floors/${props.buildingId}`)
      .then((res) => {
        const { data } = res;
        setbuidlingInformation({ ...data });
        if (callback) callback(data);
      })
      .catch((err) => {
        errorMessage(`Error when loading building information.`);
      });
  }

  //const [floors, setfloors] = useState([{name:"Floor 1", image:f4},{name:"Floor 2", image:b2},{name:"Floor 1", image:f4},{name:"Floor 2", image:b2},{name:"Floor 1", image:f4},{name:"Floor 2", image:b2},{name:"Floor 1", image:f4},{name:"Floor 2", image:b2}])
  const [floors, setfloors] = useState();

  const [recentActivities, setrecentActivities] = useState([]);

  const [selectedLiveStreams, setselectedLiveStreams] = useState();

  const [alarms, setalarms] = useState();

  const [mapCenter, setmapCenter] = useState([51.505, -0.09]);

  const [izoneTresholdsSettings, setizoneTresholdsSettings] = useState([]);

  const myMap = useRef();

  const [buidlingInformation, setbuidlingInformation] = useState();

  const [buildingSelectedFloor, setbuildingSelectedFloor] = useState(null);

  const [izoneSelected, setizoneSelected] = useState(null);

  const [slectedSensor, setslectedSensor] = useState(null);

  return (
    <>
      {/* <IZoneAdvanceSettings
              izoneAll={izoneSelectedAllInfo}
              selectediZone={selectizoneAvanceSettings ?? []}
              onClose={()=>setdisplayBuldingSettings(null)}
              show={displayBuldingSettings}
     /> */}
      <Row>
        <Col lg={10} md={8}>
          {slectedSensor && (
            <IZoneTresholds
              close={() => {
                setslectedSensor(null);
              }}
              query={slectedSensor}
            />
          )}

          {
            <IZoneControlMenu
              setslectedSensor={setslectedSensor}
              buidlingInformation={{}}
              onClose={() => setizoneSelected(null)}
              visible={buidlingInformation?.floors
                ?.find(
                  (buildingfind) =>
                    buildingfind?._id === buildingSelectedFloor?._id
                )
                ?.izones?.find(
                  (izonefinder) => izonefinder?._id === izoneSelected?._id
                )}
            />
          }

          {}

          <Cardold>
            <Cardold.Body>
              <Cardold.Title>
                <h3 style={{ textAlign: "center" }}>
                  {buidlingInformation?.name}{" "}
                </h3>
                <h3 style={{ textAlign: "center" }}>
                  {buildingSelectedFloor?.name}{" "}
                </h3>
              </Cardold.Title>
              <MapContainer
                ref={myMap}
                zoomControl={false}
                maxZoom={20}
                minZoom={15}
                style={{ height: "100vh" }}
                center={mapCenter}
                zoom={16}
                scrollWheelZoom={true}
                maxBounds={[
                  [51.49, -0.08],
                  [51.5, -0.06],
                ]}
              >
                <ZoomControl
                  maxZoom={20}
                  zoom={16}
                  position="topright"
                  scrollWheelZoom={true}
                  minZoom={15}
                />

                <div>
                  {/* {accessLocationDevices &&
                    accessLocationDevices.map((corloca) => (
                      <Marker
                        draggable
                        key={corloca.cordinates.id}
                        icon={
                          corloca.status === "OPENED"
                            ? sensor_green
                            : sensor_red
                        }
                        position={corloca.cordinates.location}
                      >
                        <Popup>
                          <div>
                            <UpdateLocationDetails
                              mainComponents={<>{corloca.name}</>}
                              _id={corloca._id}
                              variant="warning"
                              icon={<InfoCircleFilled />}
                            />
                          </div>

                          <Badge bg="dark">{corloca.status}</Badge>
                        </Popup>
                      </Marker>
                    ))}
                     */}
                  {buildingSelectedFloor?.izones &&
                    buidlingInformation?.floors
                      ?.find(
                        (buildingfind) =>
                          buildingfind?._id === buildingSelectedFloor?._id
                      )
                      ?.izones?.map((e) => ({
                        ...e.cordinates,
                        label: e.name,
                        _id: e._id,
                        liveStreams: e?.liveStreams,
                        status: e?.status,
                        sensors: e?.sensors,
                        alarms: e?.alarms,
                        people: e?.people,
                        peopleCount: e?.people?.length,
                        switchcontrolls: e?.switchcontrolls,
                        commands: e?.commands,
                      }))
                      .map((a) => (
                        <Polygon
                          key={`poly${a.id}`}
                          eventHandlers={{
                            click: () => setselectedLiveStreams(a?.liveStreams),
                          }}
                          pathOptions={{ color: izoneColorGenerator(a) }}
                          positions={a?.latlngs || []}
                          children={<h2>dsd</h2>}
                        >
                          <Popup>
                            {a?.status === "Locked" && (
                              <>
                                {" "}
                                <Badge bg="danger">
                                  <LockFilled
                                    title="iZone Locked"
                                    color="danger"
                                  />{" "}
                                </Badge>
                              </>
                            )}
                            <GearFill
                              onClick={(e) => setizoneSelected(a)}
                            ></GearFill>
                            <IZoneUpdator
                              name={a.label}
                              _id={a._id}
                              titleComponent={<>{a.label}</>}
                            />
                            <ListOfPeopleV2
                              titleComponent={
                                <Badge
                                  style={{ margin: 5 }}
                                  bg={
                                    a.peopleCount === 0 ? "success" : "danger"
                                  }
                                >
                                  {a.peopleCount} people
                                </Badge>
                              }
                              row={{ name: a.label }}
                              peopleInSelectedZone={[...a.people]}
                            />

                            <Collapse defaultActiveKey={["3"]}>
                              <Panel header="Alarms" key="3">
                                {a?.alarms && (
                                  <>
                                    {a?.alarms?.map(
                                      (al) =>
                                        al?.status === "alarm" && (
                                          <Badge
                                            title={moment(al.date).fromNow()}
                                            style={{ margin: 2 }}
                                            bg="danger"
                                          >
                                            {al.label}
                                          </Badge>
                                        )
                                    )}
                                  </>
                                )}
                              </Panel>
                            </Collapse>
                          </Popup>
                        </Polygon>
                      ))}
                </div>

                {buildingSelectedFloor?._id && (
                  <MyComponent
                    floor={`${window.API_URL}/v1/building/${buildingSelectedFloor?._id}/floor`}
                    icons={[]}
                  />
                )}

                <ComponentEditorNew
                  addMarker={(e) => {
                    setaddNewAccessLocation({ ...e });
                  }}
                  add={(e) => {
                    setfromAddingNewPolygone({ ...e });
                    setnewPolygones((olds) => [...olds, { ...e }]);
                  }}
                />
              </MapContainer>
            </Cardold.Body>
          </Cardold>
        </Col>
        <Col lg={2} md={4}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Floors" key="1">
              <div style={{ textAlign: "center" }}>
                {}
                {buidlingInformation?.floors &&
                  buidlingInformation?.floors.map((fr) => (
                    <Button
                      title={fr.description}
                      variant="dark"
                      onClick={(e) => {
                        setbuildingSelectedFloor({ ...fr });
                      }}
                      style={{
                        margin: 2,
                        borderWidth: 2,
                        width: 100,
                        height: 100,
                        borderRadius: 60,
                      }}
                    >
                      {fr.name}
                    </Button>
                  ))}
              </div>
            </Panel>
            {/* <Panel header="Recent Activities" key="2">
          <SimpleActivities activities={recentActivities} />
          </Panel> */}
            {/* <Panel header="Alarms" key="3">
           {(buildingSelectedFloor?.izones?.map(mapizonemap=>(mapizonemap.alarms?.filter(alarmsfilter=> alarmsfilter.status==="alarm")?.map(alrmmap=>(<AlarmActivities
                      type={alrmmap?.type}
                      name={alrmmap?.label}
                      date={alrmmap?.date}
                      izone={mapizonemap?.name}
                    />)))))}
          
          </Panel> */}
          </Collapse>
          <UpdaZoneLocations
            currentSelectedBuilding={buildingSelectedFloor}
            currentFloorId={currentFloorId}
            close={() => {
              setfromAddingNewPolygone(null);
            }}
            forceShow={fromAddingNewPolygone}
          />
          <UpdaAccessLocations
            currentSelectedBuilding={currentBuildingSelected}
            currentFloorId={buildingSelectedFloor}
            close={() => {
              setaddNewAccessLocation(null);
            }}
            forceShow={addNewAccessLocation}
          />
        </Col>
      </Row>
    </>
  );
}

export function IZoneControlMenu(props) {
  return (
    <>
      <Drawer
        width={400}
        title="Izone Information."
        placement="right"
        onClose={() => {
          if (props.onClose) props.onClose();
        }}
        visible={props?.visible}
      >
        <h4>
          <Signpost2 /> {props?.visible?.name}
        </h4>
        <p>
          {props?.visible?.sensors && (
            <>
              <h6 style={{ fontWeight: "bold" }}>
                <Activity /> Sensors
              </h6>
              <Table>
                <tbody>
                  {props?.visible?.sensors?.map((mapsensor) => (
                    <tr>
                      <>
                        <td
                          onClick={(e) => {
                            props.setslectedSensor({
                              query: {
                                deviceId: mapsensor.deviceId,
                                keys: mapsensor.name,
                              },
                              ...mapsensor,
                            });
                          }}
                        >
                          {mapsensor.label}
                        </td>
                        <td> {generateSensorLabel(mapsensor)}</td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </p>
        <p>
          {props?.visible?.commands && (
            <>
              <h6 style={{ fontWeight: "bold" }}>
                <Gear /> Commands
              </h6>
              <div style={{ margin: 0 }}>
                <Table>
                  <tbody>
                    {props?.visible?.commands?.map((cm) => (
                      <tr>
                        <td>{cm.name}</td>

                        <td>
                          {cm?.commands?.map((cmd) => (
                            <Badge
                              as={"button"}
                              onClick={(e) => {
                                if (cm?.type != "Legacy")
                                  sendToCommand(
                                    cm?.deviceId,
                                    cmd?.command,
                                    null,
                                    cmd?.value
                                  );
                                else {
                                  sendToCommandLegacy(
                                    cm?.deviceId,
                                    cmd?.command,
                                    null,
                                    cmd?.value
                                  );
                                }
                              }}
                              style={{ marginLeft: 5 }}
                              size="sm"
                              bg="warning"
                            >
                              {cmd.label}
                            </Badge>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </p>
      </Drawer>
    </>
  );
}
