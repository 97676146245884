import { FileAddFilled } from '@ant-design/icons'
import { Collapse, Descriptions, Input, Modal as ModalAnt, Radio, Result, Select } from 'antd'
import moment, { HTML5_FMT } from 'moment'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { Badge, Button, NavDropdown } from 'react-bootstrap'
import { Cpu, Activity, TerminalFill, Command } from 'react-bootstrap-icons'
import DataTable from 'react-data-table-component'
import httpService, { axiosinstance2 } from '../services/httpService'
import { closeMQTTConnection, connectToMQTTBroker, connectWS, disconnectWS, errorMessage, successMessage } from '../services/utilities'
import { CustomModalV2 } from './CustomModal';
import Terminal from 'terminal-in-react';

import JSONInput from 'react-json-editor-ajrm'
import locale    from 'react-json-editor-ajrm/locale/en';

export default function VSITConnect() {

  const [vsitConnectInfo, setvsitConnectInfo] = useState(null)


  useEffect(() => {
    const currentURL = window.location.href
    var vsitInfo = { url: currentURL, key: "ajksd93473497349" };
    setvsitConnectInfo(JSON.stringify(vsitInfo));

  }, [])

  return (
    <>

      <CustomModalV2 closeAfterProccess={true} centered={false} size="sm" proccess={() => { }} canProccess={true} proccessTitle={"OK"} title={`VSIT Connect`} mainComponents={<>  <NavDropdown.Item >VSIT Connect</NavDropdown.Item>         </>}>



        <div style={{ textAlign: "center" }}>
          <p>Please scan this QR Code to login to your company VSIT account. </p>

          {vsitConnectInfo && <> <QRCode size={150} value={vsitConnectInfo} /></>}

        </div>




      </CustomModalV2>
    </>
  )
}

export function SystemSettings() {
  const inputList = [
    { name: "iconUrl", label: "Icon Url Link" },
    { name: "siteName", label: "Site Name" },
    {
      name: "VSIT_telegramBot",
      label: "Telegram Bot Token",
    },
    {
      name: "VSIT_telegram_chanel",
      label: "Telegram Chanel ID",
    },
    {
      name: "VSIT_ALARM_CALLBACKS",
      label: "Alarms Webhooks",
      type:"select"
    },
  ];

  const SSO = [
    { name: "VSIT_SSO_AZURE_CLIENTID", label: "VSIT_SSO_AZURE_CLIENTID" },
    {
      name: "VSIT_SSO_AZURE_CLIENTSECRET",
      label: "VSIT_SSO_AZURE_CLIENTSECRET",
    },
    { name: "VSIT_SSO_AZURE_REDIRECTURL", label: "VSIT_SSO_AZURE_REDIRECTURL" },
    { name: "VSIT_SSO_GOOGLE_CLIENTID", label: "VSIT_SSO_GOOGLE_CLIENTID" },
    {
      name: "VSIT_SSO_GOOGLE_CLIENTSECRET",
      label: "VSIT_SSO_GOOGLE_CLIENTSECRET",
    },
    {
      name: "VSIT_SSO_GOOGLE_REDIRECTURL",
      label: "VSIT_SSO_GOOGLE_REDIRECTURL",
    },
    {
      name: "VSIT_SSO_redirectAppCallBack",
      label: "VSIT_SSO_redirectAppCallBack",
    },
    {
      name: "VSIT_SSO_failureRedirectCallback",
      label: "VSIT_SSO_failureRedirectCallback",
    },

  ];

  const [systemInformation, setsystemInformation] = useState({});

  function loadSettingInformation() {
    httpService
      .get("/v1/api/app/system")
      .then((response) => {
        const { data } = response;
        setsystemInformation({ ...data });
      })
      .catch((err) => { });
  }

  function saveSettings() {
    warning(() => {
      httpService.post('/v1/api/app/system', systemInformation)
        .then(res => {
          successMessage('Settings saved successfully !');
          window.location = `${window.API_URL}/auth/logout`;
        })
        .catch(err => {
          errorMessage(`Failed to save system settings (${err}`)
        })
    })

  }

  const warning = (callback) => {
    var mymodal = ModalAnt.confirm({

      title: 'Saving Settings',
      content: 'Are you sure you want to save these settings? After saving these settings, the system will reboot after 10 seconds for the setting to take effect.',
      onOk: e => { callback(); mymodal.destroy() }
    });
  };

  return (
    <>
      <CustomModalV2
        load={loadSettingInformation}
        closeAfterProccess={true}
        centered={false}
        size="md"
        proccess={() => { saveSettings() }}
        canProccess={true}
        proccessTitle={"OK"}
        title={`System Settings`}
        mainComponents={
          <>
            {" "}
            <NavDropdown.Item>System Settings</NavDropdown.Item>{" "}
          </>
        }
      >
        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="System Information" key="1">
            {inputList?.map((input) => (
              <div style={{ margin: 5 }}>
                {input?.label}
                {!input?.type &&
                    <Input
                    placeholder={input?.label}
                    bordered
                    value={systemInformation[input.name]}
                    onChange={(e) =>
                      setsystemInformation((old) => ({
                        ...old,
                        [input?.name]: e.target.value,
                      }))
                    }
                  />
                }
            
                {input?.type==="select" &&
                <>
                <Select onChange={e=>{
                  alert(e)
                  setsystemInformation(old=> ({...old,[input.name]:e.join()}))
                }} value={(systemInformation[input.name]?.split(","))} style={{width:"400px"}} mode='tags' ></Select></>
                }
              </div>
            ))}
          </Collapse.Panel>

          <Collapse.Panel header="SSO" key="2">
            {SSO?.map((input) => (
              <div style={{ margin: 5 }}>
                {input?.label}
                <Input
                  placeholder={input?.label}
                  bordered
                  value={systemInformation[input.name]}
                  onChange={(e) =>
                    setsystemInformation((old) => ({
                      ...old,
                      [input?.name]: e.target.value,
                    }))
                  }
                />
              </div>
            ))}
          </Collapse.Panel>
        </Collapse>
      </CustomModalV2>
    </>
  );
}

export function VSITIPOPS() {


  const [ipops, setipops] = useState([]);
  const [deviceRegistrationCode, setdeviceRegistrationCode] = useState({
    name: "",
    code: ""
  });



  const [templateID, settemplateID] = useState((new Date()).getTime());

  const [fileSignature, setfileSignature] = useState();

  const [importTemplateFeedback, setimportTemplateFeedback] = useState([]);

  const [fileTemplate, setfileTemplate] = useState();


  const startTemplateImport = () => {

    if (!fileTemplate) return errorMessage('Please select File to import...');

    const formData = new FormData();

    formData.append("template", fileTemplate);
    formData.append("templatedId", templateID);
    formData.append("signature", fileSignature);

    httpService.post('/v1/buildinglocation/template', formData)
      .then(res => { })
      .catch(err => {
        errorMessage(`Could not start the import (${err.response.data})`);
      })

  }

  const loadDevicelist = () => {
    httpService
      .get("/v1/building/availabledevices")
      .then((response) => {
        const { data } = response;
        setipops([...data]);
      })
      .catch((error) => {
        setipops([]);
      });
  };

  const clearInput = () => {
    setdeviceRegistrationCode({ name: "", code: "" });
  }
  const addDevice = () => {

    var find = ipops.find(findipops => findipops.label === deviceRegistrationCode.name);
    if (find)
      return errorMessage(`${deviceRegistrationCode.name} has already been assigned to your account.`);

    httpService.post('/v1/building/adddevice', {
      name: deviceRegistrationCode.name,
      code: deviceRegistrationCode.code
    }).then(res => {
      setjustAddediPoP(true);
      const { data } = res;
      successMessage(`Device Added Successfully`);
      clearInput();
      loadDevicelist();
    }).catch(error => {
      errorMessage(`Failed to add device: (${error?.response?.data || error} )`);
    })
  }

  const connectToMQTTServerToWaitFromImport = () => {
    var profile = JSON.parse(localStorage.getItem("profileInfo"));

    var topic = `/v2/${profile?.CID}/templateCreation/${templateID}`;
    connectToMQTTBroker(
      topic,
      (topc, msg) => {
        if (topc === topic) {

          try {
            let mymsg = JSON.parse(msg);
            setimportTemplateFeedback(old => [...old, mymsg])
            //console.log('Message',mymsg)
          } catch (error) {
            //console.log('Message failed',msg)
          }
          // 
        }
        //console.log(`new data received reloading`);
      },
      "",
      ""
    );



  }

  const [justAddediPoP, setjustAddediPoP] = useState(false);

  return (
    <>
      <CustomModalV2
        close={() => {

          closeMQTTConnection();
          setipops([]);
          setimportTemplateFeedback([]);
          setdeviceRegistrationCode({ name: "", code: "" });
        }}
        load={() => { loadDevicelist(); connectToMQTTServerToWaitFromImport() }}
        closeAfterProccess={true}
        centered={false}
        size="lg"
        proccess={() => { }}
        canProccess={true}
        proccessTitle={null}
        title={`IPoPs`}
        mainComponents={
          <>
            {" "}
            <NavDropdown.Item>IPoPs</NavDropdown.Item>{" "}
          </>
        }
      >
        <Collapse >
          <Collapse.Panel header="Add iPoP" key="1">
            {!justAddediPoP && <>
              <p style={{ margin: 5 }}>
                Please enter your device name & registration code to add the iPoP
                to your VISIT account.{" "}
              </p>
              <Input
                placeholder="iPoP Name"
                style={{ margin: 5 }}
                bordered

                onChange={(e) =>
                  setdeviceRegistrationCode(old => ({ ...old, name: e.target.value.trim() }))
                }
                value={deviceRegistrationCode.name}
              />
              <Input
                placeholder="iPoP Registration Code"
                style={{ margin: 5 }}
                bordered

                onChange={(e) =>
                  setdeviceRegistrationCode(old => ({ ...old, code: e.target.value.toUpperCase().trim() }))
                }
                value={deviceRegistrationCode.code}
              />
              <Button onClick={e => addDevice()} style={{ margin: 5 }} variant="success" size="sm">
                Register
              </Button>
            </>}


            {justAddediPoP && <Result
              status="success"
              title={`successfully registration of ${deviceRegistrationCode.name}  ipop to your account !`}
              subTitle="you can access this iPoP in the iPOPs section."
              extra={[
                <Button variant="success" key="console">
                  Add Again
                </Button>

              ]}
            />}

          </Collapse.Panel>

          <Collapse.Panel header="iPoPs" key="2">
            
            <DataTable
              pagination={true}
              paginationPerPage={5}
              columns={[
                { name: "Creation Time", selector: "creationDate", sortable: true, cell: row => <>{moment(row.creationDate).format("MMMM Do YYYY HH:mm")}</> },
                { name: "Name", selector: "label", sortable: true, cell: row => <p>{row.label}</p> },
                { name: "Type", selector: "type", sortable: true },
                { cell: row => <DashoardiPoP /> }
              ]}

              data={ipops}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Import Template" key="3">

            <div style={{ margin: 5 }}>
              File Signature
              <Input
                placeholder="Signature"
                type="file"
                bordered

                onChange={e => setfileSignature(e.target.files[0])}
              />
            </div>
            <div style={{ margin: 5 }}>
              Template File
              <Input
                placeholder="Template"

                bordered
                type="file"
                onChange={e => setfileTemplate(e.target.files[0])}
              />
            </div>


            <Button onClick={e => { startTemplateImport() }} style={{ margin: 5 }} variant="warning" size="sm">
              Start Importing...
            </Button>
            {importTemplateFeedback.length > 0 && <DataTable

              conditionalRowStyles={[{ when: row => row?.type === "success", style: { background: "green", color: "white" } },
              { when: row => row?.type === "danger", style: { background: "red", color: "white" } }
              ]}
              title='Import Status'
              pagination={true}
              paginationPerPage={10}
              columns={[
                { name: "Time", selector: "creationDate", sortable: true, cell: row => <>{moment().format("MMMM Do YYYY HH:mm")}</> },
                { name: "Message", selector: "message", sortable: true, cell: row => <p>{row.message}</p> },
                { name: "Type", selector: "type", sortable: true },
                { name: "From", selector: "origin", sortable: true }
              ]}

              data={importTemplateFeedback}
            />}

          </Collapse.Panel>
        </Collapse>
      </CustomModalV2>
    </>
  );
}

export function VSITIPOPSV3() {


  const [ipops, setipops] = useState([]);
  const [deviceRegistrationCode, setdeviceRegistrationCode] = useState({
    name: "",
    code: ""
  });



  const [templateID, settemplateID] = useState((new Date()).getTime());

  const [fileSignature, setfileSignature] = useState();

  const [importTemplateFeedback, setimportTemplateFeedback] = useState([]);

  const [fileTemplate, setfileTemplate] = useState();


  const startTemplateImport = () => {

    if (!fileTemplate) return errorMessage('Please select File to import...');

    const formData = new FormData();

    formData.append("template", fileTemplate);
    formData.append("templatedId", templateID);
    formData.append("signature", fileSignature);

    httpService.post('/v1/buildinglocation/template', formData)
      .then(res => { })
      .catch(err => {
        errorMessage(`Could not start the import (${err.response.data})`);
      })

  }

  const loadDevicelist = () => {
    httpService
      .get("/v1/building/availabledevices")
      .then((response) => {
        const { data } = response;
        setipops([...data]);
        setfilteredipops([...data]);
      })
      .catch((error) => {
        setipops([]);
      });
  };


  const [filteredipops, setfilteredipops] = useState([]);

  const clearInput = () => {
    setdeviceRegistrationCode({ name: "", code: "" });
  }
  const addDevice = () => {

    var find = ipops.find(findipops => findipops.label === deviceRegistrationCode.name);
    if (find)
      return errorMessage(`${deviceRegistrationCode.name} has already been assigned to your account.`);

    httpService.post('/v1/building/adddevice', {
      name: deviceRegistrationCode.name,
      code: deviceRegistrationCode.code
    }).then(res => {
      setjustAddediPoP(true);
      const { data } = res;
      successMessage(`Device Added Successfully`);
      clearInput();
      loadDevicelist();
    }).catch(error => {
      errorMessage(`Failed to add device: (${error?.response?.data || error} )`);
    })
  }

  const connectToMQTTServerToWaitFromImport = () => {
    var profile = JSON.parse(localStorage.getItem("profileInfo"));

    var topic = `/v2/${profile?.CID}/templateCreation/${templateID}`;
    connectToMQTTBroker(
      topic,
      (topc, msg) => {
        if (topc === topic) {

          try {
            let mymsg = JSON.parse(msg);
            setimportTemplateFeedback(old => [...old, mymsg])
            //console.log('Message',mymsg)
          } catch (error) {
            //console.log('Message failed',msg)
          }
          // 
        }
        //console.log(`new data received reloading`);
      },
      "",
      ""
    );



  }

  const [justAddediPoP, setjustAddediPoP] = useState(false);

  const [showModal, setshowModal] = useState(false)

  return (
    <>
      <NavDropdown.Item onClick={e => { loadDevicelist(); setshowModal(true) }}>IPoPs</NavDropdown.Item>{" "}
      <ModalAnt
        forceRender={true}
        footer={[]}
        width={800}
        okType="success"
        title={`iPoPs`}
        visible={showModal}
        onOk={() => {

        }}
        onCancel={() => {
          closeMQTTConnection();
          setipops([]);
          setimportTemplateFeedback([]);
          setdeviceRegistrationCode({ name: "", code: "" });
          setshowModal(false)
        }}
      >
        <Collapse >
          <Collapse.Panel header="Add iPoP" key="1">
            {!justAddediPoP && <>
              <p style={{ margin: 5 }}>
                Please enter your device name & registration code to add the iPoP
                to your VISIT account.{" "}
              </p>
              <Input
                placeholder="iPoP Name"
                style={{ margin: 5 }}
                bordered

                onChange={(e) =>
                  setdeviceRegistrationCode(old => ({ ...old, name: e.target.value.trim() }))
                }
                value={deviceRegistrationCode.name}
              />
              <Input
                placeholder="iPoP Registration Code"
                style={{ margin: 5 }}
                bordered

                onChange={(e) =>
                  setdeviceRegistrationCode(old => ({ ...old, code: e.target.value.toUpperCase().trim() }))
                }
                value={deviceRegistrationCode.code}
              />
              <Button onClick={e => addDevice()} style={{ margin: 5 }} variant="success" size="sm">
                Register
              </Button>
            </>}


            {justAddediPoP && <Result
              status="success"
              title={`successfully registration of ${deviceRegistrationCode.name}  ipop to your account !`}
              subTitle="you can access this iPoP in the iPOPs section."
              extra={[
                <Button onClick={e => setjustAddediPoP(false)} variant="success" key="console">
                  Add Again
                </Button>

              ]}
            />}

          </Collapse.Panel>

          <Collapse.Panel header="iPoPs" key="2">
          <Input.Search  onChange={e=> {
            let found = ipops.filter(ipop=> ipop?.label?.toUpperCase()?.includes(e.target.value?.toUpperCase()));
            setfilteredipops(found);
          }} setf='Search for ipop'></Input.Search>
            <DataTable
              pagination={true}
              paginationPerPage={5}
              columns={[
                { name: "Creation Time", selector: "creationDate", sortable: true, cell: row => <>{moment(row.creationDate).format("MMMM Do YYYY HH:mm")}</> },
                { name: "Name", selector: "label", sortable: true, cell: row => <p>{row.label}</p> },
                { name: "Type", selector: "type", sortable: true },
                { cell: row => <> <DashoardiPoP {...row} /> <MyiPoPSSH {...row} /></> }
              ]}

              data={filteredipops}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Import Template" key="3">

            <div style={{ margin: 5 }}>
              File Signature
              <Input
                placeholder="Signature"
                type="file"
                bordered

                onChange={e => setfileSignature(e.target.files[0])}
              />
            </div>
            <div style={{ margin: 5 }}>
              Template File
              <Input
                placeholder="Template"

                bordered
                type="file"
                onChange={e => setfileTemplate(e.target.files[0])}
              />
            </div>


            <Button onClick={e => { startTemplateImport() }} style={{ margin: 5 }} variant="warning" size="sm">
              Start Importing...
            </Button>
            {importTemplateFeedback.length > 0 && <DataTable

              conditionalRowStyles={[{ when: row => row?.type === "success", style: { background: "green", color: "white" } },
              { when: row => row?.type === "danger", style: { background: "red", color: "white" } }
              ]}
              title='Import Status'
              pagination={true}
              paginationPerPage={10}
              columns={[
                { name: "Time", selector: "creationDate", sortable: true, cell: row => <>{moment().format("MMMM Do YYYY HH:mm")}</> },
                { name: "Message", selector: "message", sortable: true, cell: row => <p>{row.message}</p> },
                { name: "Type", selector: "type", sortable: true },
                { name: "From", selector: "origin", sortable: true }
              ]}

              data={importTemplateFeedback}
            />}

          </Collapse.Panel>
        </Collapse>
      </ModalAnt>

    </>
  );
}


function DisplayTelemetrieInformation({ name, value }) {

  return <Descriptions.Item label={name}>{value}</Descriptions.Item>
}



export function DashoardiPoP(props) {

  const [showModal, setshowModal] = useState(false);
  const [payloadData, setpayloadData] = useState({});
  const [alarms, setalarms] = useState();
  const [deviceAttributes, setdeviceAttributes] = useState();



  // const [loadingData, setloadingData] = useState(false);

  const filerActivity = ["active", "lastActivityTime"]

  const handleNewData = (data) => {

    var myResponse = JSON.parse(data);

   // console.log(myResponse);

    if (myResponse?.subscriptionId === 1) {

      setpayloadData(old => ({ ...old, ...myResponse }));
    }

    if (myResponse?.subscriptionId === 9) {
      setdeviceAttributes(old => ({ ...old, ...myResponse?.data }));
    }

    if (myResponse?.cmdId === 3) {
      if (myResponse?.data)
        setalarms(myResponse?.data?.data);

      if (myResponse?.update)
        setalarms(myResponse?.update);
    }

  }

  var object = {

    tsSubCmds: [
      {
        entityType: "DEVICE",
        entityId: props?.value,
        cmdId: "1",

      },
    ],
    historyCmds: [

    ],
    // "attrSubCmds":[{"entityType":"DEVICE","entityId":props.value,"scope":"SERVER_SCOPE","cmdId":9}],
    attrSubCmds: [
      {
        entityType: "DEVICE",
        entityId: props?.value,
        cmdId: 9,
        keys: "active,commands,inactivityAlarmTime,lastConnectTime,lastDisconnectTime",
      },
    ]


  };

  function closeAllInformation() {

    setshowModal(false); disconnectWS(); setalarms(null); setpayloadData(null);
  }

  function GetWsInformation() {
    httpService.get('/v1/ipop/ws/details').then(res => {
      const { data } = res;
      const server = `${data.ws}/api/ws/plugins/telemetry?token=${data?.token}`;
      connectWS(server, () => { ModalAnt.warning({ title: "Connection lost", content: <p>Connection to the stream has been lost.</p> }); closeAllInformation() }, handleNewData, object);
    }).catch(err => {
      ModalAnt.warning({ title: "Connection Failed", content: <p>Failed to get connection information</p> })
    })
  }

  function isDeviceOnline()
  {
    return !(deviceAttributes?.active && deviceAttributes?.active[0][1]==="false")
  }

  return (<>

    <Button style={{ margin: 2 }} size="sm" onClick={e => {
      setshowModal(true);
      GetWsInformation();
    }} variant='dark'><Activity /> </Button>
    <ModalAnt
      forceRender={true}
      footer={[]}
      width={1000}
      okType="success"
      title={`iPoPs - ${props.label}`}
      visible={showModal}
      onOk={() => {
      
      }}
      onCancel={() => { closeAllInformation() }}
    >
      
      <Collapse defaultActiveKey={["3"]} style={{background:!isDeviceOnline()?  "orange":"white"}} >
        <Collapse.Panel  header="Device Particular" key="1">
        

          <Descriptions layout="horizontal" bordered title="iPoP Information">
          
            {deviceAttributes && Object.keys(deviceAttributes).map(dataKeys =>
              filerActivity?.find(fa => fa === dataKeys) &&
              <Descriptions.Item label={dataKeys}>
                <p style={{ fontSize: 12 }}>{moment(deviceAttributes[dataKeys][0][0]).format("MMM Do YY HH:mm")}
                  <br />

                </p>
                <h6><Activity /> <Badge bg="dark">{deviceAttributes[dataKeys][0][1]}</Badge> </h6>

              </Descriptions.Item>

            )}
<Descriptions.Item label={"iPoP ID"}>
                <p>{props.value} </p>

              </Descriptions.Item>

          </Descriptions>

          <Descriptions layout="vertical" bordered title="iPoP Alarms">

            {alarms && alarms.map(alarm => <Descriptions.Item label={<Badge bg="danger">{alarm.type}</Badge>}>
              <p style={{ fontSize: 12 }}>{moment(alarm.createdTime).format("MMM Do YY HH:mm")}
                <br />
              </p>


            </Descriptions.Item>)}


          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel header="Device Telemetries" key="2">
          <Descriptions column={4} layout="vertical" bordered  title="iPoP Telemetries">

            {payloadData?.data && Object.keys(payloadData?.data).map(dataKeys => <Descriptions.Item label={dataKeys}>
              <p style={{ fontSize: 12 }}>{moment(payloadData?.data[dataKeys][0][0]).format("MMM Do YY HH:mm")}
                <br />

              </p>
              <h6><Activity /> <Badge title={payloadData?.data[dataKeys][0][1]} bg="dark">{payloadData?.data[dataKeys][0][1]?.substring(0, 30)}</Badge> </h6>

            </Descriptions.Item>)}


          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel header="Advance Commands" key="3">
       
        <SendCustomCommand  deviceId={props.value}/>
        </Collapse.Panel>
      </Collapse>




    </ModalAnt>
  </>)
}

export function IPoPsMultipleCommands(props) {

  const [showModal, setshowModal] = useState(false);
  const [payloadData, setpayloadData] = useState({});
  
  const [isbusy, setisbusy] = useState(false)


  function senRemoteSystemCommand(cmd, deviceId, callback) {
    setisbusy(true);
    httpService.post(`/v1/ipops/sshcommand/tw`,
    {paylaod:cmd,devices:props?.devices}).then(response => {
        const { data } = response;
        // callback({ isError: false, message: data })
        //setcommandResponse({...data});

        ModalAnt.success({ centered:true,title: "Command Sent", content: <p>{"Command sent successfully !"}</p> })
        setisbusy(false);
      }).catch(err => {
        setisbusy(false);
        // callback({ isError: true, message: err?.response?.data || err?.message })
        ModalAnt.warning({ centered:true, title: "Command Failed", content: <p>{JSON.stringify(err?.response?.data || err?.message)}</p> })
      })
  }
  
 




  function closeAllInformation() {

    setshowModal(false); setpayloadData(null);
  }





  return (props?.devices?.length > 0 &&  <>

    <Button style={{ margin: 2 }} size="sm" onClick={e => {
      setshowModal(true);
     
    }} variant='dark'><Activity /> </Button>
    <ModalAnt
      forceRender={true}
      footer={[]}
      width={1000}
      okType="success"
      title={`iPoPs multiple Commands`}
      visible={showModal}
      onOk={() => {
      
      }}
      onCancel={() => { closeAllInformation() }}
    >
      
      <Collapse defaultActiveKey={["3"]}  >
        <Collapse.Panel  header="Device Particular" key="1">
        

          <Descriptions layout="horizontal" bordered title="iPoPs Selected">
          <p></p>
          {props?.devices?.map(device=> <h5>{device?.name}</h5>)}
          </Descriptions>

       
        </Collapse.Panel>
   
        <Collapse.Panel header="Advance Commands" key="3">
       
        <SendCustomCommandV2 comamndHanlder={senRemoteSystemCommand} isBusy={isbusy}  />
        </Collapse.Panel>
      </Collapse>




    </ModalAnt>
  </>)
}

export function SendCustomCommand({comamndHanlder, deviceId, disabled}){

  function senRemoteSystemCommand(cmd, deviceId, callback) {
    setisbusy(true);
    httpService.post(`/v1/ipop/${deviceId}/normalcommand/ow`,
    cmd).then(response => {
        const { data } = response;
        // callback({ isError: false, message: data })
        //setcommandResponse({...data});

        ModalAnt.success({ centered:true,title: "Command Sent", content: <p>{"Command sent successfully !"}</p> })
        setisbusy(false);
      }).catch(err => {
        setisbusy(false);
        // callback({ isError: true, message: err?.response?.data || err?.message })
        ModalAnt.warning({ centered:true, title: "Command Failed", content: <p>{JSON.stringify(err?.response?.data || err?.message)}</p> })
      })
  }

  function senRemoteSystemCommandTW(cmd, deviceId, callback) {
    setisbusy(true);
    httpService.post(`/v1/ipop/${deviceId}/remotecommand/tw`,
    cmd).then(response => {
        const { data } = response;
        // callback({ isError: false, message: data })
        //setcommandResponse({...data});

        ModalAnt.success({ centered:true,title: "Command Sent", content: <>
        
        <p>{"Command successfully completed !"}</p>
        <p style={{color:"green"}}>{data?.message}</p>
        </> })
        setisbusy(false);
      }).catch(err => {
        setisbusy(false);
        // callback({ isError: true, message: err?.response?.data || err?.message })
        ModalAnt.warning({ centered:true, title: "Command Failed", content: <p>{JSON.stringify(err?.response?.data || err?.message)}</p> })
      })
  }
  const [isbusy, setisbusy] = useState(false);
  const [jsonRowPaylaod, setjsonRowPaylaod] = useState({"method":"PDU_CTRL","params":{"P1":1}});
  const [comamndType, setcomamndType] = useState(1)

  return(<>
     <div  style={{margin:"1px", width:"100%"}}>
     <p>Full Command Payload : {JSON.stringify(jsonRowPaylaod)}</p>
        <Input style={{marginBottom:"10px"}} placeholder='Methode Name' defaultValue={jsonRowPaylaod.method} onChange={e=> setjsonRowPaylaod(old=>({...old, method:e.target.value}))}></Input>
        <JSONInput
        id          = 'a_unique_id'
        placeholder = { jsonRowPaylaod.params }
        height="100px"
        locale      = { locale }
        width="100%"
            onChange={e=> setjsonRowPaylaod(old=>({...old, params:e.jsObject}))}
        
    />
    <Radio.Group style={{marginTop:"10px"}} defaultValue={1} onChange={e=> setcomamndType(e.target.value)} value={comamndType}>
      <Radio value={1}>Normal</Radio>
      <Radio value={3}>Normal With  2 Retry</Radio>   
      <Radio value={2}>Two Way</Radio>     
      
    </Radio.Group>
   
       <Button disabled={isbusy} onClick={()=> {
        if(comamndType===1)
        senRemoteSystemCommand(jsonRowPaylaod,deviceId)
        if(comamndType===2)
        senRemoteSystemCommandTW(jsonRowPaylaod,deviceId)
        if(comamndType===3)
        senRemoteSystemCommand({"timeout":300000,persistent:true,retries:2,...jsonRowPaylaod},deviceId)
        
       }} style={{marginTop:"10px"}} size="sm" variant="dark"><Command/> Send Command</Button>
     
        </div>
    </>)
}

export function SendCustomCommandV2({comamndHanlder, disabled,isbusy}){

  


  const [jsonRowPaylaod, setjsonRowPaylaod] = useState({"method":"PDU_CTRL","params":{"P1":1}});
  const [comamndType, setcomamndType] = useState(1)

  return(<>
     <div  style={{margin:"1px", width:"100%"}}>
     <p>Full Command Payload : {JSON.stringify(jsonRowPaylaod)}</p>
        <Input style={{marginBottom:"10px"}} placeholder='Methode Name' defaultValue={jsonRowPaylaod.method} onChange={e=> setjsonRowPaylaod(old=>({...old, method:e.target.value}))}></Input>
        <JSONInput
        id          = 'a_unique_id'
        placeholder = { jsonRowPaylaod.params }
        height="100px"
        locale      = { locale }
        width="100%"
            onChange={e=> setjsonRowPaylaod(old=>({...old, params:e.jsObject}))}
        
    />
    <Radio.Group style={{marginTop:"10px"}} defaultValue={1} onChange={e=> setcomamndType(e.target.value)} value={comamndType}>
      <Radio value={1}>Normal</Radio>
      <Radio value={3}>Normal With  2 Retry</Radio>   
      <Radio value={2}>Two Way</Radio>     
      
    </Radio.Group>
   
       <Button disabled={isbusy} onClick={()=> {
        if(comamndType===1)
        comamndHanlder(jsonRowPaylaod)
        if(comamndType===2)
        comamndHanlder(jsonRowPaylaod)
        if(comamndType===3)
        comamndHanlder({"timeout":300000,persistent:true,retries:2,...jsonRowPaylaod})
        
       }} style={{marginTop:"10px"}} size="sm" variant="dark"><Command/> Send Command</Button>
     
        </div>
    </>)
}

export function MyiPoPSSH(props) {

  var serverUrl;

  const [showModal, setshowModal] = useState(false);

  const [deviceAttributes, setdeviceAttributes] = useState({ active: [[0, "true"]] })

  const [payloadData, setpayloadData] = useState({});

  const handleNewData = (data) => {

    var myResponse = JSON.parse(data);
    if (myResponse?.subscriptionId === 1) {

      setpayloadData(old => ({ ...old, ...myResponse }));
    }

    if (myResponse?.subscriptionId === 9) {
      setdeviceAttributes(old => ({ ...old, ...myResponse?.data }));
    }

    // if(myResponse?.cmdId===3)
    // {
    //   if(myResponse?.data)
    //   setalarms(myResponse?.data?.data);

    //   if(myResponse?.update)
    //   setalarms(myResponse?.update);
    // }

  }

  var object = {
    tsSubCmds: [
      {
        entityType: "DEVICE",
        entityId: props?.value,
        cmdId: "1",

      },
    ],

    // "attrSubCmds":[{"entityType":"DEVICE","entityId":props.value,"scope":"SERVER_SCOPE","cmdId":9}],
    attrSubCmds: [
      {
        entityType: "DEVICE",
        entityId: props?.value,
        cmdId: 9,
        keys: "active,commands,inactivityAlarmTime,lastConnectTime,lastDisconnectTime",
      },
    ]


  };

  function closeAllInformation() {

    setshowModal(false);;
  }

  function GetWsInformation() {
    httpService.get('/v1/ipop/ws/details').then(res => {
      const { data } = res;
      const server = `${data.ws}/api/ws/plugins/telemetry?token=${data?.token}`;


      serverUrl = data?.serverApi;

      connectWS(server, () => { ModalAnt.warning({ title: "Connection lost", content: <p>Connection to the stream has been lost.</p> }); closeAllInformation() }, handleNewData, object);
    }).catch(err => {
      ModalAnt.warning({ title: "Connection Failed", content: <p>Failed to get connection information</p> })
    })
  }

  function senRemoteSystemCommand(cmd, deviceId, callback) {

    httpService.post(`/v1/ipop/${deviceId}/remotecommand/tw`,
      { "method": "remoteCommand", "params": { "cmd": cmd, "pin": "jadeMotema2021!TigerBrand!9" } }).then(response => {
        const { data } = response;
        callback({ isError: false, message: data })
        //setcommandResponse({...data});
      }).catch(err => {
        callback({ isError: true, message: err?.response?.data || err?.message })
        ModalAnt.warning({ title: "Command Failed", content: <p>{JSON.stringify(err?.response?.data || err?.message)}</p> })
      })
  }

  function sendRemoteSSHCommand(cmd, deviceId, callback) {

    httpService.post(`/v1/ipop/${deviceId}/normalcommand/tw`,
      { "method": cmd, "params": { "cmd": cmd, "pin": "jadeMotema2021!TigerBrand!9" } }).then(response => {
        const { data } = response;
        callback({ isError: false, message: data })
        //setcommandResponse({...data});
      }).catch(err => {
        callback({ isError: true, message: err?.response?.data || err?.message })
        ModalAnt.warning({ title: "Command Failed", content: <p>{JSON.stringify(err?.response?.data || err?.message)}</p> })
      })
  }




  return (<>

    <Button style={{ margin: 2 }} size="sm" onClick={e => {
      setshowModal(true);
      GetWsInformation();
    }} variant='dark'><TerminalFill /> </Button>
    <ModalAnt

      forceRender={true}
      footer={[]}
      width={1000}
      okType="success"
      title={`iPoPs - ${props.label}`}
      visible={showModal}
      onOk={() => {

      }}
      onCancel={() => { closeAllInformation() }}
    >
      {/*  */}
      {/* <Button size='sm' style={{margin:"10px"}} onClick={()=>{
        sendRemoteSSHCommand("remoteSSH", props?.value,()=>{})
      }} variant='dark'>SSH Session</Button> */}

      {deviceAttributes?.active[0][1] !== "true" && <h4 style={{ color: "red", textAlign: "center" }}>The controller is offline , you can not run a session on it !</h4>}
      {deviceAttributes?.active[0][1] === "true" && <div
        style={{
          display: "flex",
          justifyContent: "center",
          // height: "100vh"
        }}

      >

        <Terminal
          startState={"maximised"}
          color='green'
          backgroundColor='black'
          barColor='black'
          style={{ fontWeight: "bold", fontSize: "1em",height: "100vh" }}
          commandPassThrough={(e, print) => {
            let cmd = "";
            e?.map(emap => cmd += `${emap} `)
            senRemoteSystemCommand(cmd, props?.value, (rs) => print((rs?.message?.message)));


          }}
          // commands={{

          //   // popup: () => alert('Terminal in React'),
          //   print:{
          //     method:(args, print, senRemoteSystemCommand )=>{
          //       alert(`ping -c ${args.time} ${args?.ip} ${props?.value}`);

          //       senRemoteSystemCommand(`ping -c ${args.time} ${args?.ip}`, props?.value,(rs)=> print(rs?.message?.message));
          //     },
          //     options: [               
          //       {
          //         name: 'ip',
          //         description: 'target ip address',
          //         defaultValue: '192.168.4.1',
          //       },
          //       {
          //         name: 'time',
          //         description: 'time to ping',
          //         defaultValue: 5,
          //       },
          //     ],
          //   }
          // }}
          // descriptions={{
          //   // 'ping -c 10':"Ping connectivity result",
          //   // 'open-google': 'opens google.com',
          //   // showmsg: 'shows a message',
          //   // alert: 'alert', popup: 'alert'
          // }}
          msg='You can write linux command here.'
        />
      </div>}






    </ModalAnt>
  </>)
}

