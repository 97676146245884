import {
  DatePicker,
  Form,
  Modal,
  Input,
  Radio,
  AutoComplete,
  Descriptions,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Container, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { GridLoader } from "react-spinners";
import Header from "../../Components/Header";
import HeaderTitle from "../../Components/HeaderTitle";
import httpService from "../../services/httpService";
import { errorMessage, successMessage } from "../../services/utilities";

const { RangePicker } = DatePicker;

function BookAccess({ reload }) {
  const [displayModal, setdisplayModal] = useState(false);
  const [bookingInformation, setbookingInformation] = useState({});
  const [peopleList, setpeopleList] = useState([]);

  function searchForPerson(personName) {
    httpService
      .post("/v1/bookings/visitor/people", { name: personName })
      .then((response) => {
        const { data } = response;
        setpeopleList(
          [...data].map((a) => ({
            value: `${a.name} ${a.surname}`,
            label: `${a.name} ${a.surname}`,
            more: a._id,
          }))
        );
      })
      .catch((error) => {});
  }
  function makeBooking(bookingInformation) {
    if (bookingInformation?.type === "personnelVisit")
      httpService
        .post("/v1/bookings/me/visitor", {
          vistingPerson: bookingInformation?.vistingPerson,
          from: bookingInformation.from,
          to: bookingInformation.to,
          reason: bookingInformation.reason,
        })
        .then((response) => {
          const { data } = response;
          successMessage("Your Booking was successfully !");
          setdisplayModal(false)
          if (reload) reload();
          
        })
        .catch((err) => {
          errorMessage(
            `Failed to make booking (${err?.response?.data || err})`
          );
        });
    else
      httpService
        .post("/v1/bookings/me/tenant", {
          from: bookingInformation.from,
          to: bookingInformation.to,
          reason: bookingInformation.reason,
        })
        .then((response) => {
          const { data } = response;
          successMessage("Your Booking was successfully !");
          setdisplayModal(false)
          if (reload) reload();
        })
        .catch((err) => {
          errorMessage(
            `Failed to make booking (${err?.response?.data || err})`
          );
        });
  }

  return (
    <>
      {!displayModal && (
        <Button onClick={(e) => setdisplayModal(true)} variant="dark" size="sm">
          +
        </Button>
      )}
      {displayModal && (
        <Modal
          onCancel={() => setdisplayModal(false)}
          forceRender={true}
          okType="success"
          width={600}
          title={`New Booking`}
          footer={[<Button size="sm" onClick={e=>makeBooking(bookingInformation)} variant="success">Book</Button>]}
          visible={displayModal}
          
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            layout="horizontal"
          >
            <Form.Item label="Type">
              <Radio.Group
                defaultValue={"tenantVisit"}
                onChange={(e) =>
                  setbookingInformation((old) => ({
                    ...old,
                    type: e.target.value,
                  }))
                }
              >
                <Radio.Button value="personnelVisit">
                  Personnel Visit
                </Radio.Button>
                <Radio.Button value="tenantVisit">Tenant Visit</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {bookingInformation?.type === "personnelVisit" && (
              <Form.Item label="Staffs">
                <AutoComplete
                  options={peopleList}
                  style={{ width: 200 }}
                  onSearch={(e) => {
                    if (e.length > 2) searchForPerson(e);
                  }}
                  onSelect={(value, option) => {
                    setbookingInformation((old) => ({
                      ...old,
                      vistingPerson: option.more,
                    }));
                    console.log(`Selected options .. ${value}`, option);
                  }}
                  placeholder="Staff"
                />
              </Form.Item>
            )}

            <Form.Item label="When">
              <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
                onChange={(e) =>
                  setbookingInformation((old) => ({
                    ...old,
                    from: e[0],
                    to: e[1],
                  }))
                }
                
              />
            </Form.Item>

            <Form.Item label="Comments">
              <Input.TextArea
                onChange={(e) =>
                  setbookingInformation((old) => ({
                    ...old,
                    reason: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

export function VisitDetails({ reload, id, buttonTitle, buttonColor, status }) {
  const [displayModal, setdisplayModal] = useState(false);
  const [bookingInformation, setbookingInformation] = useState({});

  const [proccessQuery, setproccessQuery] = useState(true);

  const [isLoading, setisLoading] = useState(false);

  const loadBookingInformation = () => {
    setisLoading(true);
    httpService
      .get(`/v1/bookings/${id}`)
      .then((respo) => {
        const { data } = respo;
        setbookingInformation({ ...data });
        setisLoading(false);
        setproccessQuery(false);
      })
      .catch((error) => {
        setisLoading(false);
      });
  };

  const bookingStatusColor = () => {
    if (status === "confirmed")
      return <Badge bg="warning"> {status.toUpperCase()}</Badge>;
    else if (status === "active")
      return <Badge bg="success">{status.toUpperCase()}</Badge>;
    else if (status === "expired")
      return <Badge bg="danger">{status.toUpperCase()}</Badge>;
    else return <Badge bg="warning">{status.toUpperCase()}</Badge>;
  };

  const makeSatus = (callBack) => {
    if (status === "confirmed" || status === "booked")
      return (
        <Button
          disabled={proccessQuery}
          size="sm"
          onClick={(e) => approveVisit()}
          style={{ margin: 2 }}
          variant="success"
          key="back"
        >
          Approve
        </Button>
      );
    else return <></>;
  };

  const approveVisit = () => {
    setproccessQuery(true);
    httpService
      .post(`/v1/bookings/${id}/tenant/approve`)
      .then((res) => {
        successMessage("Booking has been approved successfully");
        if (reload) reload();
      })
      .catch((err) => {
        successMessage("Failed to approve booking");
        setproccessQuery(false);
      });
  };

  return (
    <>
      {!displayModal && (
        <Button
          onClick={(e) => {
            setdisplayModal(true);
            loadBookingInformation();
          }}
          variant={buttonColor || "dark"}
          size="sm"
        >
          {buttonTitle}
        </Button>
      )}
      {displayModal && (
        <Modal
          onCancel={() => {
            setdisplayModal(false);
            setbookingInformation({});
          }}
          forceRender={true}
          okType="success"
          okText={makeSatus()}
          width={600}
          title={`Booking Details`}
          visible={displayModal}
          footer={[
            <Button
              style={{ margin: 2 }}
              size="sm"
              onClick={(e) => {
                setdisplayModal(false);
                setbookingInformation({});
                setproccessQuery(false);
              }}
              variant="dark"
              key="back"
            >
              Close
            </Button>,
            makeSatus(),
          ]}
          onOk={() => {}}
        >
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
          {!isLoading && (
            <>
              <Descriptions title="Booking Information">
                <Descriptions.Item label="Booking Status">
                  {bookingStatusColor()}
                </Descriptions.Item>
                <Descriptions.Item label="Booking Date">
                  <p>
                    {" "}
                    {moment(bookingInformation?.creationDate).format(
                      "YYYY-MM-DD HH:mm"
                    )}{" "}
                  </p>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions title="Visitor Information">
                <Descriptions.Item label="Name">
                  {bookingInformation?.person?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Surname">
                  {bookingInformation?.person?.surname}
                </Descriptions.Item>
                <Descriptions.Item label="Company">
                  {bookingInformation?.person?.company?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Signature">
                  {bookingInformation?.signature && (
                    <Image
                      height={150}
                      width={150}
                      fluid
                      src={bookingInformation?.signature}
                    />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Picture">
                  {bookingInformation?.visitorPicture && (
                    <Image
                      roundedCircle
                      height={150}
                      width={150}
                      fluid
                      src={bookingInformation?.visitorPicture}
                    />
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions title="Visit Information">
                <Descriptions.Item label="Visit Note">
                  {bookingInformation?.reason}
                </Descriptions.Item>

                <Descriptions.Item label="Date">
                  {moment(bookingInformation.from).format("YYYY-MM-DD HH:mm")} -{" "}
                  {moment(bookingInformation.to).format("YYYY-MM-DD HH:mm")}
                </Descriptions.Item>
              </Descriptions>
              {bookingInformation?.vistingPerson && (
                <>
                  <Descriptions title="Host Information">
                    <Descriptions.Item label="Name">
                      {bookingInformation?.vistingPerson?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Surname">
                      {bookingInformation?.vistingPerson?.surname}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )}
            </>
          )}
          {/* {JSON.stringify(bookingInformation)} */}
        </Modal>
      )}
    </>
  );
}

export default function Visits() {
  const [myvisits, setmyvisits] = useState();
  const [isLoading, setisloading] = useState(false);
  const loadMyVisits = () => {
    setisloading(true);
    httpService
      .get("/v1/bookings/mine")
      .then((response) => {
        const { data } = response;
        setmyvisits([...data]);
        setisloading(false);
      })
      .catch((error) => {
        errorMessage("Failed to load Visits");
        setisloading(false);
      });
  };
  const displayStatus = (status) => {
    if (status === "expired")
      return <Badge bg="danger">{status.toUpperCase()}</Badge>;
    else if (status === "active") return <Badge bg="success">APPROVED</Badge>;
    else return <Badge bg="warning">{status.toUpperCase()}</Badge>;
  };
  const DisplayPerson = ({ person }) => {
    if (person)
      return (
        <>
          {person.name} {person.surname}
        </>
      );
    else return <div style={{ textAlign: "center" }}>-</div>;
  };

  function cancelBooking(bookingId) {
    httpService
      .post(`/v1/bookings/me/cancel/${bookingId}`)
      .then((response) => {
        successMessage("Your booking as been canceled !");
        loadMyVisits();
      })
      .catch((error) => {
        errorMessage(
          `Failed to cancel your booking ${error?.response?.data || error}`
        );
      });
  }

  useEffect(() => {
    loadMyVisits();
    return () => {};
  }, []);

  return (
    <>
      <Header />
      <HeaderTitle name="My Visits" />
      <Container>
        <div style={{ textAlign: "center" }}>
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
        </div>
        <BookAccess reload={loadMyVisits} />
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[
            {
              name: "Date",
              selector: "creationDate",
              sortable: true,
              cell: (row) => (
                <>{moment(row.creationDate).format("YYYY-MM-DD HH:mm")}</>
              ),
            },
            {
              name: "From",
              selector: "from",
              sortable: true,
              cell: (row) => <>{moment(row.from).format("YYYY-MM-DD HH:mm")}</>,
            },
            {
              name: "To",
              selector: "to",
              sortable: true,
              cell: (row) => <>{moment(row.to).format("YYYY-MM-DD HH:mm")}</>,
            },
            {
              name: "Comments",
              selector: "reason",
              sortable: true,
              cell: (row) => <>{row.reason}</>,
            },

            {
              name: "Visiting",
              selector: "vistingPerson",
              sortable: true,
              cell: (row) => (
                <>
                  <DisplayPerson person={row?.vistingPerson} />
                </>
              ),
            },
            {
              name: "Status",
              selector: "status",
              sortable: true,
              cell: (row) => <>{displayStatus(row.status)}</>,
            },
            {
              name: "Approved By",
              selector: "approvedBy",
              sortable: true,
              cell: (row) => (
                <>
                  <DisplayPerson person={row?.approvedBy} />
                </>
              ),
            },
            {
              name: "",
              cell: (row) => (
                <>
                  <Button
                    onClick={(e) => cancelBooking(row._id)}
                    variant="danger"
                    size="sm"
                  >
                    Cancel
                  </Button>
                </>
              ),
            },
          ]}
          data={myvisits}
        />
      </Container>
    </>
  );
}

function SearchByDateRange({
  onSelectedDate,
  startSearch,
  searchButtonStatus,
}) {
  return (
    <>
      <RangePicker onChange={(e) => onSelectedDate({ from: e[0], to: e[1] })} />{" "}
      <Button
        style={{ marginLeft: 2 }}
        size="sm"
        onClick={(e) => startSearch()}
        variant="dark"
        disabled={searchButtonStatus}
      >
        Search
      </Button>
    </>
  );
}

export function Visitors() {
  const [myvisits, setmyvisits] = useState();
  const [isLoading, setisloading] = useState(false);
  const [searchInfirmation, setsearchInfirmation] = useState({});

  const loadMyVisits = () => {
    setisloading(true);
    httpService
      .get("/v1/bookings")
      .then((response) => {
        const { data } = response;
        setmyvisits([...data]);
        setisloading(false);
      })
      .catch((error) => {
        errorMessage("Failed to load Visits");
        setisloading(false);
      });
  };
  const displayStatus = (status) => {
    if (status === "expired")
      return <Badge bg="danger">{status.toUpperCase()}</Badge>;
    else if (status === "active") return <Badge bg="success">APPROVED</Badge>;
    else return <Badge bg="warning">{status.toUpperCase()}</Badge>;
  };
  const DisplayPerson = ({ person }) => {
    if (person)
      return (
        <>
          {person.name} {person.surname}
        </>
      );
    else return <div style={{ textAlign: "center" }}>-</div>;
  };

  function cancelBooking(bookingId) {
    httpService
      .post(`/v1/bookings/me/cancel/${bookingId}`)
      .then((response) => {
        successMessage("Your booking as been canceled !");
        loadMyVisits();
      })
      .catch((error) => {
        errorMessage(
          `Failed to cancel your booking ${error?.response?.data || error}`
        );
      });
  }

  const searchBookings = () => {
    setisloading(true);
    httpService
      .post("/v1/bookings/search", searchInfirmation)
      .then((res) => {
        const { data } = res;
        setmyvisits([...data]);
        setisloading(false);
      })
      .catch((erro) => {
        setisloading(false);
      });
  };

  useEffect(() => {
    loadMyVisits();
    return () => {};
  }, []);

  return (
    <>
      <Header />
      <HeaderTitle name="Visits" />
      <Container>
        <div style={{ textAlign: "center" }}>
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
        </div>
        <SearchByDateRange
          searchButtonStatus={isLoading}
          onSelectedDate={(e) => setsearchInfirmation({ ...e })}
          startSearch={(e) => searchBookings()}
        />
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[
            {
              name: "Visitor",
              selector: "person",
              sortable: true,
              cell: (row) => (
                <>
                  <DisplayPerson person={row?.person} />
                </>
              ),
            },
            {
              name: "Date",
              selector: "creationDate",
              sortable: true,
              cell: (row) => (
                <>{moment(row.creationDate).format("YYYY-MM-DD HH:mm")}</>
              ),
            },
            {
              name: "From",
              selector: "from",
              sortable: true,
              cell: (row) => <>{moment(row.from).format("YYYY-MM-DD HH:mm")}</>,
            },
            {
              name: "To",
              selector: "to",
              sortable: true,
              cell: (row) => <>{moment(row.to).format("YYYY-MM-DD HH:mm")}</>,
            },
            {
              name: "Comments",
              selector: "reason",
              sortable: true,
              cell: (row) => <>{row.reason}</>,
            },

            {
              name: "Visiting",
              selector: "vistingPerson",
              sortable: true,
              cell: (row) => (
                <>
                  <DisplayPerson person={row?.vistingPerson} />
                </>
              ),
            },
            {
              name: "Status",
              selector: "status",
              sortable: true,
              cell: (row) => <>{displayStatus(row.status)}</>,
            },
            {
              name: "Approved By",
              selector: "approvedBy",
              sortable: true,
              cell: (row) => (
                <>
                  <DisplayPerson person={row?.approvedBy} />
                </>
              ),
            },
            {
              name: "",
              cell: (row) => (
                <>
                  <VisitDetails
                    reload={loadMyVisits}
                    status={row.status}
                    id={row._id}
                    buttonColor="warning"
                    buttonTitle={"Details"}
                    key={row._id}
                  />
                </>
              ),
            },
          ]}
          data={myvisits}
        />
      </Container>
    </>
  );
}
