
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Container, Button, Form, Badge } from 'react-bootstrap'
import { InfoCircle, InfoCircleFill, Plus } from 'react-bootstrap-icons'
import DataTable from 'react-data-table-component'
import { MultiSelect } from 'react-multi-select-component'
import { GridLoader } from 'react-spinners'
import { CustomModalV2 } from '../Components/CustomModal'
import { GenericFormDaterange, GenericFormElement } from '../Components/GenericFormController'
import Header from '../Components/Header'
import HeaderTitle from '../Components/HeaderTitle'
import httpService from '../services/httpService'
import { errorMessage, successMessage } from '../services/utilities'

const fields = [
  { name: "name", title: "Name" }, 
  { name: "fromTime", title: "From Time",type:"time" },
  { name: "toTime", title: "To Time",type:"time"  },
  { name: "active", title: "Active",type:"checkbox" }
]

function CreateAccessSchedule(props) {

  const createUpdate = ()=>{
    if(props.type ==="update")
    {

      var days =  selectedDays.map(a=> a.value);    

      httpService.put(`/v1/accessschedule/${scheduleData._id}`,{
        name:scheduleData.name,
        daysOfWeek:days,
        fromTime:scheduleData.fromTime,
        toTime:scheduleData.toTime,
        active:scheduleData.active
      }).then(res=>{
        const {data} = res;
        successMessage(`Schedule successfully updated`);
         if(props.reload)
         props.reload();
      }).catch(err=>{
        
        errorMessage(`Failed to create schedule, "${err?.response?.data || err}" .`)
      })
    }
    if(props.type ==="create")
    {

      var days =  selectedDays.map(a=> a.value);

      httpService.post('/v1/accessschedule',{
        ...scheduleData,
        daysOfWeek:days
      }).then(res=>{
        const {data} = res;
        successMessage(`Schedule successfully created`);
         if(props.reload)
         props.reload();
      }).catch(err=>{
        
        errorMessage(`Failed to create schedule, "${err?.response?.data || err}" .`)
      })
    }
  }

  const [scheduleData, setscheduleData] = useState({ name: "",...props.values });
  const [optionsDays, setoptionsDays] = useState([
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ]);
  const [selectedDays, setselectedDays] = useState(props?.values?.daysOfWeek?.map(a=> ({value:a})) || []);
  const [dateRange, setdateRange] = useState([new Date(),new Date()]);

  return (<>
    <CustomModalV2 centered={false} size="md" proccess={createUpdate} canProccess={true} proccessTitle={props.type || "Create"} title={props.title || `Create Access Schedule`} mainComponents={<Button variant={props.variant || 'dark'} size='sm' > {props.icon || <Plus />} </Button>}>
    <Form.Label>Days of the week</Form.Label>
      <MultiSelect
        options={optionsDays}
        value={selectedDays}
        onChange={setselectedDays}
        labelledBy="Select"
      />
      {
        fields.map(a => (<GenericFormElement {...a} value={scheduleData[a.name]} handleChange={(e) => {         
          if(a.type==="checkbox")
          setscheduleData(old => ({ ...old, [a.name]:e.target.checked}))
          else
          setscheduleData(old => ({ ...old, [e.target.name]: e.target.value }))
        
        }} />))
      }  
    </ CustomModalV2>
  </>)
}

export default function AccessSchedule() {

  const [schedules, setschedules] = useState([]);

  const [isLoading, setisLoading] = useState(false)

  const schedulesTableConfigs =
    [
      { sortable: true, name: "Creation Date", selector: "date", cell: (row) => moment(row.creationDate).format('MMMM Do YYYY, h:mm:ss a') },
      { sortable: true, name: "Name", selector: (row) => row.active, cell:(row) => <>
       {row?.active && <Badge bg="success">Active</Badge>}
       {!row?.active && <Badge bg="danger">Inactive</Badge>}
      </> },  
      { sortable: true, name: "Name", selector: (row) => row.name, cell:(row) => <>{row.name}</> },  
      { sortable: false, name: "Time", selector: (row) => row.name, cell:(row) => <>{row.fromTime} - {row.toTime}</> },    
      { sortable: true, name: "Days", selector: (row) => row.daysOfWeek, cell:(row)=> <p>{row.daysOfWeek.map(a=> <Badge style={{margin:1}} bg='dark'>{a}</Badge>)}</p> },
      { sortable: true, name: "Days", selector: (row) => row.daysOfWeek, cell:(row)=> <><CreateAccessSchedule values={row} reload={loadSchedules} title='Update Access Schedule' type={"update"} variant='warning' icon={<InfoCircleFill/>} /></> },
    ]

  const loadSchedules = ()=>{
    setisLoading(true);
    httpService.get('/v1/accessschedule').then(a=>{
      const {data} = a;
      setschedules([...data]);
      setisLoading(false);
    }).catch(er=>{
      setisLoading(false);
    })
    
  }

  useEffect(() => {
    loadSchedules();
  }, [])

  return (
    <>
      <Header />
      <HeaderTitle name="Access Schedule" />
      <Container>
        <CreateAccessSchedule reload={loadSchedules} type={"create"} />
        <div style={{textAlign:"center"}}>
     <GridLoader  margin={10} loading={isLoading} size={30} color="black" />
     </div>
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={schedulesTableConfigs}
          data={schedules}
        />
      </Container>
    </>
  )
}
