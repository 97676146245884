import { Collapse, Input, Modal, Select,Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button, Container, Table } from 'react-bootstrap'
import { InfoCircle } from 'react-bootstrap-icons'
import { GridLoader } from 'react-spinners'
import Header from '../Components/Header'
import HeaderTitle from '../Components/HeaderTitle'
import httpService from '../services/httpService'
import { errorMessage } from '../services/utilities'


const competenciesTypes = [
    {value:"Induction",label:"Induction"},
    {value:"Medical",label:"Medical"},
    {value:"Driving Licence",label:"Driving Licence"},
    {value:"PD Licence",label:"PD Licence"},
]

export default function AccessGroup() {

    const [accessGroupList, setaccessGroupList] = useState([]);
    const [locations, setlocations] = useState([])
    const [schedules, setschedules] = useState([])
    const [competencies, setcompetencies] = useState([]);
    const [izones, setizones] = useState([]);   
    const [accessStrategies, setaccessStrategies] = useState([]);    
    const [islaoding, setislaoding] = useState(false);
    const [iPoPs, setiPoPs] = useState([])

    const load = ()=>{
        setislaoding(true);
        httpService.get("/v1/accessgroup").then(response=>{
            const {data} = response;
            setaccessGroupList([...data.group]);
            setlocations([...data.locations]);
            setschedules([...data.schedule]);
            setcompetencies([...data.competencies])
            setizones([...data.izones])
            setislaoding(false);
            setaccessStrategies([...data.strategies])
            setiPoPs([...data.ipops])
        }).catch(err=>{setislaoding(false)})
    }

    const createOrUpdate = (data) =>{

        if(!data?._id)
        {
            httpService.post('/v1/accessgroup',{
                name:data.name,
                accessLocation:data.accessLocation,
                accessSchedules:data.accessSchedules,
                competencies:data.competencies,
                izonesCommandsAccess:data.izonesCommandsAccess,
                accessStrategy:data?.accessStrategy,
                ipopDevices:data?.ipopDevices
            }).then(responose=>{load()})
              .catch(error=>{errorMessage(`Failed to create group (${error})`)})
        }
        else
        {
            httpService.put(`/v1/accessgroup/${data?._id}`,{
                name:data.name,
                accessLocation:data.accessLocation,
                accessSchedules:data.accessSchedules,
                competencies:data.competencies,
                izonesCommandsAccess:data.izonesCommandsAccess,
                accessStrategy:data?.accessStrategy,
                ipopDevices:data?.ipopDevices
            }).then(responose=>{load()})
              .catch(error=>{errorMessage(`Failed to update group (${error})`)})
        }
    }

    const updateInputs = (data, setdata,value,index,path) =>{
        let temp = [...data];
        temp[index] = { ...temp[index], [path]: value };
        setdata(temp);
    }

    useEffect(() => {     
    
        load();
    }, [])
    

  return (
    <>
    <Header />
    <HeaderTitle name="Access Group" />
    <Container>
   
    <
    
   >
       <Button variant="dark" size="sm" onClick={(e)=> setaccessGroupList(old=>([...old,{name:"",accessLocation:[],accessSchedules:[]}]))}>+</Button>
       {
                   <div style={{ textAlign: "center" }}>
                   <GridLoader margin={10} loading={islaoding} size={30} color="black" />
                   </div>
              }

              
       <Table striped bordered hover size="sm" responsive={true}>
           <thead>
               <th >Name</th>              
               <th>Locations</th>
               <th>Access Schedule</th>
               <th>Competencies</th>
               <th>iZones CA</th>
               <th>Access Strategy</th>
               <th>iPoP Controllers</th>
               <th></th>
           </thead>
           <tbody>
              
               {accessGroupList?.map((accessvalue,index)=>(<>
               <tr>
                   <td style={{width:'100%'}}> <Input onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e.target.value,index,"name")}}  style={{width:200}}   value={accessvalue?.name}/></td>
                   <td>
                       <Select value={accessvalue?.accessLocation} onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e,index,"accessLocation")}} mode='tags' style={{width:"100%"}}>
                           {locations?.map(location=>(<Select.Option value={location._id}>{location.name}</Select.Option>))}
                       </Select>
                   </td>
                   <td>
                   <Select value={accessvalue?.accessSchedules} onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e,index,"accessSchedules")}} mode='tags' style={{width:"100%"}}>
                           {schedules?.map(schedule=>(<Select.Option value={schedule._id}>{schedule.name}</Select.Option>))}
                       </Select>
                   </td>
                   <td>
                   <Select value={accessvalue?.competencies} onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e,index,"competencies")}} mode='tags' style={{width:"100%"}}>
                           {competencies?.map(schedule=>(<Select.Option value={schedule.value}>{schedule.label}</Select.Option>))}
                       </Select>
                   </td>

                   <td>
                   <Select value={accessvalue?.izonesCommandsAccess} onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e,index,"izonesCommandsAccess")}} mode='tags' style={{width:"100%"}}>
                           {izones?.map(schedule=>(<Select.Option value={schedule._id}>{schedule.name}</Select.Option>))}
                       </Select>
                   </td>

                   <td>
                   <Select value={accessvalue?.accessStrategy} onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e,index,"accessStrategy")}}  style={{width:"100%"}}>
                           {accessStrategies?.map(schedule=>(<Select.Option value={schedule._id}>{schedule.name}</Select.Option>))}
                       </Select>
                   </td>
                   <td>  
                   <Select value={accessvalue?.ipopDevices} onChange={e=> {updateInputs(accessGroupList,setaccessGroupList,e,index,"ipopDevices")}} mode='tags' style={{width:"100%"}}>
                           {iPoPs?.map(schedule=>(<Select.Option value={schedule.value}>{schedule.label}</Select.Option>))}
                       </Select>
                       
                   </td>
                   
                   <td>  
                       {!accessvalue?._id && <Button style={{margin:2}} onClick={e=> createOrUpdate(accessvalue)} size="sm" variant="success">+</Button>}                    
                       {accessvalue?._id && <Button style={{margin:2}} onClick={e=> createOrUpdate(accessvalue)} size="sm" variant="warning"><InfoCircle/></Button>}    
                       
                   </td>
               </tr>
               </>))}
               
           </tbody>
       </Table>
          
       </>
    </Container>
    </>
  )
}
