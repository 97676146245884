import React, { useEffect, useState, useRef } from "react";
import GridLayout from "react-grid-layout";
import DeckGL from "@deck.gl/react";
import { LineLayer } from "@deck.gl/layers";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import ReactMapGL, { Source, Layer, Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import { GeoJsonLayer, ArcLayer, IconLayer } from "deck.gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Container, Col, Row, Card, Button, Image, Badge, Table } from "react-bootstrap";
import { Avatar, List, Slider, Switch,Drawer, Collapse,Divider, Modal, Input ,Popover, AutoComplete, Select, Descriptions, Result} from "antd";
import {
    Activity,
    Bell,
    BellFill,
  BellSlashFill,
  Building,
  Gear,
  InfoCircle,
  People,
  Search,
  Sliders,
  ThreeDots,
  ThreeDotsVertical,
} from "react-bootstrap-icons";
import httpService from "../services/httpService";
import { generateSensorLabel, sendToCommand, sendToCommandLegacy } from "../Components/Floor";
import { closeMQTTConnection, connectToMQTTBroker, errorMessage, successMessage } from "../services/utilities";
import { IZoneAdvanceSettings, IZoneTresholds,AlarmSearch,IZoneAdvanceSettingsV2, IZoneTresholdsAdvance, SensorInformationDisplay } from "./IZones";
import { useNavigate } from "react-router-dom";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Liquid } from '@ant-design/plots';
import { useSocketIO, useUser } from "../hooks";
import { LostConnectionMessage } from "../Components/LostConnectionMessage";
const { Panel } = Collapse;

const _ = require("lodash");




const SensorTelemetries =(props)=>{

  const search =()=>{
    httpService.get(``)
  }

  return(<>
  <Modal
        forceRender={true}
        width={1300}
        okType="success"
        title={`Sensor Data`}
        visible={props.show}
        onOk={() => {
          
        }}
        onCancel={() => props.close()}
      >
        
      </Modal>
  </>)
}

function BuildingSearch({buildings,onFound}) {
  const [showAll, setshowAll] = useState(false);

  const [filtereBuilding, setfiltereBuilding] = useState([]);

  const filterSearch = (name) =>{
    if(name.length < 3)
    return;  
    
    var filter = buildings?.filter(a=> a?.name.toLowerCase().includes(name.toLowerCase()));    

    if(filter.length>0)
    {    
      setfiltereBuilding([...filter.map(a=> ({label:a.name, value:a.name}))]);
    }
   
  };

  const filterSearchByAlarmsActive = (alarms) =>{
    var buildingWithAlars =[];

    if(alarms.length === 0)
    {
      setfoundBuildingWithAlarms(buildingWithAlars);
      return buildings?.map(a=>a.name);       
    }

    
    

    buildings?.map(buildingMap=>{
     
      buildingMap?.floors[0]?.izones.map(mapizone=>{

        mapizone?.alarms?.map(mapAlarms=>{
          
          var fullAlarmsTypeLIst = [];

          alarms?.map(alarmsMapFull=>{
            var alarmsMapFullList = alarmsMapFull.split(",");
            alarmsMapFullList?.map(alarmsMapFullListmap=>fullAlarmsTypeLIst.push(alarmsMapFullListmap));
          })

          var findAlarms = fullAlarmsTypeLIst.find(a=> a===mapAlarms.name && mapAlarms.status==="alarm");
          if(findAlarms)
          {
            var isthereAlready = buildingWithAlars.find(buildingWithAlarsfind=>buildingWithAlarsfind.buildingName ===buildingMap.name);

            if(!isthereAlready)
            buildingWithAlars.push({buildingName:buildingMap.name,iZone:mapizone?.name, alarmInformation:mapAlarms.label, location:buildingMap.location})
          }
          

        })         
        })
    }
    )
    setfoundBuildingWithAlarms(buildingWithAlars);
    return buildingWithAlars;

  }

  const [foundBuildingWithAlarms, setfoundBuildingWithAlarms] = useState([]);  

  const [availableAlarmsType, setavailableAlarmsType] = useState([
    {value:"PDU 1 OFF,ACTIVE POWER HIGH,AC 1 OFF,ACTIVE POWER LOW",label:"Power Issues"},
    {value:"TARGET OFFLINE TIMEOUT,ETHERNET DOWN",label:"Connectivity Issues"},
    {value:"C,BACK DOOR OPENED,LOCK 1 UNLOCKED,LOCK 2 UNLOCKED",label:"Doors Issues"},
    {value:"TEMPERATURE 1W HIGH,TEMPERATURE 2W HIGH,TOP TEMPERATURE HIGH,BOTTOM TEMPERATURE HIGH",label:"Temperature Issues"},
    {value:"AIRCON OFF,WATER AIRCON,AIRCON ERROR WATER PRESENT,AIRCON NO POWER,AIRCON COMMS ERROR",label:"Aircon Issues"},
  ])

 

  const findBuidlingDetailsAndgo = (selectedBuilding) =>
  {
    var filter = buildings?.find(a=> a?.name ===selectedBuilding);

    if(filter) onFound(filter?.location);
  }
  return (
    <div
      style={{
        flex: 1,
        flexDirection: "row",
        textAlign: "end",
      }}
    >
      {!showAll && (
        <Button
          style={{
            borderRadius: 40,
            margin: 10,
            background: "black",
          }}
          variant="dark"
          onClick={(e) => setshowAll(true)}
        >
          Search <Search />{" "}
        </Button>
      )}
      {showAll && (
        <Card
          style={{
            margin: 10,
            opacity: 0.95,
            background: "black",
            color: "white",
          }}
        >
          <Card.Header>
            <div
              onClick={(e) => {setshowAll(false);setfoundBuildingWithAlarms([])}}
              style={{
                flex: 1,
                flexDirection: "row",
                textAlign: "end",
              }}
            >
              X
            </div>
            <div
              onClick={(e) => setshowAll(false)}
              style={{
                flex: 1,
                flexDirection: "row",
                textAlign: "start",
              }}
            >
              Search
            </div>
            
          </Card.Header>
          <Card.Body>
            
            <Row style={{textAlign:"center",margin:5}}>
              Alarms 
            <Select onChange={e=> {
              var searchAlarms = filterSearchByAlarmsActive(e);
              
            }} style={{width:"100%",marginBottom:5}} mode="tags">
              {availableAlarmsType.map(al=>(<Select.Option value={al.value}>{al.label}</Select.Option>))}             
              
              </Select>
              <p style={{textAlign:"left"}}>
              {foundBuildingWithAlarms?.map(a=> <Badge onDoubleClick={e=> onFound(a.location)} bg="danger" style={{color:"white", margin:2}}> {a.buildingName}</Badge>)}
              </p>
             

              Search For Sites
            <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{
        width: '100%',
      }}
      options={filtereBuilding}
      onSelect={e=> findBuidlingDetailsAndgo(e)}
      onSearch={e=>{filterSearch(e)}}
    >
     
    </AutoComplete>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}

export  function BuildingsMapsV2() {
  const mapRef = useRef(null);

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "80%",
    latitude: -25.7577,
    longitude: 28.4376,
    zoom: 8,
    width: "fit",
  });
  const [mycars, setmycars] = useState([
    { name: "AIR FORCE G60031D", latitude: -23.0, longitude: 27.1, fuel: 0 },
    { name: "AIR FORCE FSC0031D", latitude: -20.9, longitude: 22.36 },
    { name: "AIR FORCE 15C0031D", latitude: -25.2, longitude: 28.84 },
    {
      name: "AIR FORCE 11C0331D",
      latitude: -29.9,
      color: "green",
      longitude: 28,
    },
    { name: "SAA FG49D", latitude: -23.9, color: "green", longitude: 27 },
    {
      name: "AIRCONGO DF3434",
      latitude: -21.459,
      color: "green",
      longitude: 22,
    },
  ]);

  const [displayDetails, setdisplayDetails] = useState(false);

  const [showFuelLevel, setshowFuelLevel] = useState(0);

  const [showDeviceAlarms, setshowDeviceAlarms] = useState(true);

  const [showSensors, setshowSensors] = useState(true);

  const [showBuilding, setshowBuilding] = useState(true);

  const [filterStateShow, setfilterStateShow] = useState(false);

  const [buldingsInfo, setbuldingsInfo] = useState([]);

  const [showSiderDetails, setshowSiderDetails] = useState(false);

  const [selectedBuilding, setselectedBuilding] = useState();

  const [people, setpeople] = useState([]);

  const [filterOffAlarmsDevices, setfilterOffAlarmsDevices] = useState(-1);
  

  const generateFilteredData = () => {
    return mycars
      ?.filter((filterCar) => filterCar?.fuel > showFuelLevel)
      ?.map((carsA) => (
        <Marker latitude={carsA.latitude} longitude={carsA.longitude}>
          {displayDetails && (
            <div
              style={{
                opacity: 0.6,
                borderRadius: 5,
                height: 50,
                background: "black",
              }}
            >
              <p style={{ color: carsA?.color || "red", margin: 10 }}>
                Code: {carsA.name}
                <div> Fuel: {carsA.fuel} %</div>
              </p>
            </div>
          )}
          {carsA.fuel > 50 ? (
            <Image height={60} width={60} fluid src="/building_red.png" />
          ) : (
            <Image height={50} width={50} fluid src="/building_green.png" />
          )}
        </Marker>
      ));
  };
  const generateFilterBuildings = () => {
    return (
      buldingsInfo &&
      buldingsInfo?.filter(filterbuilding=> filterbuilding?.alarmsCount > filterOffAlarmsDevices)?.map((mapBuilding) => (
        <>
          <Marker
            latitude={mapBuilding?.location?.latitude}
            longitude={mapBuilding?.location?.longitude}
          >
            {displayDetails && (
              <div
                style={{
                  opacity: 0.9,
                  borderRadius: 10,
                  height: 50,
                  background: "black",
                }}
              >
                <p style={{ color: mapBuilding?.alarmsCount > 0 ? "red" : "green", margin: 10 }}>
                  {mapBuilding?.name}
                  <div> Alarms: {mapBuilding?.alarmsCount} </div>
                </p>
              </div>
            )}
            <div
              onDoubleClick={() => {
                setselectedBuilding({...mapBuilding});
                setshowSiderDetails(true);
                setViewport((old) => ({
                  ...old,
                  latitude: mapBuilding?.location?.latitude,
                  longitude: mapBuilding?.location?.longitude,
                  zoom: 15,
                }));
              }}
            >
              {mapBuilding?.alarmsCount > 0 ? (
                <Image height={60} width={60} fluid src="/building_red.png" />
              ) : (
                <Image height={50} width={50} fluid src="/building_green.png" />
              )}
            </div>
          </Marker>
        </>
      ))
    );
  };

  const generateSensorFilteredData = () => {
    return mycars
      ?.filter((filterCar) => filterCar?.fuel > showFuelLevel)
      ?.map((carsA) => (
        <Marker latitude={carsA.latitude + 3} longitude={carsA.longitude + 2}>
          {displayDetails && (
            <div
              style={{
                opacity: 0.6,
                borderRadius: "0%",
                height: 50,
                background: "black",
              }}
            >
              <p style={{ color: carsA?.color || "red", margin: 10 }}>
                Code: {carsA.name}
                <div> Fuel: {carsA.fuel} %</div>
              </p>
            </div>
          )}
          {carsA.fuel > 50 ? (
            <Image height={60} width={60} fluid src="/sensor_red.png" />
          ) : (
            <Image height={50} width={50} fluid src="/sensor_green.png" />
          )}
        </Marker>
      ));
  };

  const relaodBasicInformation = () =>{
   
    setbuldingsInfo(old=>[...old]);
    setpeople(old=>[...old])
  }

  const loadBuildings = () => {
    httpService
      .get("/v1/buildinglocation/floors")
      .then((res) => {
        const { data } = res;
        setbuldingsInfo([...data.buildings]);
        setpeople([...data.people]);

        var alarmsList=[];
        console.log('Loading data');
        buldingsInfo?.map((mapBuilding) =>  mapBuilding?.floors?.map((mapfloor) =>  mapfloor?.izones?.map((mapizone) =>  alarmsList.push([...mapizone?.alarms]))));
        
       
        console.log(alarmsList);
        console.log('data laoded');
      })
      .catch((err) => {});
  };

  const loadPeople =(zoneid)=>{

var Mypeople = people?.filter(findpeople=> findpeople?.lastZone ===zoneid );

return (
  <Collapse accordion  >
<Panel   header={<><h6 style={{color:Mypeople.length > 0 ? "red":"green"}}><People/> People</h6></>}>
<p>

{
  people?.filter(findpeople=> findpeople?.lastZone ===zoneid )?.map(mappeople=><>
    <div style={{margin:2}}><Avatar size={40}>{mappeople?.name[0]} {mappeople?.surname[0]}</Avatar> {mappeople?.name} {mappeople?.surname}, {moment(mappeople?.lastActivityTime).fromNow()}</div>
    </>)
}

</p>
</Panel>
</Collapse>
)



  }

  useEffect(() => {
    loadBuildings();
    var myinterval = setInterval(() => {
      relaodBasicInformation();
    }, 120000);

    return () => {
      clearInterval(myinterval);
    };
  }, []);

  useEffect(() => {
    var profile =JSON.parse(localStorage.getItem("profileInfo")) ; 
    var topic =`/v2/${profile?.CID}/buildings`;
    connectToMQTTBroker(
      topic,
        (topc,msg) => {
            if(topc===topic)
            {
              try {
                var payloadInformation = JSON.parse(msg);
                         
                setbuldingsInfo([...payloadInformation.buildings]);
                setpeople([...payloadInformation.people]);

                var alarmsList=[];
                buldingsInfo?.map((mapBuilding) =>  mapBuilding?.floors?.map((mapfloor) =>  mapfloor?.izones?.map((mapizone) =>  alarmsList.push([...mapizone?.alarms]))));
                console.log(alarmsList);
              } catch (error) {
                
              }
            }
           
        },
        "",
        ""
      );
      return () => {
        closeMQTTConnection();
      };
   
  }, [])

  

  

 const [selectizoneAvanceSettings, setselectizoneAvanceSettings] = useState();

 const [selectedSensor, setselectedSensor] = useState();

 const [izoneSelectedAllInfo, setizoneSelectedAllInfo] = useState()
 
 const [searchEvents, setsearchEvents] = useState(false);
 

 const loadizoneAlarmsSettings = (id)=>{

  httpService.get(`/v1/izone/${id}?only=alarms`).then(res=>{
    const {data} =res;
    if(data)
    setselectizoneAvanceSettings({...data})
    else
    setselectizoneAvanceSettings({});
    
  }).catch(err=>{

  })
 }



 function closeiZoneAdvance ()
 {
  setselectediZoneSettings(null);
  setselectizoneAvanceSettings(null);
  setizoneSelectedAllInfo(null);
 }

 function gotoToLocation (latitude, longitude, zoomLevel)
 {
  setViewport((old) => ({
    ...old,
    latitude: latitude,
    longitude: longitude,
    zoom: zoomLevel,
  }));
 }
 
   const [selectediZoneSettings, setselectediZoneSettings] = useState(null);

   const history = useNavigate();

  return (
    <>
      <>
        <div style={{ height: "100%" }}>
          <Container></Container>

          <Header />
          {searchEvents && <AlarmSearch close={()=>setsearchEvents(false) } query={searchEvents} />}
          {selectedSensor && (
            <IZoneTresholds
              close={() => {
                setselectedSensor(null);
              }}
              query={selectedSensor}
            />
          )}
          {selectizoneAvanceSettings && (
            <IZoneAdvanceSettings
              izoneAll={izoneSelectedAllInfo}
              selectediZone={selectizoneAvanceSettings ?? []}
              onClose={closeiZoneAdvance}
              show={selectediZoneSettings}
            />
          )}
          <div style={{height: "100vh" , width: "100%" }}>
            <Drawer
              title="Building Details"
              placement="right"
              onClose={(e) => setshowSiderDetails(false)}
              visible={showSiderDetails}
              width={480}
            >
              <h4 onDoubleClick={(e)=>{ history(`/app/dashboard/${selectedBuilding._id}`)}}>
                {" "}
                <Building  /> {selectedBuilding?.name}
              </h4>

              {buldingsInfo
                ?.find(
                  (findbuilding) => findbuilding._id === selectedBuilding?._id
                )
                ?.floors?.map((mapfloor) => (
                  <>
                    <p>
                      {mapfloor?.name} - {mapfloor?.description}
                      <Collapse>
                        {mapfloor?.izones?.map((mapizones) => (
                          <>
                            <Panel
                              accordion={true}
                              header={
                                <div
                                  style={{
                                    color:
                                      mapizones?.alarms?.filter(
                                        (alarmfilter) =>
                                          alarmfilter.status === "alarm"
                                      )?.length > 0
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {mapizones?.name}
                                </div>
                              }
                              key={mapizones?._id}
                            >
                              <p>
                                <h6
                                  onClick={(e) => {
                                    setizoneSelectedAllInfo(mapizones);
                                    setselectediZoneSettings(mapizones?._id);
                                    loadizoneAlarmsSettings(mapizones?._id);
                                  }}
                                  style={{ fontWeight: "bold" }}
                                >
                                  <Bell /> Notifications
                                </h6>
                                {mapizones?.alarms
                                  ?.filter(
                                    (alarmfilter) =>
                                      alarmfilter.status === "alarm"
                                  )
                                  ?.map((mapalarms) => (
                                    <Popover
                                      content={() => (
                                        <>
                                          <div>
                                          {moment(
                                          mapalarms?.date
                                        ).fromNow()}.
                                            <p> <h4
                                    
                                    style={{ marginRight: 1 }}
                                    bg="danger"
                                  >{mapalarms?.label}</h4>  </p>
                                         
                                          
                                           
                                              <Button onClick={(e)=>{

                                              httpService.post('/v1/izone/clearAlarm',{
                                                deviceId:mapalarms.deviceId,
                                                label:mapalarms.name
                                              }).then(res=>{successMessage("Alarm Cleared!")})
                                                .catch(err=>{errorMessage("Failed to clear message")})

                                              }} size="sm" variant="warning"> <BellSlashFill/></Button>
                                          </div>
                                        </>
                                      )}
                                      title={"Alarm Details"}
                                    >
                                      <Badge
                                    
                                        style={{ marginRight: 1 }}
                                        bg="danger"
                                      >
                                        {mapalarms?.label}
                                      </Badge>
                                    </Popover>
                                  ))}
                              </p>

                              <p>
                                {mapizones?.sensors && (
                                  <>
                                    <h6 style={{ fontWeight: "bold" }}>
                                      <Activity /> Sensors
                                    </h6>
                                    <Table>
                                      <tbody>
                                        {mapizones?.sensors?.map(
                                          (mapsensor) => (
                                            <tr>
                                              <>
                                              
                                                <td
                                                  onClick={(e) => {
                                                    setselectedSensor({
                                                      query: {
                                                        deviceId:
                                                          mapsensor.deviceId,
                                                        keys: mapsensor.name,
                                                      },
                                                      ...mapsensor,
                                                    });
                                                  }}
                                                >
                                                  {mapsensor.label}
                                                </td>
                                                <td>
                                                  {" "}
                                                  {generateSensorLabel(
                                                    mapsensor
                                                  )}
                                                </td>
                                              </>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </p>
                              <p>
                                {mapizones?.commands && (
                                  <>
                                    <h6 style={{ fontWeight: "bold" }}>
                                      <Gear /> Commands
                                    </h6>
                                    <div style={{ margin: 0 }}>
                                      <Table>
                                        <tbody>
                                          {mapizones?.commands?.map((cm) => (
                                            <tr>
                                              <td>{cm.name}</td>

                                              <td>
                                                {cm?.commands?.map((cmd) => (
                                                  <Badge
                                                    as={"button"}
                                                    onClick={(e) => {
                                                      if (cm?.type !== "Legacy")
                                                        sendToCommand(
                                                          cm?.deviceId,
                                                          cmd?.command,
                                                          null,
                                                          cmd?.value,
                                                          {
                                                            name:mapizones.name,
                                                            commmand:`${cmd?.command}/${cmd?.label}`,
                                                            more:cmd
                                                          }
                                                        );
                                                      else {
                                                        sendToCommandLegacy(
                                                          cm?.deviceId,
                                                          cmd?.command,
                                                          null,
                                                          cmd?.value,
                                                          {
                                                            name:mapizones.name,
                                                            commmand:`${cmd?.command}/${cmd?.label}`,

                                                            more:cmd
                                                          }
                                                        );
                                                      }
                                                    }}
                                                    style={{ marginLeft: 5 }}
                                                    size="sm"
                                                    bg="warning"
                                                  >
                                                    {cmd.label}
                                                  </Badge>
                                                ))}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </>
                                )}
                              </p>
                              {loadPeople(mapizones?._id)}
                            </Panel>
                          </>
                        ))}
                      </Collapse>
                      <Divider />
                    </p>
                  </>
                ))}
            </Drawer>

            <ReactMapGL
              {...viewport}
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/outdoors-v11"
              onViewportChange={(viewport) => setViewport(viewport)}
              mapboxApiAccessToken={
                "pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw"
              }
              onClick={(e) => {
                const { features } = e;

                const { clientX, clientY } = e.srcEvent;
              }}
              ref={mapRef}
            >
              {showBuilding && generateFilterBuildings()}
              {showSensors && generateSensorFilteredData()}

              <Row>
                <Col lg={2}></Col>
                <Col></Col>
                <Col></Col>
                <Col>
                <BuildingSearch onFound={e=>{
                 
                 gotoToLocation(e.latitude, e.longitude,18);

                }} buildings={buldingsInfo} />

                  {!filterStateShow && (
                    <>
                      <div
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          textAlign: "end",
                        }}
                      >
                        
                        
                        <Button
                          onClick={(e) => {
                            setfilterStateShow((old) => !old);
                          }}
                          variant="dark"
                          style={{
                            borderRadius: 40,
                            margin: 10,
                            background: "black",
                          }}
                        >
                          {filterStateShow}
                          Filter <Sliders />{" "}
                        </Button>
                      </div>
                    </>
                  )}
                  {filterStateShow && (
                    <Card
                      style={{
                        margin: 10,
                        opacity: 0.9,
                        background: "black",
                        color: "white",
                      }}
                    >
                      <Card.Header>
                        <div
                          onClick={(e) => setfilterStateShow(false)}
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            textAlign: "end",
                          }}
                        >
                          X
                        </div>
                        Filters
                      </Card.Header>
                      <Card.Body>
                        <Row>
                        
                        <Col lg={2}>
                            Search
                            <Switch
                              checked={searchEvents}
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              defaultChecked={searchEvents}
                              onChange={(e) => {
                                setsearchEvents((old) => !old);
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            Site Name
                            <Switch
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                setdisplayDetails((old) => !old);
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            Alarms List
                            <Switch
                              defaultChecked
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                setshowDeviceAlarms((old) => !old);
                              }}
                            />
                          </Col>

                          {/* <Col>
                            Building
                            <Switch
                              defaultChecked
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                setshowBuilding((old) => !old);
                              }}
                            />
                          </Col> */}
                          <Col lg={2}>
                            Alarms Only
                            <Switch
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                               

                                if (e) setfilterOffAlarmsDevices(0);
                                else setfilterOffAlarmsDevices(-1);
                              }}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </ReactMapGL>
          </div>

          {showDeviceAlarms && (
            <div
              style={{
                overflow: "scroll",
                position: "absolute",
                height: "100uh",
                width: 250,
                top: 70,
                bottom: 0,
                margin: 0,
                opacity: 1,
              }}
            >
              {buldingsInfo &&
                buldingsInfo?.map((mapBuilding) =>
                  mapBuilding?.floors?.map((mapfloor) =>
                    mapfloor?.izones?.map((mapizone) =>
                      mapizone?.alarms?.map((mapalarms) => (
                        <>
                          {mapalarms?.status === "alarm" && (
                            <>
                              <Card
                                style={{
                                  margin: 10,
                                  opacity: 0.9,
                                  
                                  maxHeight:300,
                                  background: "black",
                                  color: "white",
                                }}
                                onClick={(e) => {
                                  setViewport((old) => ({
                                    ...old,
                                    latitude: mapBuilding?.location?.latitude,
                                    longitude: mapBuilding?.location?.longitude,
                                    zoom: 18,
                                  }));
                                }}
                              >
                                <Card.Body>
                                  <div style={{ color: "white" }}>
                                    {" "}
                                    <Building /> {mapBuilding.name}
                                    <div style={{color:"grey", fontSize:15}}> {mapfloor?.name}</div>
                                   
                                  </div>

                                  {}
                                  <p>{moment(mapalarms?.date).fromNow()}</p>
                                  <p>
                                    <h6 style={{ color: mapalarms?.type }}>
                                      {mapalarms?.label}
                                    </h6>
                                  </p>
                                </Card.Body>
                              </Card>
                            </>
                          )}
                        </>
                      ))
                    )
                  )
                )}
            </div>
          )}
        </div>
      </>
    </>
  );
}

const DemoLiquid = () => {
  const config = {
    percent: 0.25,
    outline: {
      border: 4,
      distance: 8,
    },
    autoFit:true,
    wave: {
      length: 128,
    },
  };
  return <Liquid {...config} />;
};
export default function BuildingsMaps() {
  const mapRef = useRef(null);

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "80%",
    latitude: -25.7577,
    longitude: 28.4376,
    zoom: 8,
    width: "fit",
  });
  const [mycars, setmycars] = useState([
    { name: "AIR FORCE G60031D", latitude: -23.0, longitude: 27.1, fuel: 0 },
    { name: "AIR FORCE FSC0031D", latitude: -20.9, longitude: 22.36 },
    { name: "AIR FORCE 15C0031D", latitude: -25.2, longitude: 28.84 },
    {
      name: "AIR FORCE 11C0331D",
      latitude: -29.9,
      color: "green",
      longitude: 28,
    },
    { name: "SAA FG49D", latitude: -23.9, color: "green", longitude: 27 },
    {
      name: "AIRCONGO DF3434",
      latitude: -21.459,
      color: "green",
      longitude: 22,
    },
  ]);

  const [displayDetails, setdisplayDetails] = useState(false);

  const [showFuelLevel, setshowFuelLevel] = useState(0);

  const [showDeviceAlarms, setshowDeviceAlarms] = useState(true);

  const [showSensors, setshowSensors] = useState(true);

  const [showBuilding, setshowBuilding] = useState(true);

  const [filterStateShow, setfilterStateShow] = useState(false);

  const [buldingsInfo, setbuldingsInfo] = useState([]);

  const [showSiderDetails, setshowSiderDetails] = useState(false);

  const [selectedBuilding, setselectedBuilding] = useState();

  const [people, setpeople] = useState([]);

  const [filterOffAlarmsDevices, setfilterOffAlarmsDevices] = useState(-1);
  

  const generateFilteredData = () => {
    return mycars
      ?.filter((filterCar) => filterCar?.fuel > showFuelLevel)
      ?.map((carsA) => (
        <Marker latitude={carsA.latitude} longitude={carsA.longitude}>
          {displayDetails && (
            <div
              style={{
                opacity: 0.6,
                borderRadius: 5,
                height: 50,
                background: "black",
              }}
            >
              <p style={{ color: carsA?.color || "red", margin: 10 }}>
                Code: {carsA.name}
                <div> Fuel: {carsA.fuel} %</div>
              </p>
            </div>
          )}
          {carsA.fuel > 50 ? (
            <Image height={60} width={60} fluid src="/building_red.png" />
          ) : (
            <Image height={50} width={50} fluid src="/building_green.png" />
          )}
        </Marker>
      ));
  };
  const generateFilterBuildings = () => {
    return (
      buldingsInfo &&
      buldingsInfo?.filter(filterbuilding=> filterbuilding?.alarmsCount > filterOffAlarmsDevices)?.map((mapBuilding) => (
        <>
          <Marker
            
            latitude={mapBuilding?.location?.latitude}
            longitude={mapBuilding?.location?.longitude}
          >
            {displayDetails && (
              <div
                style={{
                  opacity: 0.9,
                  borderRadius: 10,
                  height: 50,
                  background: "black",
                }}
              >
                <p style={{ color: mapBuilding?.alarmsCount > 0 ? "red" : "green", margin: 10 }}>
                  {mapBuilding?.name}
                  <div> Alarms: {mapBuilding?.alarmsCount} </div>
                </p>
              </div>
            )}
            <div
              title={`${mapBuilding?.name} (${mapBuilding?.alarmsCount})`}
              onDoubleClick={() => {
                setselectedBuilding({...mapBuilding});
                setshowSiderDetails(true);
                setViewport((old) => ({
                  ...old,
                  latitude: mapBuilding?.location?.latitude,
                  longitude: mapBuilding?.location?.longitude,
                  zoom: 15,
                }));
              }}
            >
              {mapBuilding?.alarmsCount > 0 ? (
                <Image height={25} width={25} fluid src="/building_red.png" />
              ) : (
                <Image height={25} width={25} fluid src="/building_green.png" />
              )}
            </div>
          </Marker>
        </>
      ))
    );
  };

  const generateSensorFilteredData = () => {
    return mycars
      ?.filter((filterCar) => filterCar?.fuel > showFuelLevel)
      ?.map((carsA) => (
        <Marker latitude={carsA.latitude + 3} longitude={carsA.longitude + 2}>
          {displayDetails && (
            <div
              style={{
                opacity: 0.6,
                borderRadius: "0%",
                height: 50,
                background: "black",
              }}
            >
              <p style={{ color: carsA?.color || "red", margin: 10 }}>
                Code: {carsA.name}
                <div> Fuel: {carsA.fuel} %</div>
              </p>
            </div>
          )}
          {carsA.fuel > 50 ? (
            <Image height={60} width={60} fluid src="/sensor_red.png" />
          ) : (
            <Image height={50} width={50} fluid src="/sensor_green.png" />
          )}
        </Marker>
      ));
  };

  const relaodBasicInformation = () =>{
   
    setbuldingsInfo(old=>[...old]);
    setpeople(old=>[...old])
  }

  const loadBuildings = () => {
    httpService
      .get("/v1/buildinglocation/floors")
      .then((res) => {
        const { data } = res;
        setbuldingsInfo([...data.buildings]);
        setpeople([...data.people]);

        var alarmsList=[];
      
        data.buildings?.map((mapBuilding) =>  mapBuilding?.floors?.map((mapfloor) =>  mapfloor?.izones?.map((mapizone) => mapizone?.alarms?.map(allalarms=> {
          if(allalarms?.status ==="alarm") alarmsList.push({...allalarms, building:mapBuilding,floor:mapfloor,izone:{name:mapizone.name, description:mapizone.description}})
        })  )));
        
       
        alarmsList = _.orderBy(alarmsList,"date","desc");

        setalarmsDisplayes([...alarmsList])

      })
      .catch((err) => {});
  };

  const loadPeople =(zoneid)=>{

var Mypeople = people?.filter(findpeople=> findpeople?.lastZone ===zoneid );

return (
  <Collapse accordion  >
<Panel   header={<><h6 style={{color:Mypeople.length > 0 ? "red":"green"}}><People/> People</h6></>}>
<p>

{
  people?.filter(findpeople=> findpeople?.lastZone ===zoneid )?.map(mappeople=><>
    <div style={{margin:2}}><Avatar size={40}>{mappeople?.name[0]} {mappeople?.surname[0]}</Avatar> {mappeople?.name} {mappeople?.surname}, {moment(mappeople?.lastActivityTime).fromNow()}</div>
    </>)
}

</p>
</Panel>
</Collapse>
)



  }

  useEffect(() => {
    loadBuildings();
    var myinterval = setInterval(() => {
      relaodBasicInformation();
    }, 120000);

    return () => {
      clearInterval(myinterval);
    };
  }, []);

  // useEffect(() => {
  //   var profile =JSON.parse(localStorage.getItem("profileInfo")) ; 
  //   var topic =`/v2/${profile?.CID}/buildings`;
  //   connectToMQTTBroker(
  //     topic,
  //       (topc,msg) => {
  //           if(topc===topic)
  //           {
  //             try {
  //               var payloadInformation = JSON.parse(msg);
                         
  //               setbuldingsInfo([...payloadInformation.buildings]);
  //               setpeople([...payloadInformation.people]);


  //               var alarmsList=[];
               
  //               payloadInformation.buildings?.map((mapBuilding) =>  mapBuilding?.floors?.map((mapfloor) =>  mapfloor?.izones?.map((mapizone) => mapizone?.alarms?.map(allalarms=> {
  //                 if(allalarms?.status ==="alarm") alarmsList.push({...allalarms, building:mapBuilding,floor:mapfloor,izone:{name:mapizone.name, description:mapizone.description}})
  //               })  )));              
               
               

  //               alarmsList = _.orderBy(alarmsList,"date","desc");
               

  //               setalarmsDisplayes([...alarmsList])
                

  //             } catch (error) {
                
  //             }
  //           }
           
  //       },
  //       "",
  //       ""
  //     );
  //     return () => {
  //       closeMQTTConnection();
  //     };
   
  // }, [])

  


  const [alarmsDisplayes, setalarmsDisplayes] = useState([])

 const [selectizoneAvanceSettings, setselectizoneAvanceSettings] = useState();

 const [selectedSensor, setselectedSensor] = useState();

 const [izoneSelectedAllInfo, setizoneSelectedAllInfo] = useState()
 
 const [searchEvents, setsearchEvents] = useState(false);
 


 const loadizoneAlarmsSettings = (id)=>{

  httpService.get(`/v1/izone/${id}?only=alarms`).then(res=>{
    const {data} =res;
    if(data)
    setselectizoneAvanceSettings({...data})
    else
    setselectizoneAvanceSettings({});
    
  }).catch(err=>{

  })
 }



 function closeiZoneAdvance ()
 {
  setselectediZoneSettings(null);
  setselectizoneAvanceSettings(null);
  setizoneSelectedAllInfo(null);
 }

 function gotoToLocation (latitude, longitude, zoomLevel)
 {
  setViewport((old) => ({
    ...old,
    latitude: latitude,
    longitude: longitude,
    zoom: zoomLevel,
  }));
 }
 
   const [selectediZoneSettings, setselectediZoneSettings] = useState(null);

   const history = useNavigate();

   const layout = [
    { i: "a", x: 0, y: 0, w: 1, h: 2},
    { i: "b", x: 1, y: 0, w: 1, h: 2 },
    { i: "c", x: 4, y: 0, w: 1, h: 2 }
   
  ];

  const [panels, setpanels] = useState([]);



  const handleUpdateFromSocketIOt = (topic, payload)=>{



  console.log(`Payload received ${topic}`,payload);
    
    var profile =JSON.parse(localStorage.getItem("profileInfo")) ; 
    var Thetopic =`/v2/${profile?.CID}/buildings`;

    if(Thetopic===topic)
            {
              try {
                var payloadInformation =payload
                         
                setbuldingsInfo([...payloadInformation.buildings]);
                setpeople([...payloadInformation.people]);


                var alarmsList=[];
               
                payloadInformation.buildings?.map((mapBuilding) =>  mapBuilding?.floors?.map((mapfloor) =>  mapfloor?.izones?.map((mapizone) => mapizone?.alarms?.map(allalarms=> {
                  if(allalarms?.status ==="alarm") alarmsList.push({...allalarms, building:mapBuilding,floor:mapfloor,izone:{name:mapizone.name, description:mapizone.description}})
                })  )));              
               
               

                alarmsList = _.orderBy(alarmsList,"date","desc");
               

                setalarmsDisplayes([...alarmsList])
                

              } catch (error) {
                
              }
            }
  }

  const {getUserProfile} = useUser();

  const  {isConnected}= useSocketIO([`/v2/${getUserProfile()?.CID}/buildings`],handleUpdateFromSocketIOt)


  return (
    <>
      <>
        <div style={{ height: "100%" }}>
          <Container></Container>

          <Header />
    <LostConnectionMessage isConnected={!isConnected}  />

          {searchEvents && (
            <AlarmSearch
              close={() => setsearchEvents(false)}
              query={searchEvents}
            />
          )}
          {selectedSensor && (
            <IZoneTresholds
              close={() => {
                setselectedSensor(null);
              }}
              query={selectedSensor}
            />
          )}
          {selectizoneAvanceSettings && (
            <IZoneAdvanceSettings
              izoneAll={izoneSelectedAllInfo}
              selectediZone={selectizoneAvanceSettings ?? []}
              onClose={closeiZoneAdvance}
              show={selectediZoneSettings}
            />
          )}
          <div style={{ height: "100vh", width: "100%" }}>
            <Drawer
              title="Building Details"
              placement="right"
              onClose={(e) => setshowSiderDetails(false)}
              visible={showSiderDetails}
              width={1200}
            >
              <h4
                onDoubleClick={(e) => {
                  history(`/app/dashboard/${selectedBuilding._id}`);
                }}
              >
                {" "}
                <Building /> {selectedBuilding?.name}
              </h4>

              {buldingsInfo
                ?.find(
                  (findbuilding) => findbuilding._id === selectedBuilding?._id
                )
                ?.floors?.map((mapfloor) => (
                  <>
                    <p>
                      {mapfloor?.name} - {mapfloor?.description}
                      <Collapse>
                        {mapfloor?.izones?.map((mapizones) => (
                          <>
                            <Panel
                              accordion={true}
                              header={
                                <div
                                  style={{
                                    color:
                                      mapizones?.alarms?.filter(
                                        (alarmfilter) =>
                                          alarmfilter.status === "alarm"
                                      )?.length > 0
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {mapizones?.name}
                                </div>
                              }
                              key={mapizones?._id}
                            >
                              <p>
                                <IZoneAdvanceSettingsV2 id={mapizones?._id} />
                                <IZoneTresholdsAdvance
                                  sensors={mapizones?.sensors}
                                />
                                <h6
                                  // onClick={(e) => {
                                  //   setizoneSelectedAllInfo(mapizones);
                                  //   setselectediZoneSettings(mapizones?._id);
                                  //   loadizoneAlarmsSettings(mapizones?._id);
                                  // }}
                                  style={{ fontWeight: "bold" }}
                                >
                                  <Bell /> Notifications
                                </h6>

                                {mapizones?.alarms
                                  ?.filter(
                                    (alarmfilter) =>
                                      alarmfilter.status === "alarm"
                                  )
                                  ?.map((mapalarms) => (
                                    <Popover
                                      content={() => (
                                        <>
                                          <div>
                                            {moment(mapalarms?.date).fromNow()}.
                                            <p>
                                              {" "}
                                              <h4
                                                style={{ marginRight: 1 }}
                                                bg="danger"
                                              >
                                                {mapalarms?.label}
                                              </h4>{" "}
                                            </p>
                                            <Button
                                              onClick={(e) => {
                                                httpService
                                                  .post(
                                                    "/v1/izone/clearAlarm",
                                                    {
                                                      deviceId:
                                                        mapalarms.deviceId,
                                                      label: mapalarms.name,
                                                    }
                                                  )
                                                  .then((res) => {
                                                    successMessage(
                                                      "Alarm Cleared!"
                                                    );
                                                  })
                                                  .catch((err) => {
                                                    errorMessage(
                                                      "Failed to clear message"
                                                    );
                                                  });
                                              }}
                                              size="sm"
                                              variant="warning"
                                            >
                                              {" "}
                                              <BellSlashFill />
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      title={"Alarm Details"}
                                    >
                                      <Badge style={{ margin: 1 }} bg="danger">
                                        {mapalarms?.label}
                                      </Badge>
                                    </Popover>
                                  ))}
                              </p>

                              <p>
                             
                                {mapizones?.sensors && (
                                  <>
                                    <h6 style={{ fontWeight: "bold" }}>
                                      <Activity /> Sensors
                                    </h6>
                                    {/* <Button onClick={e=> setpanels(old=>[...old,{color:"white"}])}>Add Panel</Button>
                                    <Button onClick={e=> setpanels(old=>[...old,{color:"black"}])}>Add Panel</Button>
                                    <Button onClick={e=> setpanels(old=>[...old,{color:"red"}])}>Add Panel</Button> */}
                                    <GridLayout
                                      className="layout"
                                      layout={layout}
                                      cols={12}
                                      rowHeight={20}
                                      width={1200}
                                      onDragStop={e => {
                                        console.log("Draggin:", e)
                                      }}
                                     
                                     
                                      isResizable={false}
                                      isDraggable={false}

                                    >
                                      {/* {panels?.map((panel, index) => <div key={`panel_${index}`} style={{ background: panel.color }}>

                                      </div>)}

                                      <div style={{ background: "red", borderRadius: "50px", width: 50, height: 50 }} key="a">a</div>
                                      <div key="b">
                                        <DemoLiquid />
                                      </div>
                                      <div style={{ background: "green" }} key="c">
                                        <img src="logo.png" style={{ width: "100%", height: "100%" }}></img>
                                      </div> */}
                                      
                                      {mapizones?.titles?.map((title, index) => (
                                        <div
                                          data-grid={{ ...title.data }}
                                          style={{ color: "black", margin: 0, textAlign: "center", height: 150, width: 200, border: '0.2px solid black' }}
                                          key={`title_${index}`}
                                        >
                                          <h6 cli style={{ margin: 0 }}>{title?.title || "Title"}</h6>
                                        </div>
                                      ))}
                                      {mapizones?.sensors?.map((mapsensors, index) => (
                                        <div key={`${index}`}  data-grid={mapsensors?.data} style={{ width: "100%", height: "100%" }}>

                                          <SensorInformationDisplay

                                            onDouble={() => {
                                              setselectedSensor({
                                                query: {
                                                  deviceId: mapsensors.deviceId,
                                                  keys: mapsensors.name,
                                                },
                                                ...mapsensors,
                                              });
                                            }}
                                            {...mapsensors}
                                          >
                                            {generateSensorLabel(mapsensors)}
                                          </SensorInformationDisplay>
                                        </div>
                                      ))}
                                    </GridLayout>
                                    {/* <Row>
                                      {mapizones?.sensors?.map((mapsensors,index) => (
                                        <SensorInformationDisplay
                                        key={`${index}`}
                                          onDouble={() => {
                                            setselectedSensor({
                                              query: {
                                                deviceId: mapsensors.deviceId,
                                                keys: mapsensors.name,
                                              },
                                              ...mapsensors,
                                            });
                                          }}
                                          {...mapsensors}
                                        >
                                          {generateSensorLabel(mapsensors)}
                                        </SensorInformationDisplay>
                                      ))}
                                    </Row> */}
                                  </>
                                )}
                              </p>
                              <p>
                                {mapizones?.commands && (
                                  <>
                                    <h6 style={{ fontWeight: "bold" }}>
                                      <Gear /> Commands
                                    </h6>
                                    <div style={{ margin: 0 }}>
                                      <Descriptions layout="horizontal" bordered>
                                        {mapizones?.commands?.map((cm) => (
                                          <div style={{ textAlign: "center" }}>
                                            <div>{cm.name}</div>

                                            <>
                                              {cm?.commands?.map((cmd) => (
                                                <Badge
                                                  as={"button"}
                                                  onClick={(e) => {
                                                    if (cm?.type != "Legacy")
                                                      sendToCommand(
                                                        cm?.deviceId,
                                                        cmd?.command,
                                                        null,
                                                        cmd?.value,
                                                        {
                                                          name: mapizones.name,
                                                          commmand: `${cmd?.command}/${cmd?.label}`,
                                                          more:cmd
                                                        }
                                                      );
                                                    else {
                                                      sendToCommandLegacy(
                                                        cm?.deviceId,
                                                        cmd?.command,
                                                        null,
                                                        cmd?.value,
                                                        {
                                                          name: mapizones.name,
                                                          commmand: `${cmd?.command}/${cmd?.label}`,
                                                          more:cmd
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  style={{ marginLeft: 5 }}
                                                  size="sm"
                                                  bg="warning"
                                                >
                                                  {cmd.label}
                                                </Badge>
                                              ))}
                                            </>
                                          </div>
                                        ))}
                                      </Descriptions>
                                    </div>
                                  </>
                                )}
                              </p>
                              {loadPeople(mapizones?._id)}
                            </Panel>
                          </>
                        ))}
                      </Collapse>
                      <Divider />
                    </p>
                  </>
                ))}
            </Drawer>

            <ReactMapGL
              {...viewport}
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/outdoors-v11"
              onViewportChange={(viewport) => setViewport(viewport)}
              mapboxApiAccessToken={
                "pk.eyJ1IjoiamFkZW1vdGUiLCJhIjoiY2tpdTE1aDBuMGlyZjMzbXVvYXcya242bCJ9.HQNJONu_BW3EAFYZF7tdHw"
              }
              onClick={(e) => {
                const { features } = e;

                const { clientX, clientY } = e.srcEvent;
              }}
              ref={mapRef}
            >
              {showBuilding && generateFilterBuildings()}
              {showSensors && generateSensorFilteredData()}

              <Row>
                <Col lg={2}></Col>
                <Col></Col>
                <Col></Col>
                <Col>
                  <BuildingSearch
                    onFound={(e) => {
                      gotoToLocation(e.latitude, e.longitude, 18);
                    }}
                    buildings={buldingsInfo}
                  />

                  {!filterStateShow && (
                    <>
                      <div
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          textAlign: "end",
                        }}
                      >
                        <Button
                          onClick={(e) => {
                            setfilterStateShow((old) => !old);
                          }}
                          variant="dark"
                          style={{
                            borderRadius: 40,
                            margin: 10,
                            background: "black",
                          }}
                        >
                          {filterStateShow}
                          Filter <Sliders />{" "}
                        </Button>
                      </div>
                    </>
                  )}
                  {filterStateShow && (
                    <Card
                      style={{
                        margin: 10,
                        opacity: 0.9,
                        background: "black",
                        color: "white",
                      }}
                    >
                      <Card.Header>
                        <div
                          onClick={(e) => setfilterStateShow(false)}
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            textAlign: "end",
                          }}
                        >
                          X
                        </div>
                        Filters
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col lg={2}>
                            Search
                            <Switch
                              checked={searchEvents}
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              defaultChecked={searchEvents}
                              onChange={(e) => {
                                setsearchEvents((old) => !old);
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            Site Name
                            <Switch
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                setdisplayDetails((old) => !old);
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            Alarms List
                            <Switch
                              defaultChecked
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                setshowDeviceAlarms((old) => !old);
                              }}
                            />
                          </Col>

                          {/* <Col>
                            Building
                            <Switch
                              defaultChecked
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                setshowBuilding((old) => !old);
                              }}
                            />
                          </Col> */}
                          <Col lg={2}>
                            Alarms Only
                            <Switch
                              style={{ margin: 5 }}
                              checkedChildren="Show"
                              unCheckedChildren="Hide"
                              onChange={(e) => {
                                if (e) setfilterOffAlarmsDevices(0);
                                else setfilterOffAlarmsDevices(-1);
                              }}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </ReactMapGL>
          </div>

          {showDeviceAlarms && (
            <div
              style={{
                overflow: "scroll",
                position: "absolute",
                height: "100uh",
                width: 250,
                top: 70,
                bottom: 0,
                margin: 0,
                opacity: 1,
              }}
            >
              {alarmsDisplayes &&
                alarmsDisplayes?.map((mapBuilding) => (
                  <Card
                    style={{
                      margin: 10,
                      opacity: 0.9,

                      maxHeight: 300,
                      background: "black",
                      color: "white",
                    }}
                    onClick={(e) => {
                      setViewport((old) => ({
                        ...old,
                        latitude: mapBuilding?.building?.location?.latitude,
                        longitude: mapBuilding?.building?.location?.longitude,
                        zoom: 15,
                      }));
                    }}
                  >
                    <Card.Body>
                      <div style={{ color: "white" }}>
                        {" "}
                        <Building /> {mapBuilding?.building?.name}
                        <div style={{ color: "grey", fontSize: 15 }}>
                          {" "}
                          {mapBuilding?.floor?.name}
                        </div>
                      </div>

                      {}
                      <p>{moment(mapBuilding?.date).fromNow()}</p>
                      <p>
                        <h6 style={{ color: mapBuilding?.type }}>
                          {mapBuilding?.label}
                        </h6>
                      </p>
                    </Card.Body>
                  </Card>
                ))}
              {/* {buldingsInfo &&
                buldingsInfo?.map((mapBuilding) =>
                  mapBuilding?.floors?.map((mapfloor) =>
                    mapfloor?.izones?.map((mapizone) =>
                      mapizone?.alarms?.map((mapalarms) => (
                        <>
                          {mapalarms?.status === "alarm" && (
                            <>
                              <Card
                                style={{
                                  margin: 10,
                                  opacity: 0.9,
                                  
                                  maxHeight:300,
                                  background: "black",
                                  color: "white",
                                }}
                                onClick={(e) => {
                                  setViewport((old) => ({
                                    ...old,
                                    latitude: mapBuilding?.location?.latitude,
                                    longitude: mapBuilding?.location?.longitude,
                                    zoom: 15,
                                  }));
                                }}
                              >
                                <Card.Body>
                                  <div style={{ color: "white" }}>
                                    {" "}
                                    <Building /> {mapBuilding.name}
                                    <div style={{color:"grey", fontSize:15}}> {mapfloor?.name}</div>
                                   
                                  </div>

                                  {}
                                  <p>{moment(mapalarms?.date).fromNow()}</p>
                                  <p>
                                    <h6 style={{ color: mapalarms?.type }}>
                                      {mapalarms?.label}
                                    </h6>
                                  </p>
                                </Card.Body>
                              </Card>
                            </>
                          )}
                        </>
                      ))
                    )
                  )
                )} */}
            </div>
          )}
        </div>
      </>
    </>
  );
}
