import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Image, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import httpService from "../services/httpService";
import VSITConnect, {  SystemSettings, VSITIPOPSV3 } from "./VSITConnect";
import { useCookies } from 'react-cookie';
import { Browser } from "leaflet";
import { Modal, Form as FromAnt, Input, Collapse } from "antd";
import { errorMessage, successMessage } from "../services/utilities";


function UserProfile({ profileInfo, logout }) {
  const [personalInformation, setpersonalInformation] = useState({});

  const [showMoal, setshowMoal] = useState();

  const [loading, setloading] = useState(false);

  const informationLabels = [
    { name: "username", label: "Username", readOnly: true },
    { name: "name", label: "Name", readOnly: true },
    { name: "surname", label: "Surname", readOnly: true },
    { name: "company", label: "Company", readOnly: true }
  ]

  const passWordLabels = [{ name: "currentPassword", label: "Password", type: "password" },
  { name: "newPassword", label: "New Password", type: "password" },
  { name: "confimrPassword", label: "New Password", type: "password" }

  ]

  const laodProfile = () => {
    setloading(true);
    httpService.get('/v1/auth/profile').then(response => {
      const { data } = response;
      setpersonalInformation({ ...data });
      setloading(false);
    }).catch(error => {
      setloading(false);
      setpersonalInformation(null);
    })
  }

  const updateDetails = () => {

  }

  const Updatepassword = () => {
    httpService.post('/v1/auth/changepassword', {
      currentPassword: personalInformation?.currentPassword,
      password: personalInformation?.newPassword,
      passwordConfirmation: personalInformation?.confimrPassword
    }).then(response => {
      successMessage('Password Updated Successfully !');
      setTimeout(() => {
        logout();
      }, 3000);
    }).catch(error => {
      errorMessage(`Failed to update password ${error?.response?.data || error}`)
    })
  }

  return (
    <>
      <Navbar.Text
        onClick={(e) => {
          setshowMoal(true);
          laodProfile();
        }}
      >
        Signed in as: {profileInfo.name} {profileInfo.surname}{" "}
        <a onClick={logout} href="#">
          Logout!
        </a>
      </Navbar.Text>
      <Modal
        forceRender={true}
        okType="success"
        width={450}
        title={`My Profile`}
        visible={showMoal}
        onOk={() => {
          updateDetails();
        }}
        onCancel={() => {
          setshowMoal(false);
          setpersonalInformation({});
        }}
      >
        {!loading && <>
          <FromAnt
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 15 }}
            layout="horizontal"
          >
            <Collapse defaultActiveKey={["1"]}>
              <Collapse.Panel header="Information" key="1">
                {informationLabels?.map((informationlabel) => (
                  <>
                    <FromAnt.Item label={informationlabel?.label}>
                      <Input
                        disabled={informationlabel.readOnly}
                        onChange={(e) =>
                          setpersonalInformation((old) => ({
                            ...old,
                            [informationlabel?.name]: e.target.value,
                          }))
                        }
                        value={personalInformation[informationlabel?.name]}
                      />
                    </FromAnt.Item>
                  </>
                ))}
              </Collapse.Panel>
              <Collapse.Panel header="Password" key="2">
                <FromAnt
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 15 }}
                  layout="horizontal"
                >
                  {passWordLabels?.map((informationlabel) => (
                    <>
                      <FromAnt.Item label={informationlabel?.label}>
                        <Input
                          type={informationlabel?.type}
                          disabled={informationlabel.readOnly}
                          onChange={(e) =>
                            setpersonalInformation((old) => ({
                              ...old,
                              [informationlabel?.name]: e.target.value,
                            }))
                          }
                          value={personalInformation[informationlabel?.name]}
                        />
                      </FromAnt.Item>
                    </>
                  ))}
                  <Button onClick={e => Updatepassword()} variant="dark" style={{ width: "100%" }}>
                    Change Password
                  </Button>
                </FromAnt>
              </Collapse.Panel>
            </Collapse>
          </FromAnt>
        </>}
      </Modal>
    </>
  );
}

export default function Header() {

  const [userProfile, setuserProfile, removeCookie] = useCookies();

  var navigate = useNavigate();

 

  async function loadUnitInfo() {

    try {
      const { data } = await httpService.get('/app/logo');
      setunitInformation({ ...data });
      console.log(data);
    } catch (error) {

    }
  }


  const [profileInfo, setprofileInfo] = useState({});

  const [unitInformation, setunitInformation] = useState();

  const [moreProfileInformation, setmoreProfileInformation] = useState({})

  useEffect(() => {
    loadUnitInfo();
    let profile = localStorage.getItem("profileInfo");
    var moreInformation=  localStorage.getItem("moreProfile");     

   
    try {
      if(moreInformation)
      {
        var modulesAvailables ={};
        var moreInfo = JSON.parse(moreInformation);
  
        moreInfo?.modulesDisplayed?.map(minfo=> modulesAvailables[minfo]=true)
        setmoreProfileInformation({...modulesAvailables});
      }
  
    } catch (error) {
      
    }
    

    
    if (profile) {
      //alert(profile);
      profile = JSON.parse(profile);
      setprofileInfo({ ...profile });
    }
    else
      navigate("/app/login/ready")
  }, []);

  const logout = () => {
    setuserProfile("profile", "", { path: '/' });
    setuserProfile("token", "", { path: '/' });


    localStorage.removeItem("profileInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("jwt_refreshtoken");
    window.location = `${window.API_URL}/auth/logout`;
    //navigate("/app/login");
  }

  function redictToLogout (){
    setuserProfile("profile", "", { path: '/' });
    setuserProfile("token", "", { path: '/' });

    localStorage.removeItem("profileInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("jwt_refreshtoken");
    window.location = `${window.API_URL}/auth/logout?redirect=/app/display`;
  }

  const menuConfig = () => {
    if (profileInfo.userType === "admin")
      return (<>
        {window.DEMO_FEATURES && <>
          {/* <NavLink className="nav-link" to="/app/dashboard/all">
        Dashboard
      </NavLink> */}
        </>}
     
      
        <NavLink className="nav-link" to="/app/buildingsmaps">
          Sites Maps
        </NavLink>

        <NavLink className="nav-link" to="/app/izonesmanager">
         iZones Manager
        </NavLink>

        
        {moreProfileInformation?.activities &&  <NavLink className="nav-link" to="/app/activities">
          Access Activities
        </NavLink>}
        {moreProfileInformation?.bookings  && <NavLink className="nav-link" to="/app/bookings">
          Bookings
        </NavLink>}
        {moreProfileInformation?.people  &&  <NavLink className="nav-link" to="/app/people">
          People
        </NavLink>} 
        
        {moreProfileInformation?.accessGroup  &&   <NavLink className="nav-link" to="/app/accessgroup">
          Access Group
        </NavLink>}
        {moreProfileInformation?.accessSchedules  &&  <NavLink className="nav-link" to="/app/accessschedule">
          Access Schedule
        </NavLink>}
      
        {moreProfileInformation?.companies  &&   <NavLink className="nav-link" to="/app/companies">
          Companies
        </NavLink>}

      {moreProfileInformation?.assets  &&    <NavLink className="nav-link" to="/app/assets">
          Assets
        </NavLink>} 

        {moreProfileInformation?.accessStrategies  &&     <NavLink className="nav-link" to="/app/accessstrategies">
          Access Strategies
        </NavLink>} 
       
        {moreProfileInformation?.izones  &&    <NavLink className="nav-link" to="/app/izones">
          iZones
        </NavLink>} 
       
        {moreProfileInformation?.floors  &&     <NavLink className="nav-link" to="/app/Floors">
          Floors
        </NavLink>} 
      
        {moreProfileInformation?.accessLocations  &&      <NavLink className="nav-link" to="/app/locations">
          Access Locations
        </NavLink>} 
       
        {moreProfileInformation?.buildings  &&       <NavLink className="nav-link" to="/app/buildingsetups">
          Buildings
        </NavLink>} 

         {moreProfileInformation?.schedulers  &&       <NavLink className="nav-link" to="/app/schedulers">
         Schedulers
        </NavLink>} 
       
       
       
        <NavLink className="nav-link" to="/app/visitors">
       Visits
    </NavLink>
    <NavLink className="nav-link" to="/app/notifications">
      My Notifications
    </NavLink>

        {moreProfileInformation?.displays && <Nav.Item>
          <Nav.Link eventKey="display">Displays</Nav.Link>
        </Nav.Item>} 
   
        {moreProfileInformation?.settings  && 
        <NavDropdown bg="dark" title="Settings" id="basic-nav-dropdown">
        <VSITConnect />
        <VSITIPOPSV3 />
        <SystemSettings />
      </NavDropdown>
        }
        
      </>)
    if (profileInfo.userType === "staff")
      return (<>
        {window.DEMO_FEATURES && <>
          <NavLink className="nav-link" to="/app/dashboard/all">
            Dashboard
          </NavLink>
        </>}

        <NavLink className="nav-link" to="/app/buildingsmaps">
          Sites Maps
        </NavLink></>)
if (profileInfo.type === "visitor" || profileInfo.type === "staff")
return (<>
 <>
 <NavLink className="nav-link" to="/app/buildingsmaps">
          Sites
        </NavLink>
    <NavLink className="nav-link" to="/app/visits">
      My Visits
    </NavLink>
    <NavLink className="nav-link" to="/app/notifications">
      My Notifications
    </NavLink>
    
  </>

  </>)

        

    return (<></>);
  }



  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">
            
            {unitInformation && <Image height={30} fluid width={30} src={unitInformation.iconUrl} style={{ margin: 5 }} />} VSIT


          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav onSelect={(selectedKey) => {
              if(selectedKey==="display")
              redictToLogout();
            }} className="me-auto">
              {menuConfig()}

            </Nav>
            <UserProfile profileInfo={profileInfo} logout={logout} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
