import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import httpService from "../../services/httpService";

const { Option } = Select;

const FloorForm = ({ visible, onCreate, onUpdate, initialValues, onCancel }) => {
    const [form] = Form.useForm();
    const [buildings, setBuildings] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {

            if (initialValues)
                form.setFieldsValue({
                    name: initialValues?.name,
                    description: initialValues?.description,
                    buildingLocation: initialValues?.buildingLocation?._id
                })

            setLoading(true);
            httpService
                .get("/v1/buildinglocation/lite")
                .then(res => {
                    setBuildings(res?.data?.map(mapBuildings => ({ id: mapBuildings._id, name: mapBuildings.name })));
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        }
    }, [visible]);

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                if (initialValues) {
                    onUpdate(values);
                } else {
                    onCreate(values);
                }
            })
            .catch(info => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <Modal
            title={initialValues ? "Update Floor" : "Create Floor"}
            visible={visible}
            onOk={handleOk}
            onCancel={onCancel}
        >

            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: "Please input floor name!" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: "Please input floor description!" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="buildingLocation"
                    label="Building"
                    rules={[{ required: true, message: "Please select a building!" }]}
                >
                    <Select loading={loading}>
                        {buildings.map(building => (
                            <Option key={building.id} value={building.id}>
                                {building.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FloorForm;