import React from "react";
import { useState } from "react";
import { Button, Dropdown, Form, Image } from "react-bootstrap";
import { Fingerprint } from "react-bootstrap-icons";
import { CustomModalV2 } from "../Components/CustomModal";
import { GenericSelectForm } from "../Components/GenericFormController";
import httpService from "../services/httpService";
import { errorMessage, successMessage } from "../services/utilities";
export default function BioEnrolement(props) {
  const [biometricDevices, setbiometricDevices] = useState([
    {
      type: "cmid",
      label: "Default Enrolement Device",
      value: "f351b610-9098-11ec-b3e0-f333e26bc764",
      commands: ["start capture", "stop capture","REMOVE USER", "LITE SYNC","full sync"],
    },
  ]);

  const [selectedBiometricDevice, setselectedBiometricDevice] = useState({});
  const [resultInfo, setresultInfo] = useState({});

  const [isWorking, setisWorking] = useState(false);

  const updateSelectedBiometric = (e) => {
    setselectedBiometricDevice({ ...e });
  };

  const submitBiometric = () => {
    var payload = resultInfo;
    payload.FaceImg = "";
    payload["userBio"] = props.bioId;

    httpService
      .post("/v1/api/biometric/update", {
        data: payload,
        type: selectedBiometricDevice.type,
      })
      .then((res) => {
        successMessage("Biometric are been proccessed !");
      })
      .catch((err) => {
        errorMessage(`Biometric submission failed ${err}`);
      });
  };

  const sendCommand = (cmd) => {
    setisWorking(true);
    httpService
      .post("/v1/api/biometrics/commands", {
        controllerId: selectedBiometricDevice.value,
        command: cmd,
        params: {
          name: props?.name,
          surname: props?.surname,
          id: props?._id,
          bioId: props?.bioId,
        },
      })
      .then((res) => {
        const { data } = res;
        setresultInfo({ ...data });
        
        if(data.isSuccess)
        successMessage(`${data.message}`);
        else{
            errorMessage(data.message); 
        }

        setisWorking(false);

      })
      .catch((err) => {
        const { response } = err;
        setisWorking(false);
        if (response?.status === 408) {
          errorMessage("Biometric enrollement timeout, Please try again !");
          return;
        }

        if (response?.status === 409) {
          errorMessage(
            "Biometric device is offline , Please contact your admin !"
          );
          return;
        }

        errorMessage(`Biometric enrollement error ${err}`);

        
      });
  };

  return (
    <>
      <CustomModalV2
        size="md"
        proccess={submitBiometric}
        canProccess={true}
        proccessTitle={false}
        title={`Biometrics - ${props.name} ${props.surname}`}
        mainComponents={
          <Dropdown.Item href="#">
            <Fingerprint /> Biometrics Enrollement
          </Dropdown.Item>
        }
      >
        <GenericSelectForm
          handleChange={updateSelectedBiometric}
          title="Biometric Devices"
          options={biometricDevices}
        />

        <div style={{ textAlign: "center" }}>
          {selectedBiometricDevice?.commands?.map((a) => (
            <>
              <div style={{ margin: 4 }}>
                <Button
                  disabled={isWorking}
                  onClick={() => sendCommand(a.toUpperCase())}
                  style={{ width: "18rem" }}
                  variant="dark"
                >
                  {a.toUpperCase()}
                </Button>
              </div>
            </>
          ))}
        </div>
        {resultInfo.FaceImg && (
          <>
            <div style={{ textAlign: "center" }}>
              <h4>Picture</h4>
              <Image
                width={"200rem"}
                src={`data:image/jpeg;base64,${resultInfo.FaceImg}`}
              />
            </div>{" "}
          </>
        )}
      </CustomModalV2>
    </>
  );
}
