import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { GenericFormElement } from "../Components/GenericFormController";
import httpService from "../services/httpService";
import { errorMessage, successMessage } from "../services/utilities";
import jwt_decode from "jwt-decode";
import {    useNavigate, useParams  } from "react-router-dom";
import { GoogleOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { Key, Microsoft } from "react-bootstrap-icons";
import packageJson from '../../package.json';
import { useContext } from "react";
import { UserContext } from "../context";

const colInfo = [
    {name:"email", title:"Username"},       
    {name:"password", title:"Password" ,type:"password"},     
]


export default function Login() {
  const [userToken, setuserToken] = useCookies(["profile","token"]);

  const params = useParams();
  const {userDetails, setuserDetails} = useContext(UserContext);
  

    let navigate = useNavigate();

    const updateValue = (e) => {
        setloginDetails((oldValue) => ({
        ...oldValue,
        [e.target.name]: e.target.value,
      }));
    };

    const [loginDetails, setloginDetails] = useState({
      email: "",
      password: "",
    });

    const [untInformation, setuntInformation] = useState(null);

    const login = ()=>{
      
      setisBusy(true);
        httpService.post('/v1/auth/login',loginDetails).then(async res=>{
            const {data} = res;
            successMessage('Login was successfull.');
            localStorage.setItem("jwt_token",data.jwt_token);
            localStorage.setItem("jwt_refreshtoken",data.jwt_refreshtoken);
            var decoded = jwt_decode(data.jwt_token);
            localStorage.setItem("profileInfo",JSON.stringify(decoded) );          
           
            
            try {
              var profileInformation = await httpService.get('/v1/auth/profile');
             
              localStorage.setItem("moreProfile",JSON.stringify(profileInformation?.data) );    
             

              setuserDetails({...profileInformation?.data});

              setuntInformation({...profileInformation?.data})
            } catch (error) {
              
            }

            navigate("/");

            setisBusy(false);

        }).catch(err=>{
            if(err?.response)
            {
                const {data} = err?.response;
                if(data)
                errorMessage(`Login failed\n(${data})`)
            }
            
            else
            errorMessage(`Login failed\n(${err})`);

            setisBusy(false);
        })
    }

    useEffect(() => {     
    
      async function loadMorePofileInformation ()
      {

        try {
          var profileInformation = await httpService.get('/v1/auth/profile');
         
          localStorage.setItem("moreProfile",JSON.stringify(profileInformation?.data) );     
          setuserDetails({...profileInformation?.data});
          navigate("/");
        } catch (error) {
          navigate("/");
        }
      }
     

      if(params?.success==="success")
      {        
       
        var getotken = userToken?.token;
        localStorage.setItem("jwt_token",getotken);       
        var decoded = jwt_decode(getotken);
        localStorage.setItem("profileInfo",JSON.stringify(decoded) );

        setuserDetails({...decoded});
        if(!decoded.isActive)
        return navigate("/app/onboarding");

        loadMorePofileInformation();

        
      }
  
     
      

      async function loadUnitInfo ()
      {
    
        try {
          const {data} = await httpService.get('/app/logo');
          setuntInformation({...data});
  
        } catch (error) {
           errorMessage(`Failed to load unit settings`);
        }
      }

      loadUnitInfo();
     
    }, [])
    
    const [isBusy, setisBusy] = useState(false);
  return (
    <>
      <div>
          <Row>
              <Col md={4}></Col>
              <Col md={4}>   <div style={{ textAlign: "center" ,marginTop:100,margin:50 ,alignItems:"center"  }}>
          <Card style={{ borderWidth:2,
          borderColor:"black" }}>
            <Card.Body>
              <Card.Title><h3>Login in to your account</h3> </Card.Title>
              <div style={{textAlign:"center"}} >
                 
                  {untInformation && <div >
                    <Image height={100} width={100} src={untInformation?.iconUrl} style={{margin:5}} />
                    <h5>{untInformation.unitName}</h5>
                  </div>}
              </div>
             
              <div style={{textAlign:"left", margin:20}}>
              <Row>
                 
              {colInfo.map((a) => (
                <GenericFormElement
                  
                  {...a}                  
                  handleChange={updateValue}
                />
              ))}
              </Row>
              <div >
              <Button disabled={isBusy}  onClick={login } style={{marginTop:10, width:"100%"}} variant="dark"><Key/>  <h6 style={{color:'white'}}>Local Login</h6></Button>
             {untInformation?.azureGoogle  &&  <Button  disabled={isBusy}  onClick={e=> window.location=`${window.API_URL}/auth/google` } style={{marginTop:10, width:"100%"}} variant="dark"><GoogleOutlined/> <h6 style={{color:'white'}}>Google</h6></Button>}
             {untInformation?.azureSSO &&  <Button  disabled={isBusy}  onClick={e=> window.location=`${window.API_URL}/auth/azure` } style={{marginTop:10, width:"100%"}} variant="dark"><Microsoft/><h6 style={{color:'white'}}>Microsoft</h6> </Button>
             }
             
               </div>
              
              
              {/* <Button id="feedback-form" style={{marginTop:10, width:"10rem"}} variant="dark">Help  </Button> */}


              
              </div>
            
              <div style={{textAlign:"center"}} >
                  <Image fluid={true} height={100} width={100} src="/logo2.png" />
                  </div>
                    {packageJson?.version}
            </Card.Body>
          </Card>
        </div></Col>
              <Col md={4}></Col>
          </Row>
     
      </div>
    </>
  );
}
