import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import React from 'react'
import { Form } from 'react-bootstrap';
import Select from 'react-select';




export  function GenericFormDaterange(props) {
  return (
    <div>
       <Form>
          <Form.Group controlId={`inputds${props.name}`}>
            <Form.Label>{props.title}</Form.Label>
            <div>
            <DateTimeRangePicker
              onChange={props.setValue}
              value={props.value}
              />
            </div>
           
          </Form.Group>
        </Form>
    </div>
  );
}

export function SearchSujestion(props)
{
  return (<>
  {props?.sugestions?.length > 0 &&<div style={{height:150 , overflowY:"scroll"}}>
  { <h3>Suggestions</h3>}
  {<ul className="autocomplete">
            {props?.sugestions?.map(a=>(<li onClick={(e)=>props?.handleAutoSuggestion(a)}> {a.title} </li>))}
            
              </ul>
              }     

    </div>}
  

 
  </>)
}

<Form.Group className="mb-3" controlId="formBasicCheckbox">
<Form.Check type="checkbox" label="Check me out" />
</Form.Group>




export  function GenericFormElement(props) {
    return (
      < > 
       {props.type!=="checkbox" &&    < >
            <Form.Group key={props.name} controlId={`inputds${props.name}`}>
              <Form.Label>{props.title}</Form.Label>
              <Form.Control  defaultValue={props.defaultValue} as={props.as} value={props.value} type={props.type} placeholder={props.title} disabled={props.disabled}  name={props.name} onChange={props.handleChange} />
            </Form.Group>    
           
            
          </>}       
      

          {props.type==="checkbox" && <>
          <Form.Group key={props.name} controlId={`inputds${props.name}`}>
          <Form.Check type={props.type} checked={props.value} value={props.value} label={props.title} onChange={props.handleChange} />
               </Form.Group>    
          </>}
      </>
    );
  }

  export  function GenericSelectForm(props) {   
    
    return (
      <div>
        
         <Form>
            <Form.Group controlId={`inputds${props.name}`}>
              <Form.Label>{props.title}</Form.Label>
              <Select value={props.value} onChange={props.handleChange} options={props.options} /> 
              {props.children}
            </Form.Group>
          </Form>
      </div>
    );
  }

export default function GenericFormController() {
    return (
        <>
            
        </>
    )
}
