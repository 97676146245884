import { Descriptions } from "antd";
import React, { useRef } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { Bar } from "@ant-design/plots";

const paletteSemanticRed = "#F4664A";
const brandColor = "#5B8FF9";

const ReturnColor = (type) => {
  if (type === "Total Units to be Tested") return "red";
  else if (type === "Total Units not available") return "yellow";
  else if (type === "Total Units Inspected") return "green";
  else if (type === "Total Units Leak Test Passed") return "red";
  else if (type === "Total Units Failed") return "brown";
  else if (type === "Total Faults observed") return "orange";
  else return "green";
};

const DemoBar = () => {
  const data = [
    {
      year: "Total Units to be Tested",
      value: 38,
    },
    {
      year: "Total Units not available",
      value: 52,
    },
    {
      year: "Total Units Inspected",
      value: 61,
    },
    {
      year: "Total Units Leak Test Passed",
      value: 145,
    },
    {
      year: "Total Units Failed",
      value: 48,
    },
  ];
  const config = {
    data,
    xField: "value",
    yField: "year",
    seriesField: "year",
    legend: false,
    conversionTag: {},
  };
  return <Bar {...config} height={500} />;
};

export function SimpleRepport() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <>
        <div ref={componentRef}>
          <div style={{ textAlign: "center" }}>
            <Image src="/header.png" />
          </div>

          <div style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>ROXY 40 QUATERLY LEAK TEST REPORT</h3>
              <h3>West Wits</h3>
              <Image width={250} height={250} src="/mylogo.png" />
            </div>

            <Row>
              <div style={{ margin: 0 }}>
                <Descriptions size="small" bordered title="Inspection">
                  <Descriptions.Item label="Leak Testing Date">
                    30-Sep-22
                  </Descriptions.Item>
                  <Descriptions.Item label="Unit Inspected By ">
                    Motema Jade
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" bordered title="Detailed Inspection">
                  <Descriptions.Item label="Total Units to be Tested">
                    73
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units not available ">
                    3
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Leak Test Passed">
                    70
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Failed ">
                    60
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units to be Tested">
                    73
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units not available ">
                    3
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Leak Test Passed">
                    70
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Failed ">
                    60
                  </Descriptions.Item>
                </Descriptions>
              </div>

              <div style={{ margin: 0 }}>
                <Descriptions
                  layout="horizontals"
                  size="small"
                  bordered
                  title="Summary Report"
                >
                  <Descriptions.Item label="Total Units to be Tested">
                    73
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units not available ">
                    3
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Leak Test Passed">
                    70
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Failed ">
                    60
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units to be Tested">
                    73
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units not available ">
                    3
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Leak Test Passed">
                    70
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Units Failed ">
                    60
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </Row>

            <div style={{ margin: 10, textAlign: "center" }}>
              <DemoBar />
            </div>

            <div style={{ margin: 0, textAlign: "center" }}>
              <Table size="sm">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>SCSR No</th>
                    <th style={{ width: "5%" }}>Serial Number</th>
                    <th style={{ width: "5%" }}>M/Fact Date</th>
                    <th style={{ width: "5%" }}>Unit Inspected</th>
                    <th>Unit Leak Test</th>
                    <th>Unit Damage / Fault Code</th>
                    <th style={{ width: "20%" }}>Damage Reason</th>
                    <th style={{ width: "10%" }}>Quarantine Date</th>
                    <th style={{ width: "10%" }}>Date RTF</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      scsr: "S1",
                      sm: "95874",
                      md: "T04-17",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25,E27",
                      dr: "Fails during stabilization,Fails during stabilization,Fails during stabilization,Fails during stabilization,Fails during stabilization,Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25,E45,T53,A4",
                      dr: "Fails during stabilization,Failed Fix, Failed Open.",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Failed",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization, More Information needed",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                    {
                      scsr: "S2",
                      sm: "95871",
                      md: "T04-47",
                      ui: "Pass",
                      ul: "Pass",
                      dc: "E25",
                      dr: "Fails during stabilization",
                      qd: "1-Oct-22",
                      drtf: "1-Oct-22",
                    },
                  ].map((i) => (
                    <tr>
                      <td>{i.scsr}</td>
                      <td>{i.sm}</td>
                      <td>{i.md}</td>
                      <td>{i.ui}</td>
                      <td>{i.ul}</td>
                      <td>{i.dc}</td>
                      <td>{i.dr}</td>
                      <td>{i.qd}</td>
                      <td>{i.drtf}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <Descriptions size="small" layout="vertical" title="">
              <Descriptions.Item label="Comments">
                <p>
                  Paragraphs are the building blocks of papers. Many students
                  define paragraphs in terms of length: a paragraph is a group
                  of at least five sentences, a paragraph is half a page long,
                  etc. In reality, though, the unity and coherence of ideas
                  among sentences is what constitutes a paragraph. A paragraph
                  is defined as “a group of sentences or a single sentence that
                  forms a unit” (Lunsford and Connors 116). Length and
                  appearance do not determine whether a section in a paper is a
                  paragraph. For instance, in some styles of writing,
                  particularly journalistic styles, a paragraph can be just one
                  sentence long. Ultimately, a paragraph is a sentence or group
                  of sentences that support one main idea. In this handout, we
                  will refer to this as the “controlling idea,” because it
                  controls what happens in the rest of the paragraph
                </p>
              </Descriptions.Item>
            </Descriptions>

            <p style={{ float: "left", textAlign: "center" }}>
              <h6>Unit inspected by</h6>
              <h5>Oldric Louw</h5>
              <h6>2002/09/30</h6>

              <Image width={100} height={100} src="/signature.jpg" />
            </p>

            <p style={{ float: "right", textAlign: "center" }}>
              <h6>Approved</h6>
              <h5>C Grobler</h5>
              <h6>2002/09/31</h6>
              <Image width={100} height={100} src="/signature.jpg" />
            </p>
          </div>
        </div>
      </>
    </>
  );
}
