import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
  Image,
  Form,
  Badge,
  Dropdown,
  Table,
} from "react-bootstrap";
import {
  Box,
  DoorOpen,
  DoorOpenFill,
  Eraser,
  EraserFill,
  GearFill,
  Fingerprint,
  Info,
  InfoCircle,
  InfoCircleFill,
  InfoLg,
  Key,
  PeopleFill,
  Plus,
  Search,
  Camera2,
  Building,
  EnvelopeFill,
} from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import CustomModal, { CustomModalV2 } from "../Components/CustomModal";
import {
  GenericFormElement,
  GenericSelectForm,
} from "../Components/GenericFormController";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import { toast } from "react-toastify";
import moment from "moment";
import {
  errorMessage,
  LoadingComponents,
  modulesAvailables,
  successMessage,
} from "../services/utilities";
import { MultiSelect } from "react-multi-select-component";
import BioEnrolement from "./BioEnrolement";
import CameraCaptureModal from "../Components/CameraCaptureModal";
import { DotLoader, GridLoader } from "react-spinners";
import {
  message,
  Popconfirm,
  Modal as ModalAnt,
  Collapse,
  Input,
  Form as FromAnt,
  Select as SelectAnt,
  Switch,
  Tabs,
  Modal,
  DatePicker,
} from "antd";
import { PictureFilled } from "@ant-design/icons";
import Webcam from "react-webcam";
import AccessSchedule from "./AccessSchedule";
import { Camera } from "react-camera-pro";
import { Icon } from "leaflet";
const { TabPane } = Tabs;
var QRCode = require("qrcode.react");



var modulesReady =modulesAvailables;

//const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;



const SearchBar = (props) => {
  const [value, setvalue] = useState("");
  return (
    <>
      <div style={{ width: "50%" }}>
        <InputGroup className="mb-3">
          <FormControl
            placeholder={props.placeHolder}
            aria-describedby="basic-addon2"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (props.search) props.search(value);
              }
            }}
            onChange={(e) => {
              var value = e.target.value;
              setvalue(value);
              if (value.length > 3) props.search(value);
            }}
          />
          <Button
            variant="dark"
            onClick={(e) => {
              if (props.search) props.search(value);
            }}
            id="button-addon2"
          >
            <Search />
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

const DeleteButton = (props) => {
  // const removeUser =()=>{
  //     httpService.get
  // }
  return (
    <Button style={{ margin: 5 }} size="sm" variant="danger">
      <Eraser />
    </Button>
  );
};

export const CreateNewPerson = (props) => {
  const typeOfPerson = [
    { value: "visitor", label: "Visitor" },
    { value: "staff", label: "Staff" },
    { value: "tenant", label: "Tenant" },
  ];

  const colInfo = [
    { name: "name", title: "Name" },
    { name: "surname", title: "Surname" },
    { name: "idNumber", title: "ID Number" },
    { name: "bioId", title: "Bio Id" },
  ];

  const [personDetails, setpersonDetails] = useState({
    name: "",
    surname: "",
    idNumber: "",
    bioId: "",
    company: "",
    type: "",
  });

  const updateValue = (e) => {
    setpersonDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
  };
  const updateValueSelct = (e, name) => {
    setpersonDetails((oldValue) => ({ ...oldValue, [name]: e.value }));
  };

  const loadLocations = () => {
    httpService
      .get("/v1/LocationsAccess")
      .then((a) => {
        const { data } = a;

        setavailableLocations([
          ...data.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);
      })
      .catch((err) => { });
  };

  const createPerson = () => {
    var newPersonDetails = personDetails;
    newPersonDetails = {
      ...newPersonDetails,
      accessLocation: locations.map((a) => a.value),
    };

    httpService
      .post("/v1/people/", newPersonDetails)
      .then((r) => {
        toast(`${personDetails.name} as been created successfully!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        if (props.reload) props.reload();

        // laodPeople();
      })
      .catch((err) => {
        const { response } = err;
        if (response) {
          toast(`${response.data}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

            type: "error",
          });
        }
      });
  };

  const [companies, setcompanies] = useState([]);

  const [locations, setlocations] = useState([]);

  const [availableLocations, setavailableLocations] = useState([]);

  const laodPeople = () => {
    httpService
      .get("/v1/companies")
      .then((resp) => {
        const { data } = resp;
        var comaniesformated = data.map((e) => {
          return {
            value: e._id,
            label: e.name,
          };
        });
        setcompanies([...comaniesformated]);
      })
      .catch((a) => (err) => { });
  };

  useEffect(() => {
    laodPeople();
  }, []);

  return (
    <>
      <CustomModal
        load={() => loadLocations()}
        canProccess={true}
        proccess={createPerson}
        size="md"
        title="New Person"
        proccessTitle={"Create"}
        icon={<Plus />}
      >
        <Row>
          <Col>
            {colInfo.map((a) => (
              <GenericFormElement {...a} handleChange={updateValue} />
            ))}

            <GenericSelectForm
              title="Companies"
              handleChange={(e) => updateValueSelct(e, "company")}
              options={companies}
            />
            <GenericSelectForm
              title="Person Type"
              handleChange={(e) => updateValueSelct(e, "type")}
              options={typeOfPerson}
            />
            <Form.Label>Locations</Form.Label>
            <MultiSelect
              options={availableLocations}
              value={locations}
              onChange={setlocations}
              labelledBy="Select"
            />
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export const UpdatePerson = (props) => {
  const typeOfPerson = [
    { value: "visitor", label: "Visitor" },
    { value: "staff", label: "Staff" },
    { value: "tenant", label: "Tenant" },
  ];

  const colInfo = [
    { name: "name", title: "Name" },
    { name: "surname", title: "Surname" },
    { name: "idNumber", title: "ID Number" },
    { name: "bioId", title: "Bio Id" },
    { name: "username", title: "Email Address" },
  ];

  const [personDetails, setpersonDetails] = useState({
    name: "",
    surname: "",
    idNumber: "",
    bioId: "",
    company: "",
    type: "",
    username: "",
    companyName: "",
  });

  const updateValue = (e) => {
    setpersonDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
  };
  const updateValueSelct = (e, name) => {
    setpersonDetails((oldValue) => ({ ...oldValue, [name]: e }));
  };
  const loadSchedules = async () => {
    const { data } = await httpService.get("/v1/accessschedule");
    setschedules([
      ...data.map((a) => {
        return { value: a._id, label: a.name };
      }),
    ]);
    return data;
  };

  const [companies, setcompanies] = useState([]);
  const [availableLocations, setavailableLocations] = useState([]);

  const [accessGoup, setaccessGoup] = useState([]);

  const [locations, setlocations] = useState([]);

  const loadLocations = () => {
    httpService
      .get("/v1/LocationsAccess")
      .then((a) => {
        const { data } = a;

        setavailableLocations([
          ...data.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);
      })
      .catch((err) => { });
  };

  const laodPeople = async () => {
    httpService
      .get("/v1/companies")
      .then((resp) => {
        const { data } = resp;
        var comaniesformated = data.map((e) => {
          return {
            value: e._id,
            label: e.name,
            data: e,
          };
        });

        setcompanies([...comaniesformated]);
        loadPersonDetails([...comaniesformated]);
      })
      .catch((a) => (err) => {
        // /loadPersonDetails();
      });
  };

  const loadPersonDetails = (comps) => {
    setisLoading(true);
    httpService
      .get(`/v1/people/${props.id}`)
      .then((a) => {
        const { data } = a;

        setlocations([
          ...data.accessLocation.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);

        setpictue(data.picture);

        setselectedSchedules([
          ...data.accessSchedules.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);

        var tempData = data;

        tempData["userType"] = {
          label: data.userType.toUpperCase(),
          value: data.userType,
        };

        tempData.type = typeOfPerson.find((a) => a.value === data.type);
        if (data.company)
          tempData.companieSelected = comps.find(
            (a) => a.value === data.company._id
          );

        setpersonDetails({ ...tempData });

        setisLoading(false);
        //laodPeople();
      })
      .catch((a) => {
        setisLoading(false);
      });
  };

  const updateInfo = () => {
    httpService
      .put(`/v1/people/${props.id}`, {
        type: personDetails?.type.value,
        name: personDetails?.name,
        surname: personDetails?.surname,
        idNumber: personDetails?.idNumber,
        company: personDetails?.companieSelected?.value,
        bioId: personDetails?.bioId,
        username: personDetails.username,
        userType: personDetails?.userType.value,
        isActive: personDetails?.isActive,
        accessLocation: locations.map((a) => a.value),
        accessSchedules: selectedSchedules.map((a) => a.value),
        picture: picture,
      })
      .then((res) => {
        const { data } = res;
        toast(`${personDetails.name} as been updated successfully!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        if (props.reload) props.reload();
      })
      .catch((e) => {
        if (e.response) {
          toast(`${e.response.data}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

            type: "error",
          });
        }
      });
  };

  const [schedules, setschedules] = useState([]);
  const [selectedSchedules, setselectedSchedules] = useState([]);

  const [picture, setpictue] = useState();

  const [isLoading, setisLoading] = useState(true);

  const [confirmUserInactivity, setconfirmUserInactivity] = useState(false);

  useEffect(() => {
    // laodPeople();
    loadLocations();
    loadSchedules();
  }, []);

  return (
    <>
      <CustomModalV2
        mainComponents={
          <Dropdown.Item href="#">
            <InfoCircleFill /> Edit Person
          </Dropdown.Item>
        }
        close={() => {
          setpersonDetails({});
        }}
        centered={false}
        canProccess={true}
        load={laodPeople}
        proccess={updateInfo}
        size="lg"
        title="Edit Person"
        proccessTitle={"Update"}
        icon={<InfoLg />}
      >
        {<LoadingComponents isLoading={isLoading} />}
        {personDetails?._id && (
          <Row>
            <div style={{ textAlign: "center" }}>
              <CameraCaptureModal
                centered={true}
                picture={picture}
                setPicture={setpictue}
              />
            </div>

            <Col md={6}>
              {colInfo.map((a) => (
                <GenericFormElement
                  {...a}
                  value={personDetails[a.name]}
                  handleChange={updateValue}
                />
              ))}

              {
                <GenericSelectForm
                  value={personDetails?.userType}
                  title="User Type"
                  handleChange={(e) => updateValueSelct(e, "userType")}
                  options={[
                    { value: "admin", label: "ADMIN" },
                    { value: "reception", label: "RECEPTION" },
                    { value: "user", label: "USER" },
                  ]}
                />
              }

              {personDetails?.companieSelected && (
                <>
                  {personDetails?.companieSelected?.data?.cabinets?.map((c) => (
                    <>
                      <Box /> {c.name} <br />
                    </>
                  ))}
                </>
              )}
            </Col>

            <Col md={6}>
              <GenericSelectForm
                value={personDetails.type}
                title="Person Type"
                handleChange={(e) => updateValueSelct(e, "type")}
                options={typeOfPerson}
              />

              <Form.Label>Locations</Form.Label>
              <MultiSelect
                options={availableLocations}
                value={locations}
                onChange={setlocations}
                labelledBy="Select"
              />

              <Form.Label>Schedules</Form.Label>
              <MultiSelect
                options={schedules}
                value={selectedSchedules}
                onChange={setselectedSchedules}
                labelledBy="Select"
              />

              <GenericSelectForm
                value={personDetails?.companieSelected}
                title="Company"
                handleChange={(e) => updateValueSelct(e, "companieSelected")}
                options={companies}
              />

              <Form.Label>Status</Form.Label>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  onChange={(e) => {
                    if (!e.currentTarget.checked) {
                      setconfirmUserInactivity(true);
                      return;
                    }
                    updateValueSelct(e.currentTarget.checked, "isActive");
                  }}
                  type="checkbox"
                  checked={personDetails.isActive}
                  label="Active"
                />
              </Form.Group>
              <Popconfirm
                onCancel={(e) => {
                  setconfirmUserInactivity(false);
                  setconfirmUserInactivity(false);
                }}
                visible={confirmUserInactivity}
                onConfirm={(e) => {
                  updateValueSelct(false, "isActive");
                  setconfirmUserInactivity(false);
                }}
                placement="bottomLeft"
                title={`Are you sure you want to disable user ${personDetails.name} ${personDetails.surname}? , ${personDetails.name} ${personDetails.surname} will no longer be able to interact with the system.`}
                okText="Yes"
                cancelText="No"
              ></Popconfirm>
            </Col>
          </Row>
        )}
      </CustomModalV2>
    </>
  );
};

export const UpdatePersonV3 = (props) => {
  const typeOfPerson = [
    { value: "visitor", label: "Visitor" },
    { value: "staff", label: "Staff" },
    { value: "tenant", label: "Tenant" },
  ];

  const colInfo = [
    { name: "name", title: "Name" },
    { name: "surname", title: "Surname" },
    { name: "idNumber", title: "ID Number" },
    { name: "bioId", title: "Bio Id" },
    { name: "username", title: "Email Address" },
  ];

  const [personDetails, setpersonDetails] = useState({
    name: "",
    surname: "",
    idNumber: "",
    bioId: "",
    company: "",
    type: "",
    username: "",
    companyName: "",
  });

  const updateValue = (e) => {
    setpersonDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
  };
  const updateValueSelct = (e, name) => {
    setpersonDetails((oldValue) => ({ ...oldValue, [name]: e }));
  };
  const loadSchedules = async () => {
    const { data } = await httpService.get("/v1/accessschedule");
    setschedules([
      ...data.map((a) => {
        return { value: a._id, label: a.name };
      }),
    ]);
    return data;
  };

  const [companies, setcompanies] = useState([]);
  const [availableLocations, setavailableLocations] = useState([]);

  const [locations, setlocations] = useState([]);

  const loadLocations = () => {
    httpService
      .get("/v1/LocationsAccess")
      .then((a) => {
        const { data } = a;

        setavailableLocations([
          ...data.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);
      })
      .catch((err) => { });
  };

  const laodPeople = async () => {
    httpService
      .get("/v1/companies")
      .then((resp) => {
        const { data } = resp;
        var comaniesformated = data.map((e) => {
          return {
            value: e._id,
            label: e.name,
            data: e,
          };
        });

        setcompanies([...comaniesformated]);
        loadPersonDetails([...comaniesformated]);
      })
      .catch((a) => (err) => {
        // /loadPersonDetails();
      });
  };

  const loadPersonDetails = (comps) => {
    setisLoading(true);
    httpService
      .get(`/v1/people/${props.id}`)
      .then((a) => {
        const { data } = a;

        setlocations([
          ...data.accessLocation.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);

        setpictue(data.picture);

        setselectedSchedules([
          ...data.accessSchedules.map((a) => {
            return { value: a._id, label: a.name };
          }),
        ]);

        var tempData = data;
        tempData.type = typeOfPerson.find((a) => a.value === data.type);
        if (data.company)
          tempData.companieSelected = comps.find(
            (a) => a.value === data.company._id
          );

        setpersonDetails({ ...tempData });
        //laodPeople();

        setisLoading(false);
      })
      .catch((a) => {
        setisLoading(false);
      });
  };

  const updateInfo = () => {
    httpService
      .put(`/v1/people/${props.id}`, {
        type: personDetails.type.value,
        name: personDetails.name,
        surname: personDetails.surname,
        idNumber: personDetails.idNumber,
        company: personDetails.companieSelected.value,
        bioId: personDetails.bioId,
        username: personDetails.username,
        accessLocation: locations.map((a) => a.value),
        accessSchedules: selectedSchedules.map((a) => a.value),
        picture: picture,
      })
      .then((res) => {
        const { data } = res;
        toast(`${personDetails.name} as been updated successfully!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        if (props.reload) props.reload();
      })
      .catch((e) => {
        if (e.response) {
          toast(`${e.response.data}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

            type: "error",
          });
        }
      });
  };

  const [schedules, setschedules] = useState([]);
  const [selectedSchedules, setselectedSchedules] = useState([]);

  const [picture, setpictue] = useState();

  useEffect(() => {
    // laodPeople();
  }, [props.id]);

  const [isLoading, setisLoading] = useState(true);

  return (
    <>
      <CustomModalV2
        mainComponents={
          <>
            <>{props?.label}</>
          </>
        }
        centered={false}
        canProccess={true}
        load={() => {
          loadLocations();
          loadSchedules();
          laodPeople();
        }}
        proccess={updateInfo}
        size="lg"
        title="Edit Person"
        proccessTitle={"Update"}
        icon={<InfoLg />}
      >
        {JSON.stringify}
        <Row>
          <div style={{ textAlign: "center" }}>
            <CameraCaptureModal
              centered={true}
              picture={picture}
              setPicture={setpictue}
            />
          </div>

          {<LoadingComponents isLoading={isLoading} />}
          {
            <>
              <Col md={6}>
                {colInfo.map((a) => (
                  <GenericFormElement
                    {...a}
                    value={personDetails[a.name]}
                    handleChange={updateValue}
                  />
                ))}
                {personDetails.companieSelected && (
                  <GenericSelectForm
                    value={personDetails.companieSelected}
                    title="Companies"
                    handleChange={(e) =>
                      updateValueSelct(e, "companieSelected")
                    }
                    options={companies}
                  />
                )}

                {personDetails?.companieSelected && (
                  <>
                    {personDetails?.companieSelected?.data?.cabinets?.map(
                      (c) => (
                        <>
                          <Box /> {c.name} <br />
                        </>
                      )
                    )}
                  </>
                )}
              </Col>

              <Col md={6}>
                <GenericSelectForm
                  value={personDetails.type}
                  title="Person Type"
                  handleChange={(e) => updateValueSelct(e, "type")}
                  options={typeOfPerson}
                />
                <Form.Label>Locations</Form.Label>
                <MultiSelect
                  options={availableLocations}
                  value={locations}
                  onChange={setlocations}
                  labelledBy="Select"
                />

                <Form.Label>Schedules</Form.Label>
                <MultiSelect
                  options={schedules}
                  value={selectedSchedules}
                  onChange={setselectedSchedules}
                  labelledBy="Select"
                />
              </Col>
            </>
          }
        </Row>
      </CustomModalV2>
    </>
  );
};

export const UpdatePersonV2 = (props) => {
  const typeOfPerson = [
    { value: "visitor", label: "Visitor" },
    { value: "staff", label: "Staff" },
    { value: "tenant", label: "Tenant" },
  ];

  const colInfo = [
    { name: "name", title: "Name" },
    { name: "surname", title: "Surname" },
    { name: "idNumber", title: "ID Number" },
    { name: "bioId", title: "Bio Id" },
    { name: "username", title: "Email Address" },
  ];

  const [personDetails, setpersonDetails] = useState({
    name: "",
    surname: "",
    idNumber: "",
    bioId: "",
    company: "",
    type: "",
    username: "",
    companyName: "",
  });

  const updateValue = (e) => {
    setpersonDetails((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
  };
  const updateValueSelct = (e, name) => {
    setpersonDetails((oldValue) => ({ ...oldValue, [name]: e }));
  };

  const [locations, setlocations] = useState([]);

  const [companies, setcompanies] = useState([]);

  const laodPeople = () => {
    httpService
      .get("/v1/companies")
      .then((resp) => {
        const { data } = resp;
        var comaniesformated = data.map((e) => {
          return {
            value: e._id,
            label: e.name,
            data: e,
          };
        });
        setcompanies([...comaniesformated]);
        loadPersonDetails([...comaniesformated]);
      })
      .catch((a) => (err) => {
        // /loadPersonDetails();
      });
  };

  const loadPersonDetails = (comps) => {
    httpService
      .get(`/v1/people/${props.id}`)
      .then((a) => {
        const { data } = a;

        var tempData = data;
        tempData.type = typeOfPerson.find((a) => a.value === data.type);
        if (data.company)
          tempData.companieSelected = comps.find(
            (a) => a.value === data.company._id
          );

        setpersonDetails({ ...tempData });
        //laodPeople();
      })
      .catch((a) => { });
  };

  const updateInfo = () => {
    httpService
      .put(`/v1/people/${props.id}`, {
        type: personDetails.type.value,
        name: personDetails.name,
        surname: personDetails.surname,
        idNumber: personDetails.idNumber,
        company: personDetails.companieSelected.value,
        bioId: personDetails.bioId,
        username: personDetails.username,
      })
      .then((res) => {
        toast(`${personDetails.name} as been updated successfully!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        if (props.reload) props.reload();
      })
      .catch((e) => {
        if (e.response) {
          toast(`${e.response.data}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    // laodPeople();
  }, []);

  return (
    <>
      <CustomModalV2
        mainComponents={<a href="#">{props.label}</a>}
        centered={true}
        canProccess={true}
        load={laodPeople}
        proccess={updateInfo}
        size="md"
        title="Edit Person"
        proccessTitle={"Update"}
        icon={<InfoLg />}
      >
        {/* <div style={{width:300}}>
    <Row>
        {['1','2','3','4','5','6','7','8','9','*','0','#'].map(a=>( <Col size={5}><div onClick={()=> alert(a)} style={{backgroundColor:"red" ,margin:5, height:50, width:50, borderRadius:19, fontSize:30,textAlign:'center'}}>{a}</div></Col> ))}
    
    </Row>
    </div> */}

        <Row>
          <Col>
            {/* <div style={{textAlign:"center"}}>
                        <Image width={150} height={150} src='https://media.gq.com/photos/566ac3fec0f741b430e641e7/16:9/w_1000,c_limit/elon-musk-gq-1215-01-sq.jpg' roundedCircle={true}  />
                    </div> */}
            <h4>Info</h4>

            {colInfo.map((a) => (
              <GenericFormElement
                {...a}
                value={personDetails[a.name]}
                handleChange={updateValue}
              />
            ))}
            {personDetails.companieSelected && (
              <GenericSelectForm
                value={personDetails.companieSelected}
                title="Companies"
                handleChange={(e) => updateValueSelct(e, "companieSelected")}
                options={companies}
              />
            )}
            {personDetails?.companieSelected && (
              <>
                {personDetails?.companieSelected?.data?.cabinets?.map((c) => (
                  <>
                    <Box /> {c.name} <br />
                  </>
                ))}
              </>
            )}

            <GenericSelectForm
              value={personDetails.type}
              title="Person Type"
              handleChange={(e) => updateValueSelct(e, "type")}
              options={typeOfPerson}
            />
          </Col>
        </Row>
      </CustomModalV2>
    </>
  );
};

const ResetPasswordButton = (props) => {
  return (
    <Dropdown.Item href="#" onClick={(a) => props.handler()}>
      <Key color="orange" /> Reset Password
    </Dropdown.Item>
  );
};

export const SendResetPasswordButton = (props) => {
  return (
    <Dropdown.Item href="#" onClick={(a) => props.handler()}>
      <EnvelopeFill color="orange" /> Send Email Reset Password Link.
    </Dropdown.Item>
  );
};


 function PeoplePicture(props) {
  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();    

    const blob = await fetch(imageSrc).then((res) => res.blob());

    console.log(blob);
    
    if (props.setPicture)
    {      
      props.setPicture(blob);
      setisShow(false);
    }
     

  }, [webcamRef]);

  const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
  };
  const webcamRef = React.useRef(null);

  const [isShow, setisShow] = useState(false);

  return (<>
  <Button onClick={e=> setisShow(true)} style={{ marginBottom: 5 }} variant="dark" size="sm"> <Camera2 /></Button>
 <ModalAnt
        forceRender={true}
        centered={true}
        okType="success"
        okText="Take Picture"
        width={500}
        title={"Take Picture"}
        visible={isShow}
        onOk={()=>{
          capture();
        }}
        onCancel={()=>{setisShow(false)}}
        >
        
 
<div style={{ textAlign:"center"}}>
  {isShow && <Webcam ref={webcamRef} screenshotFormat="image/jpeg" screenshotQuality={1} audio={false} videoConstraints={videoConstraints} />}

</div>
        </ModalAnt>
        

    
  </>)
}

export const CreateUpdatePeople = ({
  reload,
  id,
  buttonTitle,
  modalTitle,
  companies,
  locations,
  accessSchedules,
  accessGroup,
  competencies,
  
}) => {
  const [showMal, setshowMal] = useState(false);

  const [peopleTrackers, setpeopleTrackers] = useState([])

  const informationLabels = [
    { name: "name", label: "Name" },
    { name: "surname", label: "Surname" },
    { name: "idNumber", label: "Id Number" },
    { name: "bioId", label: "Bio Id" },
    { name: "username", label: "Email Address" },
  ];

  const [personDetails, setpersonDetails] = useState({ competencies: [] });

  const [isloading, setisloading] = useState(false);

  const [availableCompetencies, setavailableCompetencies] = useState([...competencies]);

  function createorUpdateAction() {
    const formData = new FormData();
    formData.append("ppicture", personDetails?.ppicture);  
    formData.append("details",JSON.stringify({ ...personDetails,modulesDisplayed:personDetails?.modulesDisplayed, accessControllGroup: !personDetails?.accessControllGroup ? null : personDetails?.accessControllGroup }))

    if (id) {
      httpService.put(`/v1/people/${id}`, formData).then(res => {
        const { data } = res;
        successMessage(`${personDetails?.name} successfully updated !`);
        setshowMal(false);
        if (reload)
          reload();
      }).catch(err => {
        const { response } = err;
        errorMessage(`Failed to update Person (${response?.data || err})`)
      })
    }
    else {
      httpService.post(`/v1/people`, formData).then(res => {
        const { data } = res;
        successMessage(`${personDetails?.name} successfully created!`);
        setshowMal(false);
        if (reload)
          reload();
      }).catch(err => {
        const { response } = err;
        errorMessage(`Failed to create Person (${response?.data || err})`)
      })
    }
  }

  function loadPersonInformation() {
    httpService.get(`/v1/people/${id}`).then(res => {
      const { data } = res;
      var peopeLocations = data?.accessLocation?.map(accesslocationmap => accesslocationmap._id)
      setpersonDetails({
        accessControllGroup: data?.accessControllGroup,
        name: data?.name,
        surname: data?.surname,
        userType: data?.userType,
        type: data?.type,
        type: data?.type,
        isActive: data?.isActive,
        username: data?.username,
        idNumber: data?.idNumber,
        bioId: data?.bioId,
        picture:data?.picture,
        followers:data?.followers	,	
        competencies: data?.competencies,
        modulesDisplayed:data?.modulesDisplayed,
        accessLocation: peopeLocations, company: data?.company?._id, accessSchedules: data?.accessSchedules?.map(accessschedulemap => accessschedulemap._id)
      });
      setisloading(true);
    }).catch(error => {
      setisloading(true);
      setpersonDetails({});
    })
  }

  const loadPeople = ()=>{
    httpService.get('/v1/people/staff/lite').then(response=>{
      const {data} = response;
      setpeopleTrackers([...data]?.map(person=>({value:person._id,label:`${person.name} ${person.surname} (${person?.company?.name || ""})`})))
     // setpeopleEmails([...data]);
    }).catch(error=>{

    })
  }



  return (
    <>
      {id && <>
        <Dropdown.Item onClick={(e) => {
          setshowMal(true);
          if (id)
          {
            loadPeople();
            loadPersonInformation();
          }
            loadPersonInformation();
        }} href="#">
          <InfoCircleFill /> Edit Person
        </Dropdown.Item>
      </>}
      {!id &&

        <Button
          onClick={(e) => {
            loadPeople();
            setshowMal(true);
            setisloading(true)
            if (id)
              loadPersonInformation();
          }}
          size="sm"
          variant="dark"
        >
          {buttonTitle}
        </Button>
      }

      <ModalAnt
        forceRender={true}
        okType="success"
        width={700}
        title={modalTitle}
        visible={showMal}
        onOk={() => { createorUpdateAction() }}

        onCancel={(e) => {
          setpersonDetails({ name: "", competencies: [] });
          setshowMal(false);
          setisloading(false);
        }}
      >
        {
          !isloading &&
          <div style={{ textAlign: "center" }}>
            <GridLoader margin={10} loading={!isloading} size={30} color="black" />
          </div>
        }


        {isloading &&

          <Tabs defaultActiveKey="1" >
            <TabPane tab="Personal Information" key="1">
              <FromAnt

                labelCol={{ span: 5 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
              >
                {informationLabels?.map((informationlabel) => (
                  <>
                    <FromAnt.Item label={informationlabel?.label}>
                      <Input
                        onChange={(e) =>
                          setpersonDetails((old) => ({
                            ...old,
                            [informationlabel?.name]: e.target.value,
                          }))
                        }
                        value={personDetails[informationlabel?.name]}
                      />
                    </FromAnt.Item>
                  </>
                ))}
                <FromAnt.Item label="User Type">
                  <SelectAnt
                    onChange={(e) =>
                      setpersonDetails((old) => ({ ...old, userType: e }))
                    }
                    value={personDetails?.userType}
                  >
                    <SelectAnt.Option value="user">User</SelectAnt.Option>
                    <SelectAnt.Option value="admin">Admin</SelectAnt.Option>
                  </SelectAnt>
                </FromAnt.Item>
                <FromAnt.Item label="Person Type">
                  <SelectAnt
                    onChange={(e) =>
                      setpersonDetails((old) => ({ ...old, type: e }))
                    }
                    value={personDetails?.type}
                  >
                    <SelectAnt.Option value="staff">Staff</SelectAnt.Option>
                    <SelectAnt.Option value="visitor">Visitor</SelectAnt.Option>
                    <SelectAnt.Option value="tenant">Tenant</SelectAnt.Option>
                  </SelectAnt>
                </FromAnt.Item>
                <FromAnt.Item label="Companies">
                  <SelectAnt
                    onChange={(e) =>
                      setpersonDetails((old) => ({ ...old, company: e }))
                    }
                    value={personDetails?.company}
                  >
                    {companies?.map((company) => (
                      <>
                        <SelectAnt.Option value={company.value}>
                          {company.label}
                        </SelectAnt.Option>
                      </>
                    ))}
                  </SelectAnt>
                </FromAnt.Item>
                <FromAnt.Item label="Status">
                  <Switch
                    checked={personDetails?.isActive}
                    onChange={(e) =>
                      setpersonDetails((old) => ({ ...old, isActive: e }))
                    }
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </FromAnt.Item>
                <FromAnt.Item label="Picture">
                <div>
                  <PeoplePicture setPicture={imageInformation=>{
                      setpersonDetails((old) => ({ ...old, ppicture: imageInformation }))
                  }} />
                  </div>

                  {personDetails?.ppicture &&  <img src={URL.createObjectURL(personDetails?.ppicture)} height={150} width={150}></img>}                 
                  {(personDetails?.picture && !personDetails?.ppicture) &&  <img src={`/v1/people/${id}/picture`} height={150} width={150}></img>} 
                  
                  <div style={{ marginTop: 5 }}> <Input accept="image/*" onChange={e=> 
                  {
                    console.log(e.target.files[0]);
                    setpersonDetails((old) => ({ ...old, ppicture: e.target.files[0] }))
                  }
                  
                  
                  } type="file"/></div>
                 
                 
                </FromAnt.Item>
              </FromAnt>

            </TabPane>
            <TabPane style={{ background: "black" }} tab="Security" key="2">
              <Collapse defaultActiveKey={["2"]}>
                <Collapse.Panel header="Security Access" key="2">
                  <FromAnt
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 20 }}
                    layout="horizontal"
                  >
                    <FromAnt.Item allowClear={true} clea label="Access Group">
                      <SelectAnt
                        allowClear={true}
                        onChange={(e) =>
                          setpersonDetails((old) => ({ ...old, accessControllGroup: e }))
                        }
                        value={personDetails?.accessControllGroup}
                      >
                        {accessGroup?.map((location) => (
                          <>
                            <SelectAnt.Option value={location.value}>
                              {location.label}
                            </SelectAnt.Option>
                          </>
                        ))}
                      </SelectAnt>
                      
                    </FromAnt.Item>

                    {personDetails?.userType==="admin" && 
                      <FromAnt.Item allowClear={true} clea label="Modules">
                      <SelectAnt
                        mode="tags"
                        allowClear={true}
                        onChange={(e) =>
                          setpersonDetails((old) => ({ ...old, modulesDisplayed: e }))
                        }
                        value={personDetails?.modulesDisplayed}
                      >
                        {modulesReady?.map((moduleName) => (
                          <>
                            <SelectAnt.Option value={moduleName}>
                              {moduleName}
                            </SelectAnt.Option>
                          </>
                        ))}
                      </SelectAnt>
                      
                    </FromAnt.Item>
                    }
                  

                    {!personDetails?.accessControllGroup && (
                      <>
                        <FromAnt.Item label="Locations">
                          <SelectAnt
                            onChange={(e) =>
                              setpersonDetails((old) => ({ ...old, accessLocation: e }))
                            }
                            mode="tags"
                            value={personDetails?.accessLocation}
                          >
                            {locations?.map((location) => (
                              <>
                                <SelectAnt.Option value={location.value}>
                                  {location.label}
                                </SelectAnt.Option>
                              </>
                            ))}
                          </SelectAnt>
                        </FromAnt.Item>
                        <FromAnt.Item label="Schedules">
                          <SelectAnt
                            onChange={(e) =>
                              setpersonDetails((old) => ({
                                ...old,
                                accessSchedules: e,
                              }))
                            }
                            mode="tags"
                            value={personDetails?.accessSchedules}
                          >
                            {accessSchedules?.map((schedule) => (
                              <>
                                <SelectAnt.Option value={schedule.value}>
                                  {schedule.label}
                                </SelectAnt.Option>
                              </>
                            ))}
                          </SelectAnt>
                        </FromAnt.Item>
                      </>
                    )}
                  </FromAnt>
                </Collapse.Panel>
                <Collapse.Panel header="Tracking" key="3">
                  <FromAnt
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 20 }}
                    layout="horizontal"
                  >
                    <FromAnt.Item allowClear={true} clea label="Trackers">
                      <SelectAnt
                      mode="tags"
                        allowClear={true}
                        onChange={(e) =>
                          setpersonDetails((old) => ({ ...old, followers: e }))
                        }
                        value={personDetails?.followers}
                      >
                        {peopleTrackers?.map((location) => (
                          <>
                            <SelectAnt.Option value={location.value}>
                              {location.label}
                            </SelectAnt.Option>
                          </>
                        ))}
                      </SelectAnt>
                    </FromAnt.Item>

                   
                  </FromAnt>
                </Collapse.Panel>
              </Collapse>
            </TabPane>
            <TabPane tab="Competency" key="3">
              <div style={{ margin: 5 }}>
                <Button size="sm" variant="success" onClick={e => {
                  var tempCompetency = personDetails?.competencies ? [...personDetails?.competencies] : []
                  tempCompetency.push({ type: "", ref: "", from: moment(), to: moment() })
                  setpersonDetails(old => ({ ...old, competencies: tempCompetency }))
                }}>+</Button>
              </div>

              <Table hover size="sm" responsive={true}>
                <thead>
                  <th>Type</th>
                  <th>Number/Reference</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th></th>
                </thead>
                <tbody>
                  {
                    personDetails?.competencies?.map((competency, index) => (<>
                      <tr key={index}>
                        <td>
                          <SelectAnt
                            style={{ width: "100%" }}
                            value={competency?.type}
                            onChange={e => {
                              var temp = [...personDetails?.competencies];
                              temp[index] = { ...temp[index], type: e };
                            
                                                     

                              setpersonDetails(old => ({ ...old, competencies: temp }))
                            }}
                          >

                            {availableCompetencies?.map(competency => (<SelectAnt.Option value={competency?.value}>{competency?.label}</SelectAnt.Option>))}

                          </SelectAnt>
                        </td>
                        <td><Input
                          onChange={e => {
                            var temp = [...personDetails?.competencies];
                            temp[index] = { ...temp[index], ref: e.target.value }
                            setpersonDetails(old => ({ ...old, competencies: temp }))
                          }}
                          value={competency?.ref} /></td>
                        <td> <DatePicker
                          value={moment(competency?.from)}
                          onChange={e => {
                            var temp = [...personDetails?.competencies];
                            temp[index] = { ...temp[index], from: e }
                            setpersonDetails(old => ({ ...old, competencies: temp }))
                          }}
                          defaultValue={moment()} /></td>
                        <td><DatePicker
                          value={moment(competency?.to)}
                          onChange={e => {
                            var temp = [...personDetails?.competencies];
                            temp[index] = { ...temp[index], to: e }
                            setpersonDetails(old => ({ ...old, competencies: temp }))
                          }}
                          defaultValue={moment()} /></td>
                        <td>
                          <Button onClick={e => {


                            var tempCompetency = personDetails?.competencies ? [...personDetails.competencies] : []

                            tempCompetency.splice(index, 1);

                            setpersonDetails(old => ({ ...old, competencies: tempCompetency }))

                          }} style={{ margin: 2 }} variant="danger" size="sm">-</Button>
                        </td>
                      </tr>
                    </>))
                  }
                </tbody>
              </Table>
            </TabPane>
          </Tabs>

        }

      </ModalAnt>
    </>
  );
};

export const PeopleOnSite = () => {
  const [showMal, setshowMal] = useState(false);

  const [peopleOnSite, setpeopleOnSite] = useState();

  const [isLoading, setisLoading] = useState(false);
  
  const loadPeopleOnSite = ()=>{
    setisLoading(true);
    httpService.get("/v1/people/onsite").then(response=>{
      const {data} = response;
      setpeopleOnSite([...data]);
      setisLoading(false);
    }).catch(error=>{
      setisLoading(false);
    })
  }



  return (
    <>
      
      {

        <Button
        title="People On Site"
        style={{margin:5}}
          onClick={(e) => {
            setshowMal(true);
            loadPeopleOnSite();
          }}
          size="sm"
          variant="dark"
        >
          <Building/>
        </Button>
      }

      <ModalAnt
        forceRender={true}
        okType="success"
        width={900}
        title={"People On Site"}
        visible={showMal}
        onOk={() => {  }}
        footer={[<Button onClick={e=> setshowMal(false)} size="sm" variant="outline-dark" >Cancel</Button>]}
        onCancel={(e) => {
          setshowMal(false);
          setpeopleOnSite([])
        }}
      >
        { <div style={{ textAlign: "center" }}>
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
        </div>}
         <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[            
            {name:"Date",sortable:true,selector:(row)=>row.lastActivityTime,cell:row=> <>{moment(row.lastActivityTime).format("YYYY-MM-DD HH:mm-ss")}</>},
            {name:"Person",selector:(row)=>row._id,cell:row=> <>{row.name} {row.surname}</>},
            {name:"Company",selector:(row)=>row?.company?._id,cell:row=> <>{row?.company?.name}</>},
            {name:"Last Location",selector:(row)=>row?.lastLocation?._id,cell:row=> <>{row?.lastLocation?.name}</>},
            {name:"Last Zone",selector:(row)=>row?.lastZone?._id,cell:row=> <>{row?.lastZone?.name}</>},
            {name:"Floor",selector:(row)=>row?.lastZone?._id,cell:row=> <>{row?.lastZone?.floor?.description}</>},
            {name:"Building",selector:(row)=>row?.lastZone?._id,cell:row=> <>{row?.lastZone?.floor?.buildingLocation?.name}</>}
          ]}
          data={peopleOnSite}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
        />

      </ModalAnt>
    </>
  );
};
export const InviteUsers = (props) => {
  const [showMal, setshowMal] = useState(false);  
  const [userSelected, setuserSelected] = useState();
  const [peopleEmails, setpeopleEmails] = useState();
  
  const loadPeople = ()=>{
    httpService.get('/v1/people/staff/lite').then(response=>{
      const {data} = response;
      setpeopleEmails([...data]);
    }).catch(error=>{

    })
  }

  const SendInvites =()=>{
    httpService.post('/v1/auth/sendInvite',{
      emails:userSelected
    }).then(response=>{
      const {data} = response;
      successMessage(data);
      setshowMal(false);
      setuserSelected([]);
    }).catch(error=>{
      errorMessage(error?.response?.data || error)
    })
  }

  return (
    <>
      
      {

        <Button
        title="Send Invites"
        style={{margin:5}}
          onClick={(e) => {
            loadPeople();
            setshowMal(true);
            
          }}
          size="sm"
          variant="dark"
        >
          <EnvelopeFill/>
        </Button>
      }

      <ModalAnt
        forceRender={true}
        okType="success"
        width={400}
        title={"Send Invites"}
        visible={showMal}
        onOk={() => {  }}
        footer={[<Button onClick={e=> {setshowMal(false); setuserSelected([]);}} size="sm" style={{margin:5}} variant="outline-dark" >Cancel</Button>,<Button style={{margin:5}} onClick={e=> SendInvites()} size="sm" variant="outline-success" >Send</Button>]}
        onCancel={(e) => {
          setshowMal(false);
          setuserSelected([]);
        }}
      >
                    <div style={{textAlign:"center"}}>
                      <p>Users</p>
                    <SelectAnt
                      style={{width:300}}
                      mode="tags"
                        allowClear={true}
                        onChange={(e) =>
                          setuserSelected(e)
                        }
                        value={userSelected}
                      >
                        {peopleEmails?.map((person) => (
                          <>
                            <SelectAnt.Option value={person.username}>
                              {person.name} {person.surname}
                            </SelectAnt.Option>
                          </>
                        ))}
                      </SelectAnt>
                    </div>
                      

        {/* { <div style={{ textAlign: "center" }}>
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
        </div>} */}
       

      </ModalAnt>
    </>
  );
};

export default function People() {
  const [people, setpeople] = useState([]);

  const AdminButtonDesign = (props) => {
    if (props.userType === "admin")
      return (
        <Dropdown.Item href="#" onClick={(a) => props.handler()}>
          <PeopleFill /> <Badge bg="danger"> Admin</Badge>{" "}
        </Dropdown.Item>
      );
    else
      return (
        <Dropdown.Item href="#" onClick={(a) => props.handler()}>
          <PeopleFill /> <Badge bg="success"> User</Badge>{" "}
        </Dropdown.Item>
      );
  };

  const DelelePerson = (props) => {
    return (
      <Dropdown.Item href="#" onClick={(a) => props.handler(props)}>
        <EraserFill color="red" /> Delete{" "}
      </Dropdown.Item>
    );
  };

  const BioInitialisation = (props) => 
    
      (
        <Dropdown.Item href="#" onClick={(_a) => resetBiometrics(props)}>
          <Fingerprint color="red" /> Biometrics Initialization{" "}
        </Dropdown.Item>
      );

  

  const columns = [
    {
      sortable: true,
      name: "QR",
      selector: (row) => row._id,
      cell: (row) => <QRCode style={{ margin: 5 }} size={50} value={row._id} />,
    },
    {
      grow: 3,
      name: "Picture",

      cell: (row) => (
        <>
          {
            <>
              {row?.picture && (
                <Image
                  roundedCircle={true}
                  fluid={true}
                  style={{ width: 100, height: 100 }}
                  src={`/v1/people/${row._id}/picture`}
                ></Image>
              )}{" "}
            </>
          }
        </>
      ),
    },
    {
      sortable: true,
      name: "Name",
      selector: (row) => row.name,
    },
    {
      sortable: true,
      name: "Surname",
      selector: (row) => row.surname,
    },
    {
      grow: 2,
      sortable: true,
      name: "Username",
      selector: (row) => row.username,
      cell: (row) => <div>{row.username}</div>,
    },

    {
      sortable: true,
      name: "Company",
      selector: (row) => row?.company?.name || row?.companyName,
      cell: (row) => <>{row?.company?.name || row?.companyName}</>,
    },
    {
      sortable: true,
      name: "Location",
      selector: (row) => row.status,
    },
    {
      sortable: true,
      name: "Last Activity",
      selector: (row) => row.lastActivityTime,
      cell: (row) => (
        <>
          {row?.lastActivityTime &&
            moment(row.lastActivityTime).format("MMMM Do YYYY, h:mm:ss a")}
        </>
      ),
    },
    {
      sortable: true,
      name: "Type",
      selector: (row) => row.type,
    },
    {
      sortable: true,
      name: "Status",
      selector: (row) => row.isActive,
      cell: (row) => (
        <>
          {row.isActive ? (
            <Badge bg="success">Active</Badge>
          ) : (
            <Badge bg="danger">Inactive</Badge>
          )}
        </>
      ),
    },
    {
      sortable: true,

      name: "Allowed Locations",
      selector: (row) => row?.accessLocation?.length,
      cell: (row) => (
        <>
          <div style={{ margin: 10 }}>
            {row?.accessLocation.map((a) => (
              <>
                <Badge style={{ margin: 2 }} bg="dark">
                  <DoorOpenFill color="green" size={15} /> {a.name}{" "}
                </Badge>
              </>
            ))}
          </div>
        </>
      ),
    },
    {
      name: "",
      grow: 3,
      cell: (row) => (
        <div style={{ height: 180 }}>
          <Dropdown>

            <Dropdown.Toggle variant="black" id={row?._id}>
              <GearFill />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <CreateUpdatePeople
                reload={loadPeople}
                id={row._id}
                locations={accesslocations}
                accessSchedules={accessSchedules}
                companies={companies}
                accessGroup={accessGroup}
                buttonTitle={"+"}
                modalTitle={`Edit ${row?.name} ${row?.surname}`}
                competencies={competenciesAvailabels}
                peopleTrackers={people?.map(person=>({value:person._id,label:`${person.name} ${person.surname} (${person?.company?.name || "" })`}))}
              />

              <ResetPasswordButton
                handler={(e) => resetPassword(row.username)}
              />
              <SendResetPasswordButton   handler={(e) => sendResetPassword(row.username)}/>
              <BioInitialisation {...row} />
              <BioEnrolement {...row} />
              
              <AdminButtonDesign {...row} />
              <DelelePerson {...row} handler={deletePeople} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const resetBiometrics = (data) => {
    var confirm = window.confirm(
      `Are you sure you want to reinitialized ${data.name} biometrics ?`
    );
    if (confirm)
      httpService
        .post("/v1/api/biometric/initialize", {
          id:data._id,
          bioId: data.bioId,
          name: data.name,
        })
        .then((a) => {
          const { data } = a;
          successMessage(`${data}`);
        })
        .catch((b) => {
          errorMessage(`Failed to initialize Biometrics`);
        });
  };

  const resetPassword = (user) => {
    var confirm = window.confirm(
      `Are you sure you want to reset ${user} password ?`
    );
    if (confirm)
      httpService
        .post("/v1/auth/resetpassword", {
          email: user,
          resetcode: "jade-012",
        })
        .then((a) => {
          const { data } = a;
          Modal.success({
            content: <p style={{ textAlign: "center" }}>

              {data.username} passowrd reseted successfully !
              <h5>New Password</h5>
              <h4 style={{ color: "green" }}>{data.password}</h4>
            </p>,
          });

        })
        .catch((a) => { });
  };

  const sendResetPassword = (username) => {
    var confirm = window.confirm(
      `Are you sure you want to reset ${username} password ?`
    );
    if (confirm)
      httpService
        .post("/v1/auth/resetpassword/generate", {
          user: username
        })
        .then((a) => {
          const { data } = a;
          Modal.success({
            content: <p style={{ textAlign: "center" }}>
          The password reset link has been sent to {username} !              
            </p>,
          });

        })
        .catch((a) => { });
  };

  const loadPeople = () => {
    setisLoading(true);
    httpService
      .get("/v1/people/search")
      .then((res) => {
        const { data } = res;
        setpeople([...data]);
        setisLoading(false);
      })
      .catch((res) => {
        setisLoading(false);
      });
  };

  const SearchPeople = (name) => {
    httpService
      .get(`/v1/people/search?byname=${name}`)
      .then((res) => {
        const { data } = res;
        setpeople([...data]);
      })
      .catch((res) => { });
  };

  const deletePeople = (props) => {
    var confirm = window.confirm(`Are you sure ?`);

    if (!confirm) return;

    httpService
      .delete(`/v1/people/${props._id}`)
      .then((res) => {
        successMessage(`${props.name} deleted successfully !`);
        loadPeople();
      })
      .catch((err) => {
        if (err) errorMessage(`Failed to delete ${err}`);
      });
  };
  const [isLoading, setisLoading] = useState(true);

  const [companies, setcompanies] = useState([]);

  const [accesslocations, setaccesslocations] = useState([]);

  const [accessSchedules, setaccessSchedules] = useState([]);

  const [accessGroup, setaccessGroup] = useState([]);

  const [competenciesAvailabels, setcompetenciesAvailabels] = useState([])

  function loadCompannies() {
    httpService
      .get("/v1/companies/lite")
      .then((response) => {
        const { data } = response;
        setcompanies([
          ...data?.map((companie) => ({
            value: companie?._id,
            label: companie?.name,
          })),
        ]);
      })
      .catch((error) => {
        setcompanies([]);
      });
  }

  function laodlcotions() {
    httpService
      .get("/v1/LocationsAccess")
      .then((response) => {
        const { data } = response;
        setaccesslocations([
          ...data?.map((companie) => ({
            value: companie?._id,
            label: companie?.name,
          })),
        ]);
      })
      .catch((error) => {
        setcompanies([]);
      });
  }

  function loadschedules() {
    httpService
      .get("/v1/accessschedule")
      .then((response) => {
        const { data } = response;
        setaccessSchedules([
          ...data?.map((companie) => ({
            value: companie?._id,
            label: companie?.name,
          })),
        ]);
      })
      .catch((error) => {
        setaccessSchedules([]);
      });
  }

  function loadCompetencies() {
    httpService.get('/v1/accessgroup/competencies').then(response => {
      const { data } = response;
      setcompetenciesAvailabels([...data]);
    }).catch(err => {
      setcompetenciesAvailabels([]);
    })
  }

  function loadGroupAccess() {
    httpService
      .get("/v1/accessgroup")
      .then((response) => {
        const { data } = response;
        setaccessGroup([
          ...data?.group?.map((companie) => ({
            value: companie?._id,
            label: companie?.name,
          })),
        ]);
      })
      .catch((error) => {
        setaccessGroup([]);
      });
  }

  useEffect(() => {
    loadPeople();
    loadCompannies();
    laodlcotions();
    loadschedules();
    loadGroupAccess();
    loadCompetencies();
  }, []);
  return (
    <>
      <Header />
      <HeaderTitle name="People" />
      <Container>
        <>

          <CreateUpdatePeople
            reload={loadPeople}
            locations={accesslocations}
            accessSchedules={accessSchedules}
            companies={companies}
            accessGroup={accessGroup}
            buttonTitle={"+"}
            modalTitle="New Person"
            competencies={competenciesAvailabels}
            peopleTrackers={people?.map(person=>({value:person._id,label:`${person.name} ${person.surname} (${person?.company?.name || ""})`}))}
          />
          <PeopleOnSite />
          <InviteUsers people={people} />
          <div style={{ marginTop: 10 }}>
            <SearchBar search={(e) => SearchPeople(e)} />
          </div>
        </>
        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={columns}
          data={people}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
        />
        <div style={{ textAlign: "center" }}>
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
        </div>
      </Container>
    </>
  );
}
