import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSyncExternalStore } from 'react'
import { Container,Button as BButton, Row, Col } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import Cron from 'react-js-cron'
import { GridLoader } from 'react-spinners'
import Header from '../Components/Header'
import HeaderTitle from '../Components/HeaderTitle'
import httpService from '../services/httpService';
import { errorMessage, successMessage } from '../services/utilities';
import 'react-js-cron/dist/styles.css';
import { Eraser } from 'react-bootstrap-icons'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const Allcommands=["PDU_1","PDU_2","PDU 1 ON","PDU 1 OFF","PDU 2 ON","PDU 2 OFF"]

const ECSchedulerCommands =({commandsList,AddCommand, devices, commands,setcommandsList})=>{

    const handleSetCommmands =(name, index,value)=>{
        let temp = [...commandsList];
        temp[index][name]=value;
        setcommandsList([...temp]);

    }

    return(<>
     <BButton size="sm" onClick={e=> AddCommand()} variant="dark">+</BButton>
    <Row>
        <Col ><div style={{width:200}}>Device</div></Col>
        <Col><div style={{width:120}}>Command</div></Col>
        <Col><div style={{width:50}}>Value</div></Col>
    </Row>
   
    {commandsList?.map((commandList,index)=> 
    <Row>
        <Col style={{margin:2}}>
        <Select key={`deviceId_${index}`} style={{width:200}} value={commandList?.deviceId}
        onChange={e=> handleSetCommmands("deviceId",index,e)}
        >
        {devices?.map(device=> <Select.Option value={device?.value}>{device.label}</Select.Option>)}
        </Select>
        </Col>
        <Col style={{margin:2}}>
        <Select key={`command_${index}`} style={{width:100}} value={commandList?.command} 
        onChange={e=> handleSetCommmands("command",index,e)}
        >
        {commands?.map(command=> <Select.Option value={command}>{command}</Select.Option>)}
        </Select >
        </Col>
        <Col style={{margin:2}}><InputNumber key={`value_${index}`} min={0} style={{width:50}} value={commandList?.value}
         onChange={e=> handleSetCommmands("value",index,e)}
        ></InputNumber></Col>
    </Row>
    )}
    </>)
}

const ECScheduler = ({ButtonTitle,reLoad, title,availableSchedules,availableDevices})=>{

    const [isModalOpen, setisModalOpen] = useState(false);
    const [scheduleDetails, setscheduleDetails] = useState({commands:[]});
    const handleOk =(e)=>{
        httpService.post('/v1/shecudles/commands',scheduleDetails).then(response=>{
            const {data} = response;
            successMessage(`Schedule ${scheduleDetails?.name} created successfully!`);
            reLoad();
            handleCancel();
        }).catch(error=>{
            errorMessage(error?.response?.data || error)
        });
    }
    const handleCancel = (e)=>{
        setisModalOpen(false);
        setscheduleDetails({commands:[],name:""});
    }
    const addCommandHandler = ()=>{
       
            let commandDetails = {deviceId:"",command:"",value:0};
            let currentCommands = scheduleDetails?.commands;
            currentCommands.push(commandDetails)
            setscheduleDetails(old=>({...old,"commands":currentCommands}))
        
    }
    
    return (
      <>
        <BButton
          style={{ margin: 2 }}
          variant="dark"
          size="sm"
          onClick={(e) => setisModalOpen(true)}
        >
          +
        </BButton>
        <Modal
        forceRender
          width={500}
          title={title}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <BButton
              onClick={handleCancel}
              size="sm"
              style={{ margin: 2 }}
              variant="dark"
            >
              Cancel
            </BButton>,
            <BButton onClick={handleOk} style={{ margin: 2 }} size="sm" variant="success">
              OK
            </BButton>,
          ]}
        >
          

          <Form layout={"vertical"}>
            <Form.Item label="Type" name="TypeINput">
              <Select
              key={"s_type"}
                value={scheduleDetails?.type}
                onChange={(e) =>
                  setscheduleDetails((old) => ({ ...old, ...{type: e,runner:""} }))
                  
                }
              >
                <Select.Option value="Repports">Repport</Select.Option>
                <Select.Option value="Commands">Commands</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Name" name="nameInput">
              <Input
                
                value={scheduleDetails?.name}
                onChange={(e) =>
                  setscheduleDetails((old) => ({
                    ...old,
                    name: e.target.value,
                  }))
                }
              ></Input>
            </Form.Item>
            <Form.Item label="Repeat Settings" name="Settings-Onput">

            <Cron
      value={scheduleDetails?.repeatAt}
      setValue={e=>  setscheduleDetails((old) => ({ ...old, repeatAt: e }))}
    />

            </Form.Item>
            <Form.Item label="Selected Runner" name="SellectedSettings-Input2 ">
              <Select
              allowClear
                value={scheduleDetails?.runner}
               
                style={{ margin: 2 }}
                onChange={(e) =>
                  setscheduleDetails((old) => ({ ...old, runner: e }))
                }
              >
                {availableSchedules
                  ?.filter(
                    (runnerFiler) => runnerFiler?.type === scheduleDetails?.type
                  )
                  ?.map((runnerOptiond) => (
                    <Select.Option value={runnerOptiond?.name}>
                      {runnerOptiond?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            {scheduleDetails?.type === "Commands" && (
              <ECSchedulerCommands
                setcommandsList={(e) =>
                  setscheduleDetails((old) => ({ ...old, commands: [...e] }))
                }
                commands={Allcommands}
                devices={availableDevices}
                AddCommand={addCommandHandler}
                commandsList={scheduleDetails?.commands}
              />
            )}
          </Form>
        </Modal>
      </>
    );
}

export default function Scheduler() {
    const [isLoading, setisLoading] = useState(false);
    const [schedules, setschedules] = useState([]);
    const [availableSchedules, setavailableSchedules] = useState();
    const [availableDevices, setavailableDevices] = useState([])

    function loadSchedules ()
    {
        httpService.get("/v1/schedules").then(response=>{
            const {data} = response;
            setschedules([...data?.schedules]);
            setavailableSchedules([...data?.availableRepports]);    
            setavailableDevices([...data?.devices])        
        }).catch(error=>{})
    }

    const deleteSchedule = (id)=>{
      httpService.delete(`/v1/schedules/${id}`).then(response=>{
        const {data} = response;
        successMessage(`Schedule was successfully deleted !`);
        loadSchedules();
      }).catch(error=>{})
    }

    const handleDelete = (id)=>{
    Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete this schedule ?',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk(){deleteSchedule(id)}
      });
    }
    useEffect(() => {   
    loadSchedules();
    }, [])
    
  return (<>
  <Header />
    <HeaderTitle name="Schedulers" />
    <Container>
        
    <ECScheduler reLoad={loadSchedules} availableDevices={availableDevices} availableSchedules={availableSchedules} ButtonTitle={<>+</>} title={"New Schedule"} />
    <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[{name:"Name",selector:row=> row?.data?.name || row.name },
          {name:"Last Run",selector:row=> row?.lastRunAt, cell:row=> <>{moment(row?.lastRunAt).format('MMMM Do YYYY, h:mm:ss a')}</>},
          {name:"Next Run",selector:row=> row?.nextRunAt,cell:row=> <>{moment(row?.nextRunAt).format('MMMM Do YYYY, h:mm:ss a')}</>},
          {name:"",cell:row=> <>
          <BButton variant="danger" onClick={e=> handleDelete(row?._id)} size="sm"><Eraser/></BButton>
          </>}
        ]}
          data={schedules}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
        />
        <div style={{ textAlign: "center" }}>
          <GridLoader margin={10} loading={isLoading} size={30} color="black" />
        </div>
    </Container>
  </>
    
  )
}
