import { Button } from "react-bootstrap";
import { Eraser, Plus } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import CustomModal from "../Components/CustomModal";
import mqtt from 'mqtt';
import { getnewToken } from "./httpService";
import { GridLoader } from "react-spinners";
var W3CWebSocket = require('websocket').w3cwebsocket;

var mqttClient ;

var wsClient ;

const envSettings = window ;

var mqtttTokenTry=0;

const ws_server= envSettings.WSS_SERVER;



export const modulesAvailables=
["people","bookings","companies","assets","activities","accessStrategies","accessSchedules","accessGroup","izones","floors","accessLocations","buildings","displays","settings","schedulers"];

export const successMessage = (msg)=>
{
    return toast(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type:"success"
        });
}

export const errorMessage = (msg)=>
{
    return toast(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type:"error"
        });
}


export const DeleteButton = (props)=>{

    return (<>
    <Button variant='danger' size="sm" onClick={(e)=> props.handler(props._id)}><Eraser/></Button>
    </>)
}


export const NewItem = (props)=>{

    return (<>
    <CustomModal load ={props.load} canProccess={true} proccess={props.handleNew} size={props.size} title={props.title} proccessTitle={props.proccessTitle} icon={props.icon || <Plus/> } >   
   {props.children}
   </CustomModal>
    </>)
}

export const getProfile = ()=>{
    let profile = localStorage.getItem("profileInfo");
    if(profile)
    {
      //alert(profile);
      profile = JSON.parse(profile);
      return profile;
    }
    else return "null";
}

export function connectToMQTTBroker(topic, dataCallBack,user,token,defaultTopic=true)
{

    
   var mypass = localStorage.getItem("jwt_token")
   var profile =JSON.parse(localStorage.getItem("profileInfo")) ; 

   if(!profile) return;


  mqttClient = mqtt.connect(profile.ws,{
    username:profile.username,
    password:mypass,
    reconnectPeriod:1000
  });
 

 
  mqttClient.on("connect",c=>{
    console.log("Connect to mqtt broker");
    mqtttTokenTry=0;   
    if(defaultTopic)
    mqttClient.subscribe(`/v2/udpateTelemetries/dashboard/${profile?.CID}`)
    if(topic)
    mqttClient.subscribe(topic)
    
  })

  mqttClient.on("message",(topic,data)=>{
    dataCallBack(topic,data)
  })
 
  mqttClient.on("end",async (e)=> console.log("Connection terminated"));
 mqttClient.on("disconnect",(e)=> console.log('Disconnected from broker'));
  mqttClient.on("reconnect",async (e)=> {
    mypass = localStorage.getItem("jwt_token")
    ///console.log(`reconnecting ... ${e}`,mqttClient.options.password)

    var newtoken="";
    try {
      if(mqtttTokenTry>9)
      {
       return window.location = '/app/login/ready';
      }
      newtoken =  await getnewToken();
      mqttClient.options.password= newtoken.jwt_token;
    } catch (error) {
      mqtttTokenTry++;
    }    
  
    //mqttClient.optionspassword
  });
}

export function ConnectToMqtt(topic, dataCallBack,user,token,password,server,defaultTopic=true,dicConnected=null,Reconnected=null)
{

    mqttClient = mqtt.connect(server,{
    username:user,
    password:password,
    reconnectPeriod:5000,
    connectTimeout:5000
  });
 

 
  mqttClient.on("connect",c=>{
    console.log("Connect to mqtt broker");
    mqtttTokenTry=0;  

    if(Reconnected)
    Reconnected("Connected to the server")
    if(topic)
    mqttClient.subscribe(topic)
    
  })

  mqttClient.on("message",(topic,data)=>{
    dataCallBack(topic,data)
  })
 
  mqttClient.on("end",async (e)=> console.log("Connection terminated"));
 mqttClient.on("disconnect",(e)=> console.log('Disconnected from broker'));
  mqttClient.on("reconnect",async (e)=> {
   
    console.log(`reconnecting ...`);

    if(dicConnected);
    dicConnected("Trying to reconnect to the server");
    
    var newtoken="";
    try {
      if(mqtttTokenTry>9)
      {
       return window.location = '/app/login/ready';
      }
      
      mqttClient.options.password= password;
    } catch (error) {
      mqtttTokenTry++;
    }    
  
    //mqttClient.optionspassword
  });
}

export function closeMQTTConnection (callback)
{
  if(mqttClient)
  mqttClient.end();
  if(callback)callback();
}

export function connectWS(url,callbackOnClosed, newMsgCallBack,payload)
{

  wsClient= new W3CWebSocket(url);
  wsClient.onerror = function(e) {
    console.log('Connection Error',e);
};



wsClient.onopen = function() {
  console.log('WebSocket Client Connected');

  function sendNumber() {
      if (wsClient.readyState === wsClient.OPEN) {
          var number = Math.round(Math.random() * 0xFFFFFF);
          wsClient.send(number.toString());
          setTimeout(sendNumber, 3000);
      }
  }

  wsClient.send(JSON.stringify(payload));

  //sendNumber();
};

wsClient.onclose = function() {
  console.log('echo-protocol Client Closed');
  if(callbackOnClosed)
  callbackOnClosed();
};

wsClient.onmessage = function(e) {  
  newMsgCallBack(e.data);
};
  
}

export function disconnectWS()
{
  wsClient?.close();
}

export function LoadingComponents (props)
{
  return ( <div style={{textAlign:"center"}}><GridLoader margin={10} loading={props.isLoading} size={30} color="black"
  /></div>)
}

