import { useEffect, useState } from "react";
import io from 'socket.io-client';



export const useCounter = ()=>{

    const [first, setfirst] = useState()

    useEffect(() => {
      
    
      return () => {
        
      }
    }, [])
    
}

export const useLocalStorage = () =>{

    const [counter, setcounter] = useState(0);
    const [isOnline, setisOnline] = useState(true);

    const getItem = (key)=>{
        let itemValue = localStorage.getItem(key);

        try {
            itemValue = JSON.parse(itemValue);
            return itemValue;
        } catch (error) {
            
            return null
        }
    }

    const setItem = (key, value)=>{
        localStorage.setItem(key, JSON.stringify(value));
    }

    const CheckNavigatorStatus =()=>
    {
        setisOnline(navigator.onLine);
    }

    useEffect(() => {
      // let counterId = setInterval(() => {
      //   setcounter(old=> old+1);
      //   CheckNavigatorStatus();
      // }, 1000);
    
      return () => {
        // clearInterval(counterId);
      }
    }, [counter])
    

    return({setItem, getItem, counter, isOnline})
}

export const useSocketIO = (initialTopics, callback)=>{

  var socket;
  
  const [isConnected, setIsConnected] = useState(socket?.connected);

  const [lastPong, setLastPong] = useState(null);

  const [topics, settopics] = useState([])

  function ConnectToIOSocketServer (Mytopics)
  {
    settopics([...Mytopics]);

    topics?.map(topic=>{
      socket.on(topic, (e) => {

        callback(topic,e)
      });
    })
  }

  useEffect(() => {
     socket = io({});

    console.log(`Initial sub`,initialTopics)

    socket.auth={token:"2322222222222222"};

    if(initialTopics)
    socket.connect("/",{
      auth:""
    })

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('connect_error', () => {
      setIsConnected(false);
    });

    

    socket.on('disconnect', () => {
      setIsConnected(false);
      socket.auth={token:"55565566"};
    });

    socket.on('pong', () => {
      setLastPong(new Date().toISOString());
    });

    initialTopics?.map(topic=>{
      socket.on(topic, (e) => {

        callback(topic,e)
      });
    })

    return () => {

      topics?.map(topic=> socket.off(topic));
      initialTopics?.map(topic=> socket.off(topic));
      // socket.disconnect();
    };
  }, []);

  const sendPing = () => {
    socket.emit('ping');
  }

  const disconnect =()=>{
     topics?.map(topic=> socket.off(topic));
      initialTopics?.map(topic=> socket.off(topic));
  }
  
  return {isConnected,ConnectToIOSocketServer}

}

export const useUser =()=>{
  const [user, setuser] = useState();

  const setUserInformation  = (userdata)=> setuser({...userdata});

  

  const getUserProfile = ()=>{
    let userInformation = localStorage.getItem("profileInfo");

    try {
      userInformation = JSON.parse(userInformation);

     
      
    } catch (error) {

      console.error("Failed to loading",{error:error?.message,...userInformation})
      userInformation={message:error?.message};
    }

    return userInformation;
  }

  const getUserDetails = ()=>{
    let userInformation = localStorage.getItem("moreProfile");

    try {
      userInformation = JSON.parse(userInformation);

     
      
    } catch (error) {

      console.error("Failed to loading",{error:error?.message,...userInformation})
      userInformation={message:error?.message};
    }

    return userInformation;
  }

  useEffect(() => {
    
    let userInformation = localStorage.getItem("moreProfile");

   

    try {
      userInformation = JSON.parse(userInformation);

      console.log("I am loading the user data",userInformation)
      setuser({...userInformation});
    } catch (error) {

      console.error("Failed to loading",{error:error?.message,...userInformation})
      userInformation={message:error?.message};
    }

    //setuser({...userInformation});
    
    return () => {
      
    }
  }, [])
  
  return {setUserInformation,user,getUserDetails,getUserProfile}

}