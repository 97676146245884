import { DatePicker, Form, Modal, Input, Radio, AutoComplete } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Badge, Button, Container } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { GridLoader } from 'react-spinners'
import Header from '../../Components/Header'
import HeaderTitle from '../../Components/HeaderTitle'
import httpService from '../../services/httpService'
import { errorMessage, successMessage } from '../../services/utilities'

const { RangePicker } = DatePicker;
function BookAccess({reload}) {
    const [displayModal, setdisplayModal] = useState(false);
    const [bookingInformation, setbookingInformation] = useState({})
    const [peopleList, setpeopleList] = useState([])

    function searchForPerson(personName)
    {
        httpService.post('/v1/bookings/visitor/people',{name:personName}).then(response=>{
            const {data} = response;
            setpeopleList([...data].map(a=>({value:`${a.name} ${a.surname}`,label:`${a.name} ${a.surname}`, more:a._id})))
        }).catch(error=>{

        })
    }
    function makeBooking(bookingInformation)
    {
        if(bookingInformation?.type==="personnelVisit")
        httpService.post('/v1/bookings/me/visitor',{
            vistingPerson:bookingInformation?.vistingPerson,
            from:bookingInformation.from,
            to:bookingInformation.to,
            reason:bookingInformation.reason
        }).then(response=>{
            const {data} =response;
            successMessage('Your Booking was successfully !');
            if(reload) reload();
        }).catch(err=>{
            errorMessage(`Failed to make booking (${err?.response?.data || err})`)
        })
        else
        httpService.post('/v1/bookings/me/tenant',{
            from:bookingInformation.from,
            to:bookingInformation.to,
            reason:bookingInformation.reason
        }).then(response=>{
            const {data} =response;
            successMessage('Your Booking was successfully !');
            if(reload) reload();
        }).catch(err=>{
            errorMessage(`Failed to make booking (${err?.response?.data || err})`)
        })
    }

   

    return (<>
        {!displayModal && <Button onClick={e => setdisplayModal(true)} variant='dark' size="sm">+</Button>}
        {displayModal && <Modal
        onCancel={()=> setdisplayModal(false)}
            forceRender={true}
            okType="success"
            width={600}
            title={`New Booking`}
            visible={displayModal}
            onOk={() => {
                makeBooking(bookingInformation)
            }}>
            
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"

            >
              
                <Form.Item label="Type" >
                    <Radio.Group defaultValue={"tenantVisit"} onChange={e => setbookingInformation(old => ({ ...old, type: e.target.value }))}>
                        <Radio.Button value="personnelVisit">Personnel Visit</Radio.Button>
                        <Radio.Button value="tenantVisit">Tenant Visit</Radio.Button>

                    </Radio.Group>
                </Form.Item>
                {bookingInformation?.type==="personnelVisit" &&
                   <Form.Item label="Staffs">
                   <AutoComplete
                       options={peopleList}
                       style={{ width: 200 }}
                        onSearch={e=>{
                            if(e.length > 2)
                            searchForPerson(e)
                        }}
                    onSelect={(value,option)=>{
                        setbookingInformation(old => ({ ...old, vistingPerson: option.more }))
                        console.log(`Selected options .. ${value}`, option)
                    }}
                       placeholder="Staff"
                   />
               </Form.Item>
                }
             
                <Form.Item label="When">
                    <RangePicker onChange={e => setbookingInformation(old => ({ ...old, from: e[0], to: e[1] }))} showTime />
                </Form.Item>

               
                <Form.Item label="Comments">
                    <Input.TextArea onChange={e => setbookingInformation(old => ({ ...old, reason: e.target.value }))} />
                </Form.Item>
            </Form>
        </Modal>}

    </>)
}

export function SearchByDateRange ({onSelectedDate, startSearch,searchButtonStatus})
{

    return (<>
     <RangePicker  onChange={e => onSelectedDate({from: e[0], to: e[1]})}  /> <Button style={{marginLeft:2}} size="sm" onClick={e=> startSearch()} variant="dark" disabled={searchButtonStatus}>Search</Button>
    </>)
}

export default function Notifications() {

    
    const [myNotifications, setmyNotifications] = useState()

    const [isLoading, setisloading] = useState(false);

    const loadNotifications = () => {
        setisloading(true);
        httpService.get('/v1/activity/mine/lite').then(response => {
            const { data } = response;
            setmyNotifications([...data]);
            setisloading(false);
        }).catch(error => {
            errorMessage("Failed to load Visits");
            setisloading(false);
        })
    }
    const displayStatus = (status) => {
        if (status === "success")
            return <Badge bg="success">OK</Badge>       
        else
            return <Badge bg="warning">WARNING</Badge>
    }  

    const [searchInfirmation, setsearchInfirmation] = useState({})

    const SearchForActivities = ()=>{
        setisloading(true);
        httpService.post('/v1/activity/mine/lite/search',searchInfirmation).then(res=>{
            const {data} =res;
            setmyNotifications([...data]);
            setisloading(false);
        }).catch(erro=>{
            setisloading(false);
        })
    }


    useEffect(() => {

        loadNotifications();
        return () => {

        }
    }, [])

    return (
        <>
            <Header />
            <HeaderTitle name="My Notifications" />
            <Container>
            <SearchByDateRange searchButtonStatus={isLoading} onSelectedDate={e=> setsearchInfirmation({...e})} startSearch={e=> SearchForActivities()} /> 
                <div style={{ textAlign: "center" }}>
                    <GridLoader margin={10}  loading={isLoading} size={30} color="black" />
                </div>        
                      
                <DataTable
                    pagination={true}
                    paginationPerPage={10}

                    columns={[
                        { name: "Date", selector: "date", sortable: true, cell: row => <>{moment(row.date).format("YYYY-MM-DD HH:mm")}</> },                       
                        { name: "Message", selector: "message", sortable: true, cell: row => <>{row.message}</> },                       
                        { name: "Message Type", selector: "type", sortable: true, cell: row => <>{displayStatus(row.type)}</> },
                        
                    ]}
                    data={myNotifications}
                /></Container>
        </>
    )
}
